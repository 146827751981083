import React, { useState, useEffect, useRef } from 'react';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import { PieItemIdentifier, DefaultizedPieValueType, PieValueType, PieArcLabelProps, PieSeriesType } from '@mui/x-charts';
import { Box, ImageList, ImageListItem } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { ChartPageType, ExtendedPieValueType } from '../../data/types';
import { ChartPopUp } from '../ChartPopUp/ChartPopUp';
import { PieArcLabel } from './ArcLabel';
import { getBrightnessAdjustedColor } from '../../utils';
import { MakeOptional } from '@mui/x-charts/models/helpers';
import { doesProtocolChartExist } from '../../data/Protocol/ProtocolData/charts'
import { doesWordListExist } from '../../data/Protocol/ProtocolData/wordlists'
import { doesStepPageExist } from '../../data/Protocol/ProtocolData/StepPage'

interface MuChartProps {
  height: number;
  width: number;
  chartData: ChartPageType;
  chartModule: string;
  item: string | null;
  subitem: string | null;
  chartRadius?: string | number | undefined;
  innerChartRadius?: string | number | undefined;
  divider?: number;
}

// Custom hook to get previous value
function usePrevious<T>(value: T): T | undefined {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}


export const ModuleChart: React.FC<MuChartProps> = ({ height, width, chartData, chartModule, item, subitem, chartRadius, innerChartRadius, 
  divider = 1.05
 }) => {
  const isSmallBreakpoint = height <= 412;
  const previousChartData = usePrevious(chartData);
  const navigate = useNavigate();
  const location = useLocation();
  const bottomTextRef = useRef<HTMLDivElement>(null);
  const [popUpState, setPopUpState] = useState<{ open: boolean, item: React.ReactElement | null, title: string }>({ open: false, item: null, title: "" });
  var data = chartData.chart as PieValueType[];
  const GetChartDataIndexFromLabel = (label: string, chartData: ChartPageType): [number, string ] | null => {
    var data = chartData.chart as ExtendedPieValueType[];
    var i;
    for (i = 0; i < data.length; i++) {
      if (data[i].label === label || (data[i].labelWithFormatting && data[i].labelWithFormatting === label)) {
        return [i, 'main-chart'];
      }
    }
    if (chartData.innerChart !== undefined) {
      data = chartData.innerChart as PieValueType[];
      for (i = 0; i < data.length; i++) {
        if (data[i].label === label || (data[i].labelWithFormatting && data[i].labelWithFormatting === label)) {
          return [i, 'inner-chart'];
        }
      }
    }
    if (chartData.innerInnerChart !== undefined) {
      data = chartData.innerInnerChart as PieValueType[];
      for (i = 0; i < data.length; i++) {
        if (data[i].label === label || (data[i].labelWithFormatting && data[i].labelWithFormatting === label)) {
          return [i, 'inner-inner-chart'];
        }
      }
    }

    return null;
  }

  const GetChartDataFromLabel = (label: string, chartData: ChartPageType): ExtendedPieValueType | null => {
    var indexAndSeriesId = GetChartDataIndexFromLabel(label, chartData);
    if (indexAndSeriesId !== null) {
      const [index, seriesId] = indexAndSeriesId;
      const data = GetChartDataFromId(seriesId, chartData);
      if (data) {
        return data[index] as ExtendedPieValueType;
      }
    }
    return null;
  }

  const GetChartDataFromId = (id: number | string, chartData: ChartPageType): ExtendedPieValueType[] | null => {
    if (id === 'main-chart') {
      return chartData.chart as ExtendedPieValueType[];
    }
    else if ((id === 'inner-chart')  && chartData.innerChart) {
      return chartData.innerChart as ExtendedPieValueType[];
    }
    else if ((id === 'inner-inner-chart')  && chartData.innerInnerChart) {
      return chartData.innerInnerChart as ExtendedPieValueType[];
    }
    return null;
  }


  const onTextClick = (label: string) => {
    if (chartData.disableClick) {
      return;
    }
    const indexAndSeriesId = GetChartDataIndexFromLabel(label, chartData);
    if (indexAndSeriesId === null) {
      return;
    }
    const [index, seriesId] = indexAndSeriesId;
    const d = { type: 'pie', dataIndex: index, label: label, seriesId: seriesId } as PieItemIdentifier;
    OnPieSliceClick(d);

  }
  const CustomPieArcLabel: React.FC<PieArcLabelProps> = ({ id, ...restProps }) => {

    let data: ExtendedPieValueType | null = GetChartDataFromLabel(restProps.formattedArcLabel as string, chartData);
    const textColor = data?.textColor ? data.textColor : getBrightnessAdjustedColor(data?.color)
    const disableTextRotation = data?.disableTextRotation;
    return (
      <PieArcLabel id={id} onItemClick={onTextClick} {...restProps} style={{ fill: textColor }} disableTextRotation={disableTextRotation} />
    );
  }

  const heightToFontSize = (h: number) => {
    if (h < 412) {
      return {xs:8, sm:9, md:11, lg:15};
    }

    return {xs:10, sm:11, md:13, lg:18};
  }


  const OnPieSliceClick = (d: PieItemIdentifier) => {
    if (chartData.disableClick) {
      return;
    }

    let data: ExtendedPieValueType[] | null = GetChartDataFromId(d.seriesId, chartData);

    if (data) {
      if (d.dataIndex > data.length && chartData.innerChart) {
        data =  chartData.innerChart as PieValueType[];
      }


      var extraData = data[d.dataIndex] as ExtendedPieValueType
      var page: string = data[d.dataIndex].label as string;
      var popup: React.ReactElement | undefined = extraData.popupContent
      var onClickNav: string | undefined = extraData.onClickNav
      let params = new URLSearchParams();
      let existingParams = new URLSearchParams(location.search);
      var starting_page = '/';
      
      if (onClickNav !== undefined) {
        navigate(onClickNav);
        return;
      }

      if (popup !== undefined) {
        setPopUpState({ open: true, item: popup as React.ReactElement, title: page });
        return;
      }


      if (existingParams.has('bottom_nav')) {
        var protocolModule = chartModule;
        var protocolItem = null;
        var protocolSubItem = null;
        if (protocolModule === 'Protocol')
        {
          protocolModule = page;
        }
        else {
          if (item === null){
            protocolItem = page;
          }
          else if (subitem == null) {
            protocolSubItem = page;
          }
        }
        if (!doesProtocolChartExist(protocolModule,protocolItem,protocolSubItem,page) &&
            !doesWordListExist(protocolModule,protocolItem,protocolSubItem,page)&& 
            !doesStepPageExist(protocolModule,protocolItem ? protocolItem : '', protocolSubItem ? protocolSubItem : '')
          ){
            return;
        }
        params.append('bottom_nav', existingParams.get('bottom_nav')!);
        starting_page = '/protocol/'


      }

      if (popup !== undefined) {
        setPopUpState({ open: true, item: popup as React.ReactElement, title: page });
      }
      else if (onClickNav !== undefined) {
        navigate(onClickNav);
      }
      else if (page === "Go To Another Chart") {
        params.append('module', chartModule);
        navigate('/?' + params.toString());
      }
      else if (chartModule === "All Modules") {
        params.append('module', page);
        navigate('/?' + params.toString());
      }
      else {
        if (item === null) {
          params.append('module', chartModule);
          params.append('item', page);
        }
        else if (subitem === null) {
          params.append('module', chartModule);
          params.append('item', item);
          params.append('subitem', page);
        }
        else {
          params.append('module', chartModule);
          params.append('item', item);
          params.append('subitem', subitem);
          params.append('detail', page);
        }

        navigate(starting_page + '?' + params.toString());

      }
    }
  };
  const onChartPopUpClose = () => {
    setPopUpState({ open: false, item: null, title: "" });
  }

  const getArcLabel = (params: DefaultizedPieValueType) => {
    if ((params as ExtendedPieValueType).labelWithFormatting) {
      return (params as ExtendedPieValueType).labelWithFormatting
    }
    return `${params.label}`;
  };
  if (height === undefined || width === undefined) {
    return null;
  }


  var series = [{
    startAngle: chartData.fullOneEightyChart ? -15 : -90,
    endAngle: chartData.fullOneEightyChart ? 360 : 90,
    paddingAngle: 0.5,
    cornerRadius: 10,
    arcLabelRadius: chartRadius ? chartRadius : (isSmallBreakpoint ?  "65%" : "75%"),
    data,
    arcLabel: getArcLabel,
    innerRadius: 0,
    id : 'main-chart'
  }] as MakeOptional<PieSeriesType<MakeOptional<PieValueType, 'id'>>, 'type'>[];


  if (chartData.innerChart) {
    series[0]['innerRadius'] = height / 2.45;
    series.push({
      startAngle: -90,
      endAngle: 90,
      paddingAngle: 0.5,
      cornerRadius: 10,
      outerRadius: height / 2.5,
      arcLabel: getArcLabel,
      arcLabelRadius: innerChartRadius ? innerChartRadius : "37%",
      data: chartData.innerChart,
      id: 'inner-chart'
    } as MakeOptional<PieSeriesType<MakeOptional<PieValueType, 'id'>>, 'type'>)
  }
  const interpolatePct = (start:number,end:number,total:number) => {
    return start/total*100 + (end /total*100 - start/total*100)/2 + 3+ '%';
  }

  if (chartData.innerInnerChart) {
    series[0]['innerRadius'] = height / 3 * 1.9;
    series[1]['innerRadius'] = height / 2.5;
    series[1]['outerRadius'] = height / 3 * 1.9;
    series[1]['arcLabelRadius'] = interpolatePct(height/2.5,height/3*1.9,height);
    series[0]['arcLabelRadius'] = interpolatePct(height/3*1.9,height,height);

    series.push({
      startAngle: -90,
      endAngle: 90,
      paddingAngle: 0.5,
      cornerRadius: 10,
      outerRadius: height / 2.5,
      arcLabel: 'label',
      arcLabelRadius: interpolatePct(0,height/2.5,height),
      data: chartData.innerInnerChart,
      id: 'inner-inner-chart'
    } as MakeOptional<PieSeriesType<MakeOptional<PieValueType, 'id'>>, 'type'>)
  }

  var bottomTextSize = chartData.textChartInstructions ? (bottomTextRef.current?.offsetHeight ?? 0) : 0;
  const chartHeight = height - bottomTextSize
  var margin_subtract = 1 - (1 / divider);
  // Determine if the previous chart had a gradient
  const prevHadGradient = previousChartData
    ? previousChartData.chart.some(item => item.color && item.color.includes('url('))
    : false;

  return (
    <Box
      display={chartData.rightOrLeftImage ? "flex" : "block"}
      justifyContent="center"
      alignItems="center"
      width="100%"
      height="100%"
    >
      {/* 1) Add a hidden svg that only holds our gradient definitions */}
      <svg style={{ height: 0, width: 0, position: 'absolute' }}>
        <defs>
          <linearGradient id="rainbowGradient" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="#FF0000" />
            <stop offset="16.7%" stopColor="#FF7F00" />
            <stop offset="33.3%" stopColor="#FFFF00" />
            <stop offset="50%" stopColor="#00FF00" />
            <stop offset="66.7%" stopColor="#0000FF" />
            <stop offset="83.3%" stopColor="#4B0082" />
            <stop offset="100%" stopColor="#8F00FF" />
          </linearGradient>
        </defs>
      </svg>

      <React.Fragment>
        <PieChart
          margin={{ bottom: chartData.fullOneEightyChart ? 0 : -chartHeight / divider, left: 0, right: 0, top: 0 }}
          series={series}
          skipAnimation={prevHadGradient}
          sx={{
            [`& .${pieArcLabelClasses.root}`]: {
              fill: 'white',
              fontSize: chartData.textSizeOverride ? chartData.textSizeOverride : heightToFontSize(chartHeight),
              flex: '1 0 auto', justifyContent: 'center', alignItems: 'center',
              fontWeight: '600',
            },
          }}
          slots={{
            pieArcLabel: CustomPieArcLabel,
          }}
          slotProps={{
            legend: { hidden: true },
            pieArcLabel: {
            }
          }}
          tooltip={{ 'trigger': 'none' }}
          height={chartHeight - height * margin_subtract < 0 ? -(chartHeight - height * margin_subtract) : chartHeight - height * margin_subtract}
          onItemClick={(event: React.MouseEvent<SVGPathElement, MouseEvent>, d: PieItemIdentifier) => OnPieSliceClick(d)}
        />
        {chartData.rightOrLeftImage && (
          <ImageList cols={1}>
            <ImageListItem>
              <img src={chartData.rightOrLeftImage} alt="" />
            </ImageListItem>
          </ImageList>
        )}
        {chartData && chartData.textChartInstructions
          ? <Box ref={bottomTextRef} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: heightToFontSize(height) }}>
            {chartData.textChartInstructions}
          </Box>
          : null
        }
        <ChartPopUp item={popUpState.item} title={popUpState.title} open={popUpState.open} onClose={onChartPopUpClose} />
      </React.Fragment>
    </Box>
  );
};

export default ModuleChart;