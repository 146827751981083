import { ChartPageType } from '../types';
import { WordsPopUp, NoInfoPopUp  } from '../popup';

export enum PreProtocolSteps {
    DIRECTION = 'direction',
    LIFE_PART = 'life-part',
    SYSTEM = 'system',
    ELEMENT = 'element',
    DIMENSION = 'dimension',
    ENERGY_FIELD = 'energy-field'
};

export type PreProtocolSubStep = {
    label: string;
    chart: ChartPageType;
};



export type PreProtocolItem = {
    label : string;
    chart : ChartPageType;
    subSteps?: { [key: string]: PreProtocolSubStep };
}

// Add type for the mapping
export type PreProtocolMapType = {
    [key in PreProtocolSteps]: PreProtocolItem;
};


export const PreProtocolMap: PreProtocolMapType = {
    [PreProtocolSteps.DIRECTION]: {
        label: "Direction",
        chart: {
            chart: [
              { label: 'North', value: 1, id: 0, color: '#C0202E', popupContent: <WordsPopUp words={["Wisdom", "Mind", "Air"]} /> },
              { label: 'West', value: 1, id: 1, color: '#F4802A', popupContent: <WordsPopUp words={["Introspection", "Body", "Earth"]} /> },
              { label: 'South', value: 1, id: 2, color: '#F3B01A', popupContent: <WordsPopUp words={["Trust", "Emotions", "Water"]} /> },
              { label: 'East', value: 1, id: 3, color: '#8BC640', popupContent: <WordsPopUp words={["Illumination", "Spirit", "Fire"]} /> },
            ],
            level: 0,
            subPages: {},
            disableClick: false,
          }
    },

    [PreProtocolSteps.LIFE_PART]: {
        label: "Life Part",
        chart: {
            chart: [
              { label: 'Emotional', value: 1, id: 0, color: '#C0202E', popupContent: <NoInfoPopUp /> },
              { label: 'Physical', value: 1, id: 1, color: '#F4802A', popupContent: <NoInfoPopUp /> },
              { label: 'Relational', value: 1, id: 2, color: '#F3B01A', popupContent: <NoInfoPopUp /> },
              { label: 'Spiritual', value: 1, id: 3, color: '#8BC640', popupContent: <NoInfoPopUp /> },
              { label: 'Situational', value: 1, id: 4, color: '#3CB54B', popupContent: <NoInfoPopUp /> },
              { label: 'Mental', value: 1, id: 5, color: '#138D93', popupContent: <NoInfoPopUp /> }
            ],
            level: 0,
            subPages: {},
            disableClick: false,
          }
    },

    [PreProtocolSteps.SYSTEM]: {
        label: "System",
        chart: {
            chart: [
                { label: "Musculoskeletal", value: 1, id: 0, color: "#C0202E", popupContent: <NoInfoPopUp /> },
                { label: "Hormonal", value: 1, id: 1, color: "#F4802A", popupContent: <NoInfoPopUp /> },
                { label: "Respiratory", value: 1, id: 2, color: "#F3B01A", popupContent: <NoInfoPopUp /> },
                { label: "Cardiovascular", value: 1, id: 3, color: "#8BC640", popupContent: <NoInfoPopUp /> },
                { label: "Digestive", value: 1, id: 4, color: "#3CB54B", popupContent: <NoInfoPopUp /> },
                { label: "Lymph", value: 1, id: 5, color: "#138D93", popupContent: <NoInfoPopUp /> },
                { label: "Integumentary", value: 1, id: 6, color: "#4E7AC7", popupContent: <NoInfoPopUp /> },
                { label: "Reprod Male/Female", value: 1, id: 7, color: "#283890", popupContent: <WordsPopUp words={["Reproductive Male/Female"]}/> },
                { label: "Neurological", value: 1, id: 8, color: "#6A1B9A", popupContent: <NoInfoPopUp /> },
                { label: "Immune", value: 1, id: 9, color: "#C71585", popupContent: <NoInfoPopUp /> },
                { label: "Fascial", value: 1, id: 10, color: "#9C6B3F", popupContent: <NoInfoPopUp /> },
                { label: "Blood", value: 1, id: 11, color: "#DB2F2F", popupContent: <NoInfoPopUp /> },
                { label: "Endocrine", value: 1, id: 12, color: "#EA7B13", popupContent: <NoInfoPopUp /> },
                { label: "Urinary", value: 1, id: 13, color: "#FFD809", popupContent: <NoInfoPopUp /> }
              ],
              
            level: 0,
            subPages: {},
            disableClick: false,
          }
    },
    [PreProtocolSteps.ELEMENT]: {
        label: "Element",
        chart: {
            chart: [
                { label: "Earth", value: 1, id: 0, color: "#DB2F2F", popupContent: <WordsPopUp words={["Primary sense of presence", "Conscious thinking", "Worry"]} /> },
                { label: "Fire", value: 1, id: 1, color: "#F4802A",popupContent: <WordsPopUp words={["Self-awareness", "Intellect", "Harmony", "Joy/sadness"]} /> },
                { label: "Water", value: 1, id: 2, color: "#F3B01A",popupContent: <WordsPopUp words={["Willpower", "Will to survive", "Passive reproduction", "Sexuality"]} /> },
                { label: "Metal", value: 1, id: 3, color: "#8BC640",popupContent: <WordsPopUp words={["Response", "Surrender", "Instinctive reaction", "Grief"]} /> },
                { label: "Wood", value: 1, id: 4, color: "#3CB54B",popupContent: <WordsPopUp words={[ "Soul", "Storage of experiences", "Deciding", "Catalyst for action", "Anger"]} /> },
              ],
            level: 0,
            subPages: {},
            disableClick: false,
          }
    },
    [PreProtocolSteps.DIMENSION]: {
        label: "Dimension",
        chart: {
            chart: [
                { label: "1", value: 1, id: 0, color: "#DB2F2F", popupContent: <NoInfoPopUp /> },
                { label: "2", value: 1, id: 1, color: "#F4802A", popupContent: <NoInfoPopUp /> },
                { label: "3", value: 1, id: 2, color: "#F3B01A", popupContent: <NoInfoPopUp /> },
                { label: "4", value: 1, id: 3, color: "#8BC640", popupContent: <NoInfoPopUp /> },
                { label: "5", value: 1, id: 4, color: "#3CB54B", popupContent: <NoInfoPopUp /> },
                { label: "6", value: 1, id: 5, color: "#138D93", popupContent: <NoInfoPopUp /> },
                { label: "7", value: 1, id: 6, color: "#4E7AC7", popupContent: <NoInfoPopUp /> },
                { label: "8", value: 1, id: 7, color: "#6A1B9A", popupContent: <NoInfoPopUp /> },
                { label: "9", value: 1, id: 8, color: "#C71585", popupContent: <NoInfoPopUp /> },
                { label: "10", value: 1, id: 9, color: "#9C6B3F", popupContent: <NoInfoPopUp /> },
                { label: "11", value: 1, id: 10, color: "#795548", popupContent: <NoInfoPopUp /> },
                { label: "12", value: 1, id: 11, color: "#f0f0f0", popupContent: <NoInfoPopUp /> },
                { label: "13", value: 1, id: 12, color: "#000000", popupContent: <NoInfoPopUp /> },
              ],
            level: 0,
            subPages: {},
          }
    },
    [PreProtocolSteps.ENERGY_FIELD]: {
        label: "Energy Field",
        chart: {
            chart: [
              { label: "Human", value: 1, id: 0, color: "#DB2F2F", onClickNav:'/protocol?bottom_nav=pre_protocol&step=energy-field&sub_step=human' },
              { label: "Animal", value: 1, id: 1, color: "#F4802A", onClickNav:'/protocol?bottom_nav=pre_protocol&step=energy-field&sub_step=animal' },
            ],
            level: 0,
            subPages: {},
            disableClick: false,
          },
          subSteps: {
            "human": {
                label: "Human",
                chart: {
                    chart: [
                      { label: "Etheric Body", value: 1, id: 0, color: "#DB2F2F", popupContent: <NoInfoPopUp /> },
                      { label: "Emotional Body", value: 1, id: 1, color: "#F4802A", popupContent: <NoInfoPopUp /> },
                      { label: "Mental Body", value: 1, id: 2, color: "#F3B01A", popupContent: <NoInfoPopUp /> },
                      { label: "Astral Body", value: 1, id: 3, color: "#8BC640", popupContent: <NoInfoPopUp /> },
                      { label: "Etheric Template", value: 1, id: 4, color: "#3CB54B", popupContent: <NoInfoPopUp /> },
                      { label: "Celestial Body", value: 1, id: 5, color: "#138D93", popupContent: <NoInfoPopUp /> },
                      { label: "Ketheric Body", value: 1, id: 6, color: "#4E7AC7", popupContent: <NoInfoPopUp /> },
                    ],
                    level: 0,
                    subPages: {},
                }
            },
            "animal": {
                label: "Animal",
                chart: {
                    chart: [
                      { label: "Etheric Field #1", value: 1, id: 0, color: "#DB2F2F", popupContent: <NoInfoPopUp /> },
                      { label: "Etheric Field #2", value: 1, id: 1, color: "#F4802A", popupContent: <NoInfoPopUp /> },
                      { label: "Emotional", value: 1, id: 2, color: "#F3B01A", popupContent: <NoInfoPopUp /> },
                      { label: "Mental #1", value: 1, id: 3, color: "#8BC640", popupContent: <NoInfoPopUp /> },
                      { label: "Mental #2", value: 1, id: 4, color: "#3CB54B", popupContent: <NoInfoPopUp /> },
                      { label: "Spiritual #1", value: 1, id: 5, color: "#138D93", popupContent: <NoInfoPopUp /> },
                      { label: "Spiritual #2", value: 1, id: 6, color: "#4E7AC7", popupContent: <NoInfoPopUp /> },
                      { label: "Spiritual #3", value: 1, id: 7, color: "#6A1B9A", popupContent: <NoInfoPopUp /> },
                      { label: "Transpersonal", value: 1, id: 8, color: "#C71585", popupContent: <NoInfoPopUp /> },
                    ],
                    level: 0,
                    subPages: {},
                }
            }
        }
    }
};
