import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import WordCardCollection from '../components/WordCardCollection/WordCardCollection';
import { WordListType } from '../data/types';
import { useWindowSize } from '@uidotdev/usehooks';

export const NoInfoPopUp = () => {
    return (<Typography>No information available</Typography>);
};

export const WordsPopUp = ({ words }: { words: string[] }) => {
    return (
        <div>
            {words.map((word, index) => {
                return <Typography key={index} gutterBottom>{word}</Typography>;;
            })}
        </div>
    );
}

export const NutritionalIssuePopUp = () => {
    return (
        <div>
            <Typography>See &nbsp;
                <Link to="/?module=Animal&item=Nutrition">Nutrition Chart </Link></Typography>
            <Typography> or </Typography>
            <Typography> Consult <Link to="/?module=Nutrition">Nutrition Style Module </Link></Typography>
        </div>
    );

}


export const WordListPopUp = ({ words }: { words: WordListType[] }) => {
const size = useWindowSize();
const boxRef = React.useRef<HTMLDivElement>(null);
const [boxSize, setBoxSize] = React.useState({ width: 0, height: 0 });

React.useEffect(() => {
    if (boxRef.current) {
        setBoxSize({
            width: boxRef.current.offsetWidth,
            height: boxRef.current.offsetHeight,
        });
    }
}, [size]);

return (
    <Box
        ref={boxRef}
        width='100%'
        height='100%'
    >
        <WordCardCollection
            wordList={words}
            width={boxSize.width}
            height={boxSize.height}
            titleHeight={20}
            wordPageTitleHeight={0}
        />
    </Box>
);
}