import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/HomePage';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { lighten } from '@mui/system';
import Auth from './components/Auth/Auth';
import AccountSetup from './pages/AccountSetupPage';
import Login from './pages/LoginPage';
import AccountReset from './pages/AccountResetPage';
import SwaggerUI from "swagger-ui-react"
import "swagger-ui-react/swagger-ui.css"
import Protocol from './pages/ProtocolPage';

const theme = createTheme({
  palette: {
    primary: {
      main: '#7B1FA2',
    },
    secondary: {
      main: '#dc004e',
    },
  },
  typography: {
    fontFamily: 'Arial',
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: lighten('#7B1FA2', 0.9),
          },
        },
      },
    },

    MuiButtonBase: {
      styleOverrides: {
        root: {
          '&:hover .MuiTouchRipple-root': {
            color: lighten('#7B1FA2', 0.5),
          },
        },
      },
    },

    MuiTouchRipple: {
      styleOverrides: {
        child: {
          backgroundColor: lighten('#7B1FA2', 0.5),
        },
        childLeaving: {
          backgroundColor: lighten('#7B1FA2', 0.5),
        },
        rippleVisible: {
          color: lighten('#7B1FA2', 0.5),
        },
      },
    },

  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route index element={<Auth redirectUrl='/login'><Home /></Auth>} />
          {/* <Route path="/" element={<Home />} /> */}
          <Route path="/protocol" element={<Auth redirectUrl='/login'><Protocol /></Auth>} />
          {/* <Route path="/protocol" element={<Protocol />} /> */}
          <Route path="/setup_account" element={<AccountSetup setup_or_forgot_pass='setup'/>} />
          <Route path="/reset_password" element={<AccountSetup setup_or_forgot_pass='forgot'/>} />
          <Route path="/login" element={<Login />} />
          <Route path="/reset" element={<AccountReset />} />
          <Route path="/swagger" element={<Auth redirectUrl='/login'><SwaggerUI url="/swagger.json" /></Auth>} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
