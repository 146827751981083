import React, { useState, useRef, useEffect, useCallback } from 'react';
import { CustomChartProps } from '../../data/Protocol/ProtocolData/StepPage';
import { Typography, Button } from '@mui/material';
import Box from '@mui/material/Box';

interface ViewBox {
  x: number;
  y: number;
  width: number;
  height: number;
}

// Define colors at the top.
const generationColors = [
  '#f44336', // Gen 1
  '#ff9800', // Gen 2
  '#dbca32', // Gen 3
  '#4caf50', // Gen 4
  '#8bc34a', // Gen 5
  '#00bcd4', // Gen 6
  '#9c27b0', // Gen 7
];

const AncestryChartZoom: React.FC<CustomChartProps> = ({ width, height }) => {
  const isSm = height <= 412 || width < 360;
  const [boxWidth, setBoxWidth] = useState(0);
  const boxRef = useRef<HTMLDivElement>(null);

  // When no generation is selected, show the full tree.
  const [selectedGeneration, setSelectedGeneration] = useState<number | null>(null);

  // We use 7 generations.
  const generations = 7;
  // Reserve some vertical margin.
  const additionalSpace = height * 0.05;
  // Compute vertical spacing so that generation 1 is at the bottom.
  const verticalSpacing = (height - additionalSpace) / generations;
  // Reserve width for the sidebar.
  const sidebarWidth = width * 0.15;
  // The remaining width is used for the chart.
  const chartWidth = width - sidebarWidth;

  useEffect(() => {
    if (boxRef.current) {
      setBoxWidth(boxRef.current.offsetWidth);
    }
  }, [width]);

  // Full viewBox state for the main SVG.
  const [viewBox, setViewBox] = useState<ViewBox>({ x: 0, y: 0, width: chartWidth, height });
  const svgRef = useRef<SVGSVGElement>(null);

  // New state: for Generation 6 and 7, track half zoom ("none", "left", or "right")
  const [halfSelection, setHalfSelection] = useState<'none' | 'left' | 'right'>('none');

  // Reset halfSelection to "none" if a generation other than 6 or 7 is selected.
  useEffect(() => {
    if (selectedGeneration !== 6 && selectedGeneration !== generations) {
      setHalfSelection('none');
    }
  }, [selectedGeneration, generations]);

  // For positioning, assume a grid based on the maximum number of nodes.
  // For a binary tree, the top generation has 2^(generations-1) nodes.
  const largestNodes = 2 ** (generations - 1) * 2;
  const slotWidth = chartWidth / largestNodes;

  // Helper: Get the (x,y) coordinates for a node in generation "gen" at a given index.
  // Generation 1 is at the bottom; generation 7 is at the top.
  const getNodeCoords = useCallback((gen: number, index: number) => {
    const y = height - gen * verticalSpacing;
    const nodesInGen = 2 ** (gen - 1);
    const ratio = largestNodes / nodesInGen;
    const xInSlots = (index + 0.5) * ratio;
    const x = xInSlots * slotWidth;
    return { x, y };
  }, [height, verticalSpacing, largestNodes, slotWidth]);

  // Build the full tree’s connecting lines and node labels.
  const lines: JSX.Element[] = [];

  // For generations 1 through 6, draw connecting lines.
  for (let gen = 1; gen < generations; gen++) {
    const childCount = 2 ** (gen - 1);
    for (let i = 0; i < childCount; i++) {
      const { x: childX, y: childY } = getNodeCoords(gen, i);
      const parentY = height - (gen + 1) * verticalSpacing;
      const midY = (childY + parentY) / 2;
      // Vertical line from child node upward.
      lines.push(
        <line
          key={`child-vertical-${gen}-${i}`}
          x1={childX}
          y1={childY}
          x2={childX}
          y2={midY}
          stroke={generationColors[gen - 1]}
          strokeWidth={isSm ? 1 : 2}
        />
      );
      // Each child connects to two parent nodes.
      for (let p = 0; p < 2; p++) {
        const parentIndex = 2 * i + p;
        const { x: parentX } = getNodeCoords(gen + 1, parentIndex);
        // Horizontal segment.
        lines.push(
          <line
            key={`horizontal-${gen}-${i}-${parentIndex}`}
            x1={childX}
            y1={midY}
            x2={parentX}
            y2={midY}
            stroke={generationColors[gen]}
            strokeWidth={isSm ? 1 : 2}
          />
        );
        // For the last generation (gen === generations - 1), extend the vertical line to meet the text.
        const extension = gen === generations - 1 ? 10 : 0;
        lines.push(
          <line
            key={`parent-vertical-${gen}-${i}-${parentIndex}`}
            x1={parentX}
            y1={midY}
            x2={parentX}
            y2={parentY + extension}
            stroke={generationColors[gen]}
            strokeWidth={isSm ? 1 : 2}
          />
        );
      }
    }
  }

  // When a generation is selected, recalc the viewBox to focus on that generation.
  useEffect(() => {
    if (selectedGeneration !== null && boxWidth > 0) {
      if (selectedGeneration === 1) {
        // For generation 1, use a fixed margin so width is nonzero.
        const node = getNodeCoords(1, 0);
        const marginX = slotWidth * 2;
        const marginY = verticalSpacing * 0.2;
        setViewBox({
          x: node.x - marginX,
          y: node.y - marginY,
          width: marginX * 2,
          height: verticalSpacing + 2 * marginY,
        });
      } else if (selectedGeneration === 6 || selectedGeneration === generations) {
        // For Generation 6 and 7, support half zoom.
        const nodesCount = 2 ** (selectedGeneration - 1);
        let firstIndex = 0;
        let lastIndex = nodesCount - 1;
        if (halfSelection === 'left') {
          firstIndex = 0;
          lastIndex = nodesCount / 2 - 1;
        } else if (halfSelection === 'right') {
          firstIndex = nodesCount / 2;
          lastIndex = nodesCount - 1;
        }
        const firstNode = getNodeCoords(selectedGeneration, firstIndex);
        const lastNode = getNodeCoords(selectedGeneration, lastIndex);
        const marginX = (lastNode.x - firstNode.x) * 0.05;
        const marginY = verticalSpacing * 0.1;
        setViewBox({
          x: firstNode.x - marginX,
          y: firstNode.y - marginY,
          width: (lastNode.x - firstNode.x) + 2 * marginX,
          height: verticalSpacing + 2 * marginY,
        });
      } else {
        const nodesCount = 2 ** (selectedGeneration - 1);
        const firstNode = getNodeCoords(selectedGeneration, 0);
        const lastNode = getNodeCoords(selectedGeneration, nodesCount - 1);
        const marginX = (lastNode.x - firstNode.x) * 0.1;
        const marginY = verticalSpacing * 0.2;
        setViewBox({
          x: firstNode.x - marginX,
          y: firstNode.y - marginY,
          width: (lastNode.x - firstNode.x) + 2 * marginX,
          height: verticalSpacing + 2 * marginY,
        });
      }
    } else {
      // When not zoomed, show the full chart.
      setViewBox({ x: 0, y: 0, width: chartWidth, height });
    }
  }, [selectedGeneration, boxWidth, chartWidth, height, verticalSpacing, slotWidth, halfSelection, getNodeCoords]);

  // In full mode we show complete node labels.
  // In zoom mode, we compute enlarged labels for the selected generation.
  // If halfSelection is active for Gen 6 or Gen 7, only render the appropriate half.
  const zoomedNodeLabels = selectedGeneration !== null && (() => {
    const totalNodes = 2 ** (selectedGeneration - 1);
    let startIndex = 0;
    let count = totalNodes;
    if ((selectedGeneration === 6 || selectedGeneration === generations) && halfSelection !== 'none') {
      if (halfSelection === 'left') {
        startIndex = 0;
        count = totalNodes / 2;
      } else if (halfSelection === 'right') {
        startIndex = totalNodes / 2;
        count = totalNodes / 2;
      }
    }
    return Array.from({ length: count }, (_, i) => {
      const index = startIndex + i;
      const { x, y } = getNodeCoords(selectedGeneration, index);
      const labelNumber =
        selectedGeneration === 1 ? 'You' : Math.pow(2, selectedGeneration - 1) + index;
      const baseOffset = (selectedGeneration === 1) ? 0 : 5;
      // For Gen 6 and Gen 7 in zoom mode, we stagger labels slightly.
      const staggerOffset = (selectedGeneration === generations) ? 5 : 0;
      const finalX = x - baseOffset;
      const finalY = y - staggerOffset;
      const fontSize =
        selectedGeneration === 1
          ? '5px'
          : (selectedGeneration === 6 || selectedGeneration === generations)
            ? '7px'
            : '15px';
      return (
        <foreignObject
          key={`zoom-node-${selectedGeneration}-${index}`}
          x={finalX}
          y={finalY}
          width={60}
          height={30}
          transform={(selectedGeneration === 1 || selectedGeneration === generations) ? `rotate(-90, ${x}, ${y})` : ''}
          textAnchor="start"
        >
          <Typography
            variant="body2"
            style={{
              color: generationColors[selectedGeneration - 1],
              whiteSpace: 'nowrap',
              fontSize: fontSize,
              fontWeight: 'bold',
              backgroundColor: 'white', // Add white background fill
            }}
          >
            {labelNumber}
          </Typography>
        </foreignObject>
      );
    });
  })();

  return (
    <div style={{ display: 'flex', width: '100%', height: '100%' }}>
      {/* Sidebar as a flex column */}
      <div
        style={{
          width: sidebarWidth,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-around',
          alignItems: 'center',
          borderRight: '1px solid #ccc',
          height: height,
        }}
      >
        {Array.from({ length: generations }, (_, i) => {
          // Reverse the order so that Generation 7 appears at the top.
          const gen = generations - i;
          const isSelected = selectedGeneration === gen;
          return (
            <Button
              key={`sidebar-${gen}`}
              variant={isSelected ? 'contained' : 'outlined'}
              onClick={() => {
                setSelectedGeneration(isSelected ? null : gen);
                setHalfSelection('none');
              }
              }
              style={{
                backgroundColor: isSelected ? generationColors[gen - 1] : undefined,
                borderColor: generationColors[gen - 1],
                color: isSelected ? '#fff' : generationColors[gen - 1],
                fontWeight: isSelected ? 'bold' : 'normal',
                margin: '5px 0',
              }}
            >
              Generation {gen}
            </Button>
          );
        })}
      </div>
      {/* Main chart area */}
      <Box
        ref={boxRef}
        style={{
          flex: 1,
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <svg
          ref={svgRef}
          width="100%"
          height="100%"
          viewBox={`${viewBox.x} ${viewBox.y} ${viewBox.width} ${viewBox.height}`}
          style={{ display: 'block' }}
        >
          {/* Always render the connecting lines */}
          {lines}
          {/* Render zoomed node labels when in zoom mode */}
          {selectedGeneration === null ? null : zoomedNodeLabels}
        </svg>
        {/* If Generation 6 or Generation 7 is selected, render Left/Right buttons */}
        {(selectedGeneration === 6 || selectedGeneration === generations) && (
          <Box
            sx={{
              position: 'absolute',
              bottom: 20,
              left: '50%',
              transform: 'translateX(-50%)',
              display: 'flex',
              gap: 2,
            }}
          >
            <Button
              variant={halfSelection === 'left' ? "contained" : 'outlined'}
              onClick={() =>
                setHalfSelection(halfSelection === 'left' ? 'none' : 'left')
              }
            >
              Left
            </Button>
            <Button
              variant={halfSelection === 'right' ? "contained" : 'outlined'}
              onClick={() =>
                setHalfSelection(halfSelection === 'right' ? 'none' : 'right')
              }
            >
              Right
            </Button>
          </Box>
        )}
      </Box>
    </div>
  );
};

export default AncestryChartZoom;
