import React, { useRef, useState, useEffect } from 'react';
import ModuleChart from '../ModuleChart/ModuleChart';
import ModuleTitle from '../ModuleTitle/ModuleTitle';
import Box from '@mui/material/Box';
import { useWindowSize } from '@uidotdev/usehooks';
import { GetProtocolChart } from '../../data/Protocol/ProtocolData/charts';
import { GetProtocolWordList } from '../../data/Protocol/ProtocolData/wordlists';
import { GetStepPageContent } from '../../data/Protocol/ProtocolData/StepPage';
import { useLocation } from 'react-router-dom';
import { ProtocolBreadcrumbs } from './ProtocolBreadcrumbs'
import WordCardCollection from '../WordCardCollection/WordCardCollection'
import ECSPage from '../MultiChartPage/ECS'
import StepPageContent from './StepPageContent';

interface ProtocolContentProps {
  height: number;
  width: number;
}


const ProtocolContent: React.FC<ProtocolContentProps> = ({ width, height }) => {
  const titleRef = useRef<HTMLDivElement>(null);
  const breadcrumbsRef = useRef<HTMLDivElement>(null);
  const size = useWindowSize();
  const [chartHeight, setChartHeight] = useState<number>(width);
  const [wordCardHeight, setWordCardHeight] = useState<number>(height);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const module = queryParams.get('module') || null;
  const item = queryParams.get('item') || null;
  const subitem = queryParams.get('subitem') || null;
  const detail = queryParams.get('detail') || null;

  useEffect(() => {
    setChartHeight(height - (titleRef.current?.offsetHeight ?? 0));
    setWordCardHeight(height - (breadcrumbsRef.current?.offsetHeight ?? 0) - (titleRef.current?.offsetHeight ?? 0));
  }, [size, height]);



  const specialContent = (() => {
    if (module && item) {
      const content = GetStepPageContent(module, item, subitem || " ");
      if (content) {
        return <StepPageContent stepMap={content} 
                                width={width} 
                                height={chartHeight} 
                                titleHeight={titleRef.current?.offsetHeight ?? 0} 
                                />
      }
    }
    return null;
  })();


  const words = GetProtocolWordList(module, item, subitem, detail)
  const chart = GetProtocolChart(module, item, subitem, detail);

  const chartModule: string = module || "Protocol";

  return (
    <Box style={{ position: 'relative', flexGrow: 1 }}>
      <Box sx={{ zIndex: 1 }} ref={breadcrumbsRef}>
        <ProtocolBreadcrumbs module={module} item={item} subItem={subitem} detail={detail} />
      </Box>
      <Box style={{ position: 'absolute', top: 'desiredValue', zIndex: 1 }} ref={titleRef}>
        <ModuleTitle module={"Protocol"} />
      </Box>
      {words && wordCardHeight > 0 && width > 0 ? (
        <WordCardCollection wordList={words} width={width}
          height={wordCardHeight}
          titleHeight={titleRef.current?.offsetHeight}
          wordPageTitleHeight={titleRef.current?.offsetHeight}
        />
      ) : (
        <>
          {specialContent && (
            <Box height={chartHeight} width={width}>
              {specialContent}
            </Box>)
          }
          {chart && item !== "ECS" && specialContent === null && chart.customInstructionsPage === undefined && (
            <ModuleChart chartData={chart} height={chartHeight} width={width} chartModule={chartModule} item={item} subitem={subitem} divider={1.0}/>
          )}
          {chart && item !== "ECS" && specialContent === null && chart.customInstructionsPage !== undefined && (
            <Box sx={{ mt: `${titleRef.current?.offsetHeight}px`, ml: '20px', mr: '20px' }}>
              {chart.customInstructionsPage}
            </Box>
          )}
          {item === "ECS" && specialContent === null && (
            <ECSPage height={chartHeight} width={width} />
          )}
        </>
      )}
    </Box>
  );
}

export default ProtocolContent;