import { Typography } from "@mui/material";
import { Link } from 'react-router-dom';
import { NoInfoPopUp, WordsPopUp } from "../popup"
import { popupIdMappingType } from "../types"
import { AngelsPopups } from "../AngelsPopups"

export const KarmaPopUp = () => {
    return (
        <div>
            <Typography>Clearing needed in Karma Style.</Typography>
            <Typography>Consult &nbsp;
                <Link to="?module=Karma">Karma Style</Link>
            </Typography>
        </div>
    );

}

export const FreedomPopUps: popupIdMappingType = {
    "Freedom": {
        "Frequency": {
            //All Have no info
            "FrequencyNoInfo": <NoInfoPopUp />,
        },

        "Incongruence": {
            "PastLife": <WordsPopUp words={["Karma", "Trauma", "Soul Lesson"]} />,
            "Allergy": <WordsPopUp words={["Thought", "Idea", "Substance"]} />,
            "Genetics": <NoInfoPopUp />,
            "Karma": <KarmaPopUp />,
            "Chromo": <WordsPopUp words={["Chromosome Abnormality"]} />,
            "Envirn": <WordsPopUp words={["Environmental Toxins"]} />,
            "Vaccs": <WordsPopUp words={["Vaccinations-Medication Toxicity"]} />,
            "GMO": <NoInfoPopUp />,
            "Group": <NoInfoPopUp />,
            "Other": <NoInfoPopUp />,
            "NoInfo": <NoInfoPopUp />,

        },

        "Animal": {
            //All have no info in modal
            "AnimalSyncNoInfo": <NoInfoPopUp />,
        },
        
        "Angels": {
            ...AngelsPopups,
        },

        "SelfCare": {
            //All Have no info
            "SelfCareNoInfo": <NoInfoPopUp />,
        },
    }
}