import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
    Tab, Tabs
} from '@mui/material';
import { navItemToText, NavItem } from '../../data/types'


const BottomNav: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const currentNavItem = queryParams.get('bottom_nav') || 'protocol';


    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        let path = '/protocol';
        let params = new URLSearchParams();
        params.append('bottom_nav', newValue);
        path += '?' + params.toString();
        navigate(path);
    };

    return <Tabs
        value={currentNavItem}
        onChange={handleChange}
        textColor="inherit"
        indicatorColor="primary"
        aria-label="Top Nav Tabs"
        sx={{ textTransform: 'none', mb: 1 }}
    >
        {Object.keys(navItemToText).map((navItem) => (
            <Tab
                key={navItem}
                value={navItem}
                label={navItemToText[navItem as NavItem]}
                color="inherit"
                sx={{
                    color: currentNavItem === navItem ? 'primary.main' : 'inherit',
                }}
            />
        ))}
    </Tabs>
};

export default BottomNav;