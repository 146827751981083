import React, { useRef, useState, useEffect } from 'react';
import ModuleTitle from '../ModuleTitle/ModuleTitle';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { StepperContentMapType } from '../../data/Protocol/ProtocolData/StepPage';
import { NoInfoPopUp, WordsPopUp, WordListPopUp } from '../../data/popup';
import StepPageContent from '../ProtocolContent/StepPageContent';
import GenerationsChart from './Tree';
import PdfDialog from '../PdfDialog/PdfDialog';
import { useWindowSize, useVisibilityChange } from '@uidotdev/usehooks';
import { Button } from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';


const InstructionsCard: React.FC = () => {
  const [clearingSessionWsOpen, setClearingSessionWsOpen] = useState(false);
  const [wellnessWorkSheetOpen, setWellnessWorkSheetOpen] = useState(false);

  const handleClearingSessionWsClose = () => {
    setClearingSessionWsOpen(false);
  };

  const handleWellnessWorkSheetClose = () => {
    setWellnessWorkSheetOpen(false);
  }

  return (
    <Card sx={{ maxWidth: 600, margin: "auto", mt: 4, padding: 2, boxShadow: 3 }}>
      <CardContent>
        <Typography variant="h6" sx={{ mb: 1 }}>
          <strong>Instructions</strong>
        </Typography>
        <Typography variant="body1" sx={{ mb: 0 }}>
          Use the <strong>Clearing Session Worksheet</strong> to guide you through the{" "}
          <strong>Ancestry, Pre-Protocol, and Protocol</strong> sections.
        </Typography>
        <Typography variant="body1" ml={2}>
          <strong>•</strong> Download Clearing Session Worksheet
          <Button size="small" sx={{ margin: 0, textTransform: 'none' }} onClick={() => { setClearingSessionWsOpen(true) }}>
            <DescriptionIcon sx={{ margin: 0 }} fontSize="small" />
            view
          </Button>
        </Typography>
        <Typography variant="body1" sx={{ mt: 2 }}>
          Additionally, you can use the <strong>Wellness Revolution Worksheet</strong> to track your
          sessions using the <strong>Trailblazing System</strong>.
        </Typography>
        <Typography variant="body1" ml={2}>
          <strong>•</strong> Download Wellness Revolution Worksheet
          <Button size="small" sx={{ margin: 0, textTransform: 'none' }} onClick={() => { setWellnessWorkSheetOpen(true) }}>
            <DescriptionIcon sx={{ margin: 0 }} fontSize="small" />
            view
          </Button>
        </Typography>
        <PdfDialog open={clearingSessionWsOpen} onClose={handleClearingSessionWsClose} pdfName="ClearingSessionWorksheet.pdf" />
        <PdfDialog open={wellnessWorkSheetOpen} onClose={handleWellnessWorkSheetClose} pdfName="Wellness Revolution.pdf" />
      </CardContent>
    </Card>
  );
};

const Steps: StepperContentMapType = {
  'Instructions': {
    label: 'Instructions',
    customText: (<InstructionsCard />)
  },
  'Category': {
    label: 'Category',
    chart: {
      chart: [
        { id: 0, label: '1 - Fears', value: 1, color: '#C31F26', popupContent: <NoInfoPopUp /> },
        { id: 1, label: '2 - Emotions', value: 1, color: '#E97A23', popupContent: <NoInfoPopUp /> },
        { id: 2, label: '3 - Traumatic Injury', value: 1, color: '#F0B731', popupContent: <NoInfoPopUp /> },
        { id: 3, label: '4 - Anc/Gen', value: 1, color: '#94C739', popupContent: <WordsPopUp words={['Ancestral/Generational']} /> },
        { id: 4, label: '5 - Geo Earth Stress', value: 1, color: '#4FAD45', popupContent: <WordsPopUp words={['Geopathic Earth Stress']} /> },
        { id: 5, label: '6 - Implants', value: 1, color: '#209692', popupContent: <NoInfoPopUp /> },
        { id: 6, label: '7 - Childbirth', value: 1, color: '#2A468A', popupContent: <NoInfoPopUp /> },
        { id: 7, label: '8 - Abuse', value: 1, color: '#5E409A', popupContent: <NoInfoPopUp /> },
        { id: 8, label: '9 - Poor Nutrition', value: 1, color: '#774192', popupContent: <NoInfoPopUp /> },
        { id: 9, label: '10 - Karmic', value: 1, color: '#804B3F', popupContent: <NoInfoPopUp /> },
        { id: 10, label: '11 - Astrological', value: 1, color: '#935E35', popupContent: <NoInfoPopUp /> },
        { id: 11, label: '12 - Nature', value: 1, color: '#E97A23', popupContent: <NoInfoPopUp /> },
        { id: 12, label: '13 - Num Prog', value: 1, color: '#F0B731', popupContent: <WordsPopUp words={['Numbers Program']} /> },
        { id: 13, label: '14 - Situations', value: 1, color: '#94C739', popupContent: <NoInfoPopUp /> },
        { id: 14, label: '15 - Freq of Illness', value: 1, color: '#4FAD45', popupContent: <WordsPopUp words={['Frequency of Illness']} /> },
        { id: 15, label: '16 - Gen Mod', value: 1, color: '#209692', popupContent: <WordsPopUp words={['Genetic Modification']} /> }
      ],
      level: 0,
      disableClick: false,
      subPages: {}
    }
  },
  'Ancestor': {
    label: 'Ancestor',
    customChart : GenerationsChart
  },
  'Illness': {
    label: 'Illness',
    chart: {
      chart: [
        { id: 0, label: '#10 Mental Illness', value: 1, color: '#C31F26', popupContent: <WordListPopUp words={['6321', '2570', '7051', '3681', '6318', '1570', '4702', '5170', '3745', '5280']} /> },
        { id: 1, label: '#5 Heart Disease', value: 1, color: '#E97A23', popupContent: <WordListPopUp words={['4758', '2833', '0264', '7451', '5815']} /> },
        { id: 2, label: '#1 Autism', value: 1, color: '#F0B731', popupContent: <WordsPopUp words={['5250']} /> },
        { id: 3, label: '#4 Obesity', value: 1, color: '#94C739', popupContent: <WordListPopUp words={['0147', '3724', '3810', '3649']} /> },
        { id: 4, label: '#1 Blood Disorder', value: 1, color: '#4FAD45', popupContent: <WordsPopUp words={['8155']} /> },
        { id: 5, label: '#5 Autoimmune', value: 1, color: '#209692', popupContent: <WordListPopUp words={['4629', '2794', '2842', '1556', '7450']} /> },
        { id: 6, label: 'Cancer #15', value: 1, color: '#2A468A', popupContent: <WordListPopUp words={['5555', '3554', '4205', '6216', '3636', '3420', '7418', '6416', '4761', '7114', '3516', '2684', '6357', '1100', '0101']} /> },
        { id: 7, label: 'Diabetes #5', value: 1, color: '#263A8E', popupContent: <WordListPopUp words={['6181', '7255', '3205', '2266', '4830']} /> },
        { id: 8, label: 'Digestive Disorder #3', value: 1, color: '#5E409A', popupContent: <WordListPopUp words={['3749', '4252', '7164']} /> },
        { id: 9, label: 'Stroke #1', value: 1, color: '#774192', popupContent: <WordsPopUp words={['5041']} /> },
        { id: 10, label: 'Genetics #7', value: 1, color: '#804B3F', popupContent: <WordListPopUp words={['6534', '2641', '5730', '4781', '6153', '1248', '7337']} /> },
        { id: 11, label: 'Lack of Self Love #0', value: 1, color: '#C31F26', popupContent: <WordsPopUp words={['2296', '4073']} /> }
      ],
      level: 0,
      disableClick: false,
      subPages: {}
    }
  },
}

interface PrePreProtocolContentProps {
  height: number;
  width: number;
}


const PrePreProtocolContent: React.FC<PrePreProtocolContentProps> = ({ width, height }) => {
  const titleRef = useRef<HTMLDivElement>(null);
  const size = useWindowSize();
  const [chartHeight, setChartHeight] = useState<number>(height);

  useEffect(() => {
    setChartHeight(height - (titleRef.current?.offsetHeight ?? 0));
  }, [size, height]);


  const specialContent = (() => {
    const content = Steps;
    if (height > 0 && width > 0) {
      return <StepPageContent stepMap={content} width={width} height={chartHeight} titleHeight={titleRef.current?.offsetHeight ?? 0} finalStepLabel='To Pre-Protocol' finalStepLink='/protocol?bottom_nav=pre_protocol' />
    }
    return null;
  })();
  const documentVisible = useVisibilityChange();

  return (
    <Box style={{ position: 'relative', flexGrow: 1 }}>
      <Box style={{ position: 'absolute', top: 'desiredValue', zIndex: 1 }} ref={titleRef}>
        <ModuleTitle module={"Trauma Genius"} />
      </Box>
      {specialContent && documentVisible && (
        <Box style={{ position: 'absolute', top: 0, width: '100%' }}>
          {specialContent}
        </Box>
      )}
    </Box>
  );
}

export default PrePreProtocolContent;