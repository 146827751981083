import React, { useRef, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Typography from '@mui/material/Typography';
import ModuleChart from '../ModuleChart/ModuleChart';
import ModuleTitle from '../ModuleTitle/ModuleTitle';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { useWindowSize } from '@uidotdev/usehooks';
import { PreProtocolMap, PreProtocolSteps, PreProtocolItem } from '../../data/Protocol/PreProtocolData';
import { NavItem } from '../../data/types';
import { useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';

interface PreProtocolContentProps {
  height: number;
  width: number;
}

const isValidStep = (step: string): step is PreProtocolSteps => {
  return Object.values(PreProtocolSteps).includes(step as PreProtocolSteps);
};

const PreProtocolContent: React.FC<PreProtocolContentProps> = ({ width, height }) => {
  const stepperRef = useRef<HTMLDivElement>(null);
  const size = useWindowSize();
  const navigate = useNavigate();
  const [chartWidth, setChartWidth] = useState<number>(width);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const paramsStep = queryParams.get('step') || PreProtocolSteps.DIRECTION;
  const paramsSubStep = queryParams.get('sub_step') || null;
  const currentStep: PreProtocolSteps = isValidStep(paramsStep) ? paramsStep : PreProtocolSteps.DIRECTION as PreProtocolSteps;
  const currentStepIndex = Object.values(PreProtocolSteps).indexOf(currentStep);

  useEffect(() => {
    setChartWidth(width - (stepperRef.current?.offsetHeight ?? 0));
  }, [size, width]);

  const hasSubSteps = PreProtocolMap[currentStep].subSteps && Object.keys(PreProtocolMap[currentStep].subSteps!).length > 0;

  const chart = paramsSubStep !== null
    ? PreProtocolMap[currentStep].subSteps![paramsSubStep].chart
    : PreProtocolMap[currentStep].chart;

  const handleStepClick = (index: number) => {
    const stepKey = Object.keys(PreProtocolSteps)[index];
    const stepValue = PreProtocolSteps[stepKey as keyof typeof PreProtocolSteps];

    // Preserve existing query parameters
    const queryParams = new URLSearchParams(location.search);
    queryParams.set('step', stepValue);
    queryParams.delete('sub_step');

    const newUrl = `${location.pathname}?${queryParams.toString()}`;
    navigate(newUrl);
  };

  const handleBack = () => {
    if (paramsSubStep === null) {
      handleStepClick(currentStepIndex - 1);
    }
    else {
      // delete the sub_step param and keep the current step
      handleStepClick(currentStepIndex);
    }
  };

  const handleNext = () => {
    if (currentStepIndex === Object.values(PreProtocolSteps).length - 1) {
      // move to a new tab within the protocol
      const queryParams = new URLSearchParams();
      queryParams.set('bottom_nav', NavItem.PROTOCOL);
      const newUrl = `${location.pathname}?${queryParams.toString()}`;
      navigate(newUrl);
    }
    else {
      handleStepClick(currentStepIndex + 1);
    }
  }
  const handleSubStepClick = (label: string) => {
    const stepKey = Object.keys(PreProtocolSteps)[currentStepIndex];
    const stepValue = PreProtocolSteps[stepKey as keyof typeof PreProtocolSteps];

    // Preserve existing query parameters
    const queryParams = new URLSearchParams(location.search);
    queryParams.set('step', stepValue);
    queryParams.set('sub_step', label);

    const newUrl = `${location.pathname}?${queryParams.toString()}`;
    navigate(newUrl);
  };

  useEffect(() => {
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  }, [location, currentStep]);

  return (
    <Grid container spacing={0} direction="row">
      <Grid item xs={1} md={2} ref={stepperRef}>
        <ModuleTitle module={"Pre-Protocol"} />
        <Typography
          sx={{
            fontSize: { xs: '0.8rem', sm: '1rem', md: '1.2rem', marginLeft: '20px' },
            mb: 1,
          }}
        >
          Steps
        </Typography>
        <Box sx={{ marginLeft: '20px' }}>
          <Stepper
            activeStep={currentStepIndex}
            orientation="vertical"
            sx={{
              '& .MuiStep-root': { my: 0.5 },
              '& .MuiStepLabel-label': {
                fontSize: { xs: '0.7rem', sm: '0.8rem', md: '0.9rem' },
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }
            }}
          >
            {Object.values(PreProtocolMap).map((step: PreProtocolItem, index: number) => (
              <Step key={step.label} sx={{
                py: 0, '& .MuiStepLabel-root.Mui-disabled': {
                  cursor: 'pointer',
                },
              }} >
                <StepLabel sx={{ py: 0, cursor: 'pointer' }}>
                  {step.label}
                </StepLabel>
                <StepContent sx={{ cursor: 'pointer' }}>
                  <Box sx={{ mb: 2 }}>
                    <Button
                      onMouseDown={(e) => e.preventDefault()}
                      disabled={currentStepIndex === 0}
                      onClick={handleBack}
                      sx={{
                        mt: 1, mr: 1,
                        WebkitTapHighlightColor: 'transparent',
                        '&:hover': { bgcolor: 'transparent' }
                      }}
                    >
                      Back
                    </Button>
                    {hasSubSteps && paramsSubStep === null ? (
                      Object.keys(PreProtocolMap[currentStep].subSteps!).map((subStepKey: string) => (
                        <Button
                          onMouseDown={(e) => e.preventDefault()}
                          key={subStepKey}
                          variant="contained"
                          onClick={() => handleSubStepClick(subStepKey)}
                          sx={{
                            mt: 1, mr: 1,
                            WebkitTapHighlightColor: 'transparent',
                            '&:hover': { bgcolor: 'primary.main' }
                          }}
                        >
                          {PreProtocolMap[currentStep].subSteps![subStepKey].label}
                        </Button>
                      ))
                    ) : (
                      <>
                        <Button
                          onMouseDown={(e) => e.preventDefault()}
                          variant="contained"
                          disableElevation={true}
                          disableRipple={true}
                          onClick={handleNext}
                          sx={{
                            mt: 1, mr: 1,
                            WebkitTapHighlightColor: 'transparent',
                            '&:hover': { bgcolor: 'primary.main' }
                          }}
                        >
                          {currentStepIndex === Object.values(PreProtocolSteps).length - 1 ? 'To Protocol' : 'Continue'}
                        </Button>
                      </>
                    )}
                  </Box>
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </Box>
      </Grid>
      <Grid item xs={11} md={9} container sx={{
        justifyContent: "center",
        alignItems: "center",
      }} direction="column">
        <ModuleChart chartData={chart} height={Math.min(height, chartWidth)} width={chartWidth} chartModule='Pre-Protocol' item={null} subitem={null} />
      </Grid>
    </Grid>
  );
}

export default PreProtocolContent;