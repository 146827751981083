import React from 'react';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Breadcrumbs, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { NavItem } from '../../data/types'

interface ProtocolBreadcrumbsProps {
    module: string | null;
    item: string | null;
    subItem: string | null;
    detail: string | null;
}

export const ProtocolBreadcrumbs: React.FC<ProtocolBreadcrumbsProps> = ({ module, item, subItem, detail }) => {
    const navigate = useNavigate();
    let params = new URLSearchParams();

    const handleAllModulesClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      params.set('bottom_nav', NavItem.PROTOCOL);
      navigate(`/protocol?${params.toString()}`)
    }

    const handleModuleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        params.set('bottom_nav', NavItem.PROTOCOL);
        params.append('module', module as string);
        navigate(`/protocol?${params.toString()}`)
    }
    const handleItemClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        params.set('bottom_nav', NavItem.PROTOCOL);
        params.append('module', module as string);
        params.append('item', item as string);
        navigate(`/protocol?${params.toString()}`)
    }
    const handleSubItemClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        params.set('bottom_nav', NavItem.PROTOCOL);
        params.append('module', module as string);
        params.append('item', item as string);
        params.append('subitem', subItem as string);
        navigate(`/protocol?${params.toString()}`)
    }
    const handleDetailClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        params.set('bottom_nav', NavItem.PROTOCOL);
        params.append('module', module as string);
        params.append('item', item as string);
        params.append('subitem', subItem as string);
        params.append('detail', detail as string);
        navigate(`/protocol?${params.toString()}`)
    }

    return (
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} sx={{ marginTop: '20px', marginLeft: '20px' }}>
            <Link underline="hover" color="inherit" onClick={handleAllModulesClick}>Protocol</Link>
            {module && module !== "Protocol" && <Link underline="hover" color="inherit" onClick={handleModuleClick}>{module}</Link>}
            {item && <Link underline="hover" color="inherit" onClick={handleItemClick}>{item}</Link>}
            {subItem && <Link underline="hover" color="inherit" onClick={handleSubItemClick} >{subItem}</Link>}
            {detail && <Link underline="hover" color="inherit" onClick={handleDetailClick} >{detail}</Link>}
        </Breadcrumbs>
    )
};

export default ProtocolBreadcrumbs;