import { ChartPageType } from '../../types';
import { NutritionPopUps } from '../../Nutrition/popups';
import { NoInfoPopUp, WordsPopUp } from "../../popup"
import { TraumaPeoplePopUps } from '../../TraumaPeople/popups'
import { TraumaAnimalPopUps } from '../../TraumaAnimal/popups';
import {
    Typography, Box, Link,
    Card, CardContent,
    CardHeader,
} from "@mui/material";
import { SUB_TEXT_TYPOGRAPHY_PROPS } from '../../../utils';

export const NotYetImplementedPopUp = () => {
    return (
        <div>
            <Typography>Not Yet Implemented</Typography>
        </div>
    );

}

export const AddictionPopUp = () => {
    return (
        <div>
            <Typography>
                -You will work on all of these for addiction issues.
            </Typography>
            <Typography>
                -See which Heart you will work on first.
                After determining which heart you will work on, start in the protocol to begin the clearing process.
            </Typography>
            <Typography>
                -After the session is complete, see how long until you can work on the next heart area.
                After time period, find the next heart area to work on until they are all complete.
            </Typography>
            <Typography>
                -Balancing All Hearts session is the last session you will do in this process.
            </Typography>
        </div>
    );
};

export const NanobotsClearingPopUp = ({ botCategory }: { botCategory: string }) => {
    return (
        <div>
            <Typography>Deactivate & render un-useable all bots whose mission related to {botCategory} by
                sending & targeting with the MRI frequency of 1MHZ-300MHZ only at
                the mission bots & no other part of <i>Persons Name</i> body.
                Clear any & all affects that have risen from their residency.
            </Typography>
        </div>
    );
}

const SRSChartText = () => {
    return (
        <Box style={{ textAlign: 'center' }}>
            <Typography {...SUB_TEXT_TYPOGRAPHY_PROPS}>Check how many sessions needed, and do a full session on each one.</Typography>
        </Box>
    );
}

const CleanUpSessionsText = () => {
    return (
        <Box style={{ textAlign: 'center' }}>
            <Typography {...SUB_TEXT_TYPOGRAPHY_PROPS}>Choose either Part 1 or Part 2</Typography>
        </Box>
    );
}

const NavToPeopleOrAnimal = () => {
    return (
        <Box style={{ textAlign: 'center' }}>
            <Typography component={'div'}>
                Continue to:
                <Typography><Link href="/?module=Trauma+People">Trauma People Style</Link> (for humans)</Typography>
                <Typography><Link href="/?module=Trauma+Animal">Trauma Animal Style</Link> (for animals)</Typography>
            </Typography>
        </Box>
    );
}
export const SeeOtherChart = ({ href, text }: { href: string, text: string }) => {
    return (
        <div>
            <Typography>See chart in&nbsp;
                <Link href={href}>{text}</Link>
            </Typography>
        </div>
    );
}

const PointsOfLightText = () => {
  return (
    <Box style={{ textAlign: 'center' }}>
      <Typography {...SUB_TEXT_TYPOGRAPHY_PROPS}>
        Obtain starting color and ending color and then allow for the clearing.
      </Typography>
    </Box>
  );
}



const LandRejuvenationText = () => {
    const indentMarginStep = 1;
    const indentMarginSubStep = indentMarginStep + 2;
    const itemSpacing = 2;
    return (<>
        <Card sx={{ mx: '20px' }} >
            <CardHeader sx={{ paddingBottom: '0px' }} title="Land Rejuvenation" />
            <CardContent>
                <Typography variant="body1" component={'div'} ml={indentMarginStep}>
                    1. Connect with the land via intention, and ask if it is ready and up for a cleansing.
                    <Typography variant="body1" component={'p'} ml={indentMarginSubStep}>
                        a. Check in to see if all the residents of the land are ready and healthy enough for the cleansing (including people and animals, domestic and wild).
                    </Typography>
                    <Typography variant="body1" component={'p'} ml={indentMarginSubStep}>
                        b. Take before and after photos of the land to document changes.
                    </Typography>
                </Typography>


                <Typography variant="body1" component={'p'} ml={indentMarginStep} mt={itemSpacing}>
                    2. Set your perimeter or borders for your cleansing with your intention. Then ask if the cleansing will be within these borders or if it will reach beyond. If you want or need to know how far it will reach, you can use one of the numbers charts (e.g., for miles, acres).
                </Typography>

                <Typography variant="body1" component={'p'} ml={indentMarginStep} mt={itemSpacing}>
                    3. Call in your angels, helpers, and guides, earth fairies, and protective dragons to aid the earth and protect it in its cleansing process. Honor and guard the land’s vulnerability as it cleanses. Also ask that the cleansing be done in a kind and gentle way for all who will be affected.
                </Typography>

                <Typography variant="body1" component={'p'} ml={indentMarginStep} mt={itemSpacing}>
                    4. Add, balance, and adjust the levels of forgone energy in the land to bring perfect Divine balance back to the soils and plants.
                </Typography>

                <Typography variant="body1" component={'div'} ml={indentMarginStep} mt={itemSpacing}>
                    5. Call in the frequency for ammonia and the balance of Aglime and Dolomite lime in
                    whatever percentages are needed for neutralizing and balancing. With intention and a
                    swinging/spinning of the pendulum, envision sprinkling the ammonia and lime over the
                    land to clear and neutralize. Ask to balance the soil’s alkalinity,
                    restore and balance all nutrients, vitamins, and minerals—everything the land uniquely
                    needs at this time to be perfectly healthy and balanced. In doing so, you will neutralize the effects and remnants of:
                    <Typography variant="body1" component={'p'} ml={indentMarginSubStep}>
                        a. All herbicides
                    </Typography>
                    <Typography variant="body1" component={'p'} ml={indentMarginSubStep}>
                        b. All insecticides
                    </Typography>
                    <Typography variant="body1" component={'p'} ml={indentMarginSubStep}>
                        c. All fungicides
                    </Typography>
                    <Typography variant="body1" component={'p'} ml={indentMarginSubStep}>
                        d. All natural pesticides
                    </Typography>
                    <Typography variant="body1" component={'p'} ml={indentMarginSubStep}>
                        e. All soil fumigants, desiccants, harvest aids, and growth regulators
                    </Typography>
                    <Typography variant="body1" component={'p'} ml={indentMarginSubStep}>
                        f. Pharmaceuticals
                    </Typography>
                    <Typography variant="body1" component={'p'} ml={indentMarginSubStep}>
                        g. Emotional energies from all inhabitants (from any and all lifetimes)
                    </Typography>
                    <Typography variant="body1" component={'p'} ml={indentMarginSubStep}>
                        g. Anything and everything that has affected the land in a negative and/or unhealthy way
                    </Typography>
                </Typography>

                <Typography variant="body1" component={'p'} ml={indentMarginStep} mt={itemSpacing}>
                    5. Go to a <Link href="/?module=Nature&item=Time+Chart">Time Chart</Link> and ask how long this body will need to cleanse and do this process and document the answer.
                </Typography>
                <Typography variant="body1" component={'p'} ml={indentMarginStep} mt={itemSpacing}>
                    Make sure that you take extra care of yourself as your body cleanses, detoxes, and shifts.
                </Typography>
            </CardContent>
        </Card>
    </>);
}


const BodyRejuvenationText = () => {
    const indentMarginStep = 1;
    const indentMarginSubStep = indentMarginStep + 2;
    const itemSpacing = 2;

    return (
        <Card sx={{ mx: "20px" }}>
            <CardHeader sx={{ paddingBottom: "0px" }} title="Body Rejuvenation" />
            <CardContent>
                {/* Step 1 */}
                <Typography variant="body1" component="div" ml={indentMarginStep}>
                    1. Connect with the body via intention, and ask if it is ready and up for a cleanse.
                    <Typography variant="body1" component="p" ml={indentMarginSubStep}>
                        a. Let the person know that you are working on them and that they may have a variety of detox symptoms.
                    </Typography>
                </Typography>

                {/* Step 2 */}
                <Typography variant="body1" component="p" ml={indentMarginStep} mt={itemSpacing}>
                    2. Set the perimeter of the session, to include the physical body, the spiritual body, and the energy fields around the body.
                </Typography>

                {/* Step 3 */}
                <Typography variant="body1" component="p" ml={indentMarginStep} mt={itemSpacing}>
                    3. You will be finding your helpers when you start the session, beginning with the Trauma Genius Process. Ask that the process be done in a kind and gentle way.
                </Typography>

                {/* Step 4 */}
                <Typography variant="body1" component="div" ml={indentMarginStep} mt={itemSpacing}>
                    4. Add, balance, and adjust the body’s alkalinity, restore and balance all nutrients, vitamins, and minerals—
                    everything the body uniquely needs at this time to be perfectly healthy and balanced. In doing so,
                    you will also be neutralizing the effects and remnants of:
                    <Typography variant="body1" component="p" ml={indentMarginSubStep}>
                        a. All pharmaceuticals
                    </Typography>
                    <Typography variant="body1" component="p" ml={indentMarginSubStep}>
                        b. All chemicals
                    </Typography>
                    <Typography variant="body1" component="p" ml={indentMarginSubStep}>
                        c. All toxins
                    </Typography>
                    <Typography variant="body1" component="p" ml={indentMarginSubStep}>
                        d. All emotional energies
                    </Typography>
                    <Typography variant="body1" component="p" ml={indentMarginSubStep}>
                        e. Anything and everything that has and is affecting this body in a negative and/or unhealthy way
                    </Typography>
                </Typography>

                {/* Step 5 */}
                <Typography variant="body1" component="p" ml={indentMarginStep} mt={itemSpacing}>
                    5. Go to a <Link href="/?module=Nature&item=Time+Chart">Time Chart</Link> and ask how long this body will need to cleanse and do this process, and document the answer.
                </Typography>

                {/* Final note */}
                <Typography variant="body1" component="p" ml={indentMarginStep} mt={itemSpacing}>
                    Make sure that you take extra care of yourself as your body cleanses, detoxes, and shifts.
                </Typography>
            </CardContent>
        </Card>
    );
};


const LayOfTheLandText = () => {
    const indentMarginStep = 1;
    const itemSpacing = 2;

    return (
        <Card sx={{ mx: "20px" }}>
            <CardHeader sx={{ paddingBottom: "0px" }} title="Lay of the Land" />
            <CardContent>
                {/* Step 1 */}
                <Typography variant="body1" component="p" ml={indentMarginStep}>
                    1. Connect with the situation at hand, whether it be a state of being, a relationship, a situation, or whatever this may be about.
                </Typography>

                {/* Step 2 */}
                <Typography variant="body1" component="p" ml={indentMarginStep} mt={itemSpacing}>
                    2. Set the perimeter or boundaries for the cleansing to include the energy of any of the previously stated situations.
                    You cannot work on someone without permission, but if you have permission to work on one side of a relationship or situation,
                    you will be working on the energy between them and/or the person you have permission to work on.
                </Typography>

                {/* Step 3 */}
                <Typography variant="body1" component="p" ml={indentMarginStep} mt={itemSpacing}>
                    3. You will be finding your helpers and guides when you start this session, beginning with the Trauma Genius Process.
                    Ask that the shifting be done in a kind and gentle way.
                </Typography>

                {/* Step 4 */}
                <Typography variant="body1" component="p" ml={indentMarginStep} mt={itemSpacing}>
                    4. Call in the frequencies of balance for the energies involved that you have permission to work on and/or that may
                    unconsciously give you permission to work on them and the energies that play into the situation at hand. Ask to restore
                    balance and all things necessary to bring this situation to a perfectly healthy and balanced state. To bring clarity
                    and wisdom if needed, and anything else that will shift the situation to the intended and hoped-for resolution. To bring
                    neutralizing and detoxing if needed and to clear anything that is having a negative or unhealthy effect on the parties involved.
                </Typography>

                {/* Step 5 */}
                <Typography variant="body1" component="p" ml={indentMarginStep} mt={itemSpacing}>
                    5. Go to a <Link href="/?module=Nature&item=Time+Chart">Time Chart</Link> and ask how long this cleanse will take to complete and document the answer.
                </Typography>

                {/* Note */}
                <Typography variant="body1" component="p" ml={indentMarginStep} mt={itemSpacing}>
                    Note that all the people involved or named, even the ones that have not directly given permission, may experience a shift.
                    When one person in a situation or relationship shifts, it automatically shifts the energy dynamics of the entire relationship.
                </Typography>
            </CardContent>
        </Card>
    );
};







export enum ProtocolModules {
    ORIGIN = 'origin',
    ENERGETIC_SEED = 'energetic_seed',
    TRAUMA = 'trauma',
    UNDERLING_CAUSE = 'underlying_cause'
};

export const ProtocolMapping: ChartPageType = {
    chart: [
        { label: 'Origin', value: 1, id: 0, color: '#C0202E', onClickNav: "/protocol?bottom_nav=protocol&module=Origin" },
        { label: 'Energetic Seed', value: 1, id: 1, color: '#F4802A', onClickNav: "/protocol?bottom_nav=protocol&module=Energetic+Seed" },
        { label: 'Trauma', value: 1, id: 2, color: '#F3B01A', onClickNav: "/protocol?bottom_nav=protocol&module=Trauma" },
        { label: 'Autoimmune', value: 1, id: 3, color: '#8BC640', onClickNav: "/protocol?bottom_nav=protocol&module=Autoimmune" },
        { label: 'Underlying Cause', value: 1, id: 4, color: '#283890', popupContent: <WordsPopUp words={['Do a regular session starting with an intention statement.']} /> }
    ],
    level: 0,
    subPages: {
        'Origin': {
            chart: [
                { id: 0, label: 'Chromosome', value: 1, color: '#C31F26' },
                { id: 1, label: 'Planetary', value: 1, color: '#E97A23' },
                { id: 2, label: 'Exotic Implants', value: 1, color: '#F0B731' },
                { id: 3, label: 'Galactic', value: 1, color: '#94C739' },
                { id: 4, label: 'Circadian Cycle', value: 1, color: '#4FAD45' },
                { id: 5, label: 'Vaccines', value: 1, color: '#209692' },
                { id: 6, label: 'Soul Work', value: 1, color: '#2A468A' },
                { id: 7, label: 'Fetal Cells', value: 1, color: '#6a268e' }
            ],
            level: 1,
            parent: 'Protocol',
            disableClick: false,
            subPages: {
                'Vaccines': {
                    chart: [
                        { id: 0, label: 'Vaccines', value: 1, color: '#D32F2F' },
                        { id: 1, label: 'Origination', value: 1, color: '#E97A23' },
                        { id: 2, label: 'Vaccine Ingredients', value: 1, color: '#F0B731' },
                        { id: 3, label: 'System Affected', value: 1, color: '#8BC34A' },
                        { id: 4, label: 'Area Affected', value: 1, color: '#4CAF50' },
                        { id: 5, label: 'Life Areas Challenged', value: 1, color: '#0097A7' },
                        { id: 6, label: 'Actions', value: 1, color: '#2A468A' }
                    ],
                    level: 2,
                    parent: 'Origin',
                    disableClick: false,
                    subPages: {
                        'Actions': {
                            chart: [
                                { id: 0, labelWithFormatting: 'Turn Off Immune\nSystem Alarm', label: 'Turn Off Immune System Alarm', value: 1, color: '#D32F2F', popupContent: <NoInfoPopUp /> },
                                { id: 1, labelWithFormatting: 'Turn Off Maternal\nImmune System Alarm', label: 'Turn Off Maternal Immune System Alarm', value: 1, color: '#E97A23', popupContent: <WordsPopUp words={['Turn Off Maternal Immune System Alarm']} /> },
                                { id: 2, labelWithFormatting: 'Detox Metals\nfrom\nBrain-Body-Both', label: 'Detox Metals from Brain-Body-Both', value: 1, color: '#F0B731', popupContent: <WordsPopUp words={['Brain?', 'Body?', 'Both?']} /> },
                                { id: 3, labelWithFormatting: 'Neuronal Circuitry\nImbalances', label: 'Neuronal Circuitry Imbalances', value: 1, color: '#8BC34A', popupContent: <WordsPopUp words={['Synapse Formation', 'Dendric Spine Development', 'Neuronal Circuit Balance', 'Synaptic Plasticity']} /> },
                            ],
                            level: 2,
                            parent: 'Origin',
                            disableClick: false,
                            subPages: {}
                        },
                        'Life Areas Challenged': {
                            chart: [
                                { id: 0, label: 'Mental', value: 1, color: '#D32F2F', popupContent: <NoInfoPopUp /> },
                                { id: 1, label: 'Physical', value: 1, color: '#E97A23', popupContent: <NoInfoPopUp /> },
                                { id: 2, label: 'Emotional', value: 1, color: '#F0B731', popupContent: <NoInfoPopUp /> },
                                { id: 3, label: 'Spiritual', value: 1, color: '#8BC34A', popupContent: <NoInfoPopUp /> },
                                { id: 4, label: 'Situational', value: 1, color: '#4CAF50', popupContent: <NoInfoPopUp /> },
                                { id: 5, label: 'Relational', value: 1, color: '#0097A7', popupContent: <NoInfoPopUp /> },
                                { id: 6, label: 'Other', value: 1, color: '#2A468A', popupContent: <NoInfoPopUp /> }
                            ],

                            level: 3,
                            parent: 'Origin',
                            disableClick: false,
                            subPages: {}
                        },


                        "Area Affected": {
                            chart: [
                                { id: 0, label: 'Systems', value: 1, color: '#D32F2F', popupContent: <SeeOtherChart href={"/?module=Trauma+People&item=Systems"} text='Trauma People Style' /> },
                                { id: 1, label: 'Tissues', value: 1, color: '#E97A23', popupContent: <NoInfoPopUp /> },
                                { id: 2, label: 'Body Part', value: 1, color: '#F0B731', popupContent: <SeeOtherChart href={"/?module=Trauma+People&item=Body+Area"} text='Trauma People Style' /> },
                                { id: 3, label: 'Organs', value: 1, color: '#8BC34A', popupContent: <SeeOtherChart href={"/?module=Trauma+People&item=Body+Area&subitem=Organs"} text='Trauma People Style' /> },
                                { id: 4, label: 'Endocrines', value: 1, color: '#4CAF50', popupContent: <SeeOtherChart href={"/?module=Trauma+People&item=Body+Area&subitem=Endocrine"} text='Trauma People Style' /> },
                                { id: 5, label: 'Connectors', value: 1, color: '#0097A7', popupContent: <SeeOtherChart href={"/?module=Trauma+People&item=Connectors"} text='Trauma People Style' /> },
                                { id: 6, label: 'Cells', value: 1, color: '#2A468A', popupContent: <SeeOtherChart href={"/?module=Trauma+People&item=Cells"} text='Trauma People Style' /> },
                                { id: 7, label: 'Bones', value: 1, color: '#512DA8', popupContent: <SeeOtherChart href={"/?module=Trauma+People&item=Bones"} text='Trauma People Style' /> },
                                { id: 8, label: 'Blood', value: 1, color: '#673AB7', popupContent: <SeeOtherChart href={"/?module=Trauma+People&item=Blood"} text='Trauma People Style' /> },
                                { id: 9, label: 'DNA', value: 1, color: '#795548' },
                                { id: 10, label: 'Other', value: 1, color: '#8D6E63', popupContent: <NoInfoPopUp /> }
                            ],
                            level: 3,
                            parent: 'Origin',
                            disableClick: false,
                            subPages: {
                                "DNA": {
                                    chart: [
                                        { id: 0, label: 'Master Cell', value: 1, color: '#D32F2F', popupContent: <WordsPopUp words={['Cleanse', 'Purify']} /> },
                                        { id: 1, label: 'Divinity Glands', value: 1, color: '#E97A23', popupContent: <WordsPopUp words={['Pineal', 'Pituitary', 'Thymus']} /> },
                                        { id: 2, label: 'DNA', value: 1, color: '#F0B731', popupContent: <WordsPopUp words={['Genes', 'Aspects - mental, physical, Spiritual, Emotional', 'Pairs/Helixes - Increase, Reactivate', 'Codons - Increase', 'LEFs - Increase or activate']} /> },
                                        { id: 3, label: 'Solar Chakras', value: 1, color: '#8BC34A', popupContent: <WordsPopUp words={['Connect', 'Update']} /> },
                                        { id: 4, label: 'Activations', value: 1, color: '#4CAF50', popupContent: <WordsPopUp words={['Increase level/s']} /> },
                                        { id: 5, label: 'Other', value: 1, color: '#0097A7', popupContent: <NoInfoPopUp /> }
                                    ],
                                    level: 4,
                                    parent: 'Area Affected',
                                    disableClick: false,
                                    subPages: {}
                                }
                            }
                        },

                        'Vaccine Ingredients': {
                            chart: [
                                { id: 0, label: 'Chemicals', value: 1, color: '#D32F2F', popupContent: <NoInfoPopUp /> },
                                { id: 1, label: 'Foreign DNA', value: 1, color: '#E97A23', popupContent: <NoInfoPopUp /> },
                                { id: 2, label: 'Live Virus', value: 1, color: '#F0B731', popupContent: <NoInfoPopUp /> },
                                { id: 3, label: 'Glutamate', value: 1, color: '#8BC34A', popupContent: <NoInfoPopUp /> },
                                { id: 4, label: 'Aluminum', value: 1, color: '#4CAF50', popupContent: <NoInfoPopUp /> },
                                { id: 5, label: 'Mercury', value: 1, color: '#0097A7', popupContent: <NoInfoPopUp /> },
                                { id: 6, label: 'Bacteria', value: 1, color: '#2A468A', popupContent: <NoInfoPopUp /> },
                                { id: 7, label: 'Fetal Cells', value: 1, color: '#512DA8', popupContent: <NoInfoPopUp /> },
                                { id: 8, label: 'DNA Disruptors', value: 1, color: '#673AB7', popupContent: <NoInfoPopUp /> },
                                { id: 9, label: 'Other', value: 1, color: '#9C27B0', popupContent: <NoInfoPopUp /> }
                            ],
                            level: 3,
                            parent: 'Origin',
                            disableClick: false,
                            subPages: {}
                        },
                        'System Affected': {
                            chart: [
                                { id: 0, label: 'Integumentary', value: 1, color: '#D32F2F', popupContent: <NoInfoPopUp /> },
                                { id: 1, label: 'Reproductive', value: 1, color: '#E97A23', popupContent: <NoInfoPopUp /> },
                                { id: 2, label: 'Endocrine', value: 1, color: '#F0B731', popupContent: <NoInfoPopUp /> },
                                { id: 3, label: 'Digestive', value: 1, color: '#8BC34A', popupContent: <NoInfoPopUp /> },
                                { id: 4, label: 'Nervous', value: 1, color: '#4CAF50', popupContent: <NoInfoPopUp /> },
                                { id: 5, label: 'Immune', value: 1, color: '#0097A7', popupContent: <NoInfoPopUp /> },
                                { id: 6, label: 'Skeletal', value: 1, color: '#2A468A', popupContent: <NoInfoPopUp /> },
                                { id: 7, label: 'Muscular', value: 1, color: '#512DA8', popupContent: <NoInfoPopUp /> },
                                { id: 8, label: 'Urinary', value: 1, color: '#673AB7', popupContent: <NoInfoPopUp /> },
                                { id: 9, label: 'Lymphatic', value: 1, color: '#9C27B0', popupContent: <NoInfoPopUp /> },
                                { id: 10, label: 'Respiratory', value: 1, color: '#795548', popupContent: <NoInfoPopUp /> },
                                { id: 11, label: 'Cardiovascular', value: 1, color: '#8D6E63', popupContent: <NoInfoPopUp /> }
                            ],
                            level: 3,
                            parent: 'Origin',
                            disableClick: false,
                            subPages: {}
                        },
                    }
                },

                "Chromosome": {
                    chart: [
                        { id: 0, label: 'Numerical', value: 1, color: '#D32F2F' },
                        {
                            id: 1, label: 'Structural', value: 1, color: '#E97A23',
                        },
                        {
                            id: 2, label: 'DNA', value: 1, color: '#F0B731',
                        },
                        { id: 3, label: 'RNA', value: 1, color: '#8BC34A', popupContent: <WordsPopUp words={['Messenger', 'Ribosome', 'Transfer of genetic code']} /> },
                        { id: 4, label: 'Telomeres', value: 1, color: '#4CAF50', popupContent: <NoInfoPopUp /> },
                        { id: 5, label: 'Pairs', value: 1, color: '#0097A7'},
                        { id: 6, label: 'Parts', value: 1, color: '#2A468A', popupContent: <WordsPopUp words={['Chromatid', 'Centromere', 'Short arm', 'Long arm']} /> },
                        { id: 7, label: 'Abnormalities', value: 1, color: '#512DA8', popupContent: <WordsPopUp words={['Mitosis', 'Meiosis', 'Mosaicism']} /> },
                        { id: 8, label: 'Nucleotides', value: 1, color: '#673AB7', popupContent: <NoInfoPopUp /> }
                    ],
                    level: 2,
                    parent: 'Origin',
                    disableClick: false,
                    subPages: {
                        "Numerical": {
                            chart: [
                                { id: 0, label: '1st Pair', value: 1, color: '#D32F2F', popupContent: <NoInfoPopUp /> },
                                { id: 1, label: '2nd Pair', value: 1, color: '#E97A23', popupContent: <NoInfoPopUp /> },
                                { id: 2, label: '3rd Pair', value: 1, color: '#F0B731', popupContent: <NoInfoPopUp /> },
                                { id: 3, label: '4th Pair', value: 1, color: '#8BC34A', popupContent: <NoInfoPopUp /> },
                                { id: 4, label: '5th Pair', value: 1, color: '#4CAF50', popupContent: <NoInfoPopUp /> },
                                { id: 5, label: '6th Pair', value: 1, color: '#009688', popupContent: <NoInfoPopUp /> },
                                { id: 6, label: '7th Pair', value: 1, color: '#2A468A', popupContent: <NoInfoPopUp /> },
                                { id: 7, label: '8th Pair', value: 1, color: '#512DA8', popupContent: <NoInfoPopUp /> },
                                { id: 8, label: '9th Pair', value: 1, color: '#673AB7', popupContent: <NoInfoPopUp /> },
                                { id: 9, label: '10th Pair', value: 1, color: '#795548', popupContent: <NoInfoPopUp /> },
                                { id: 10, label: '11th Pair', value: 1, color: '#8D6E63', popupContent: <NoInfoPopUp /> },
                                { id: 11, label: '12th Pair', value: 1, color: '#FF5252', popupContent: <NoInfoPopUp /> },
                                { id: 12, label: '13th Pair', value: 1, color: '#FFA000', popupContent: <NoInfoPopUp /> },
                                { id: 13, label: '14th Pair', value: 1, color: '#FFEB3B', popupContent: <NoInfoPopUp /> },
                                { id: 14, label: '15th Pair', value: 1, color: '#C0CA33', popupContent: <NoInfoPopUp /> },
                                { id: 15, label: '16th Pair', value: 1, color: '#7CB342', popupContent: <NoInfoPopUp /> },
                                { id: 16, label: '17th Pair', value: 1, color: '#0097A7', popupContent: <NoInfoPopUp /> },
                                { id: 17, label: '18th Pair', value: 1, color: '#1976D2', popupContent: <NoInfoPopUp /> },
                                { id: 18, label: '19th Pair', value: 1, color: '#303F9F', popupContent: <NoInfoPopUp /> },
                                { id: 19, label: '20th Pair', value: 1, color: '#512DA8', popupContent: <NoInfoPopUp /> },
                                { id: 20, label: '21st Pair', value: 1, color: '#9C27B0', popupContent: <NoInfoPopUp /> },
                                { id: 21, label: '22nd Pair', value: 1, color: '#D81B60', popupContent: <NoInfoPopUp /> },
                                { id: 22, label: '23rd Pair', value: 1, color: '#E64A19', popupContent: <NoInfoPopUp /> },
                                { id: 23, label: 'XTRA Chromosome', value: 1, color: '#FFC107', popupContent: <NoInfoPopUp /> },
                            ],
                            level: 2,
                            parent: 'Chromosome',
                            disableClick: false,
                            subPages: {}
                        },
                        "Pairs":{
                            chart: [
                                { id: 0, label: '1st Pair', value: 1, color: '#D32F2F', popupContent: <NoInfoPopUp /> },
                                { id: 1, label: '2nd Pair', value: 1, color: '#E97A23', popupContent: <NoInfoPopUp /> },
                                { id: 2, label: '3rd Pair', value: 1, color: '#F0B731', popupContent: <NoInfoPopUp /> },
                                { id: 3, label: '4th Pair', value: 1, color: '#8BC34A', popupContent: <NoInfoPopUp /> },
                                { id: 4, label: '5th Pair', value: 1, color: '#4CAF50', popupContent: <NoInfoPopUp /> },
                                { id: 5, label: '6th Pair', value: 1, color: '#009688', popupContent: <NoInfoPopUp /> },
                                { id: 6, label: '7th Pair', value: 1, color: '#2A468A', popupContent: <NoInfoPopUp /> },
                                { id: 7, label: '8th Pair', value: 1, color: '#512DA8', popupContent: <NoInfoPopUp /> },
                                { id: 8, label: '9th Pair', value: 1, color: '#673AB7', popupContent: <NoInfoPopUp /> },
                                { id: 9, label: '10th Pair', value: 1, color: '#795548', popupContent: <NoInfoPopUp /> },
                                { id: 10, label: '11th Pair', value: 1, color: '#8D6E63', popupContent: <NoInfoPopUp /> },
                                { id: 11, label: '12th Pair', value: 1, color: '#FF5252', popupContent: <NoInfoPopUp /> },
                                { id: 12, label: '13th Pair', value: 1, color: '#FFA000', popupContent: <NoInfoPopUp /> },
                                { id: 13, label: '14th Pair', value: 1, color: '#FFEB3B', popupContent: <NoInfoPopUp /> },
                                { id: 14, label: '15th Pair', value: 1, color: '#C0CA33', popupContent: <NoInfoPopUp /> },
                                { id: 15, label: '16th Pair', value: 1, color: '#7CB342', popupContent: <NoInfoPopUp /> },
                                { id: 16, label: '17th Pair', value: 1, color: '#0097A7', popupContent: <NoInfoPopUp /> },
                                { id: 17, label: '18th Pair', value: 1, color: '#1976D2', popupContent: <NoInfoPopUp /> },
                                { id: 18, label: '19th Pair', value: 1, color: '#303F9F', popupContent: <NoInfoPopUp /> },
                                { id: 19, label: '20th Pair', value: 1, color: '#512DA8', popupContent: <NoInfoPopUp /> },
                                { id: 20, label: '21st Pair', value: 1, color: '#9C27B0', popupContent: <NoInfoPopUp /> },
                                { id: 21, label: '22nd Pair', value: 1, color: '#D81B60', popupContent: <NoInfoPopUp /> },
                                { id: 22, label: '23rd Pair', value: 1, color: '#E64A19', popupContent: <NoInfoPopUp /> },
                                { id: 23, label: 'XTRA Chromosome', value: 1, color: '#FFC107', popupContent: <NoInfoPopUp /> },
                            ],
                            level: 2,
                            parent: 'Chromosome',
                            disableClick: false,
                            subPages: {}
                        },
                    }
                },

                "Galactic": {
                    chart: [
                        { id: 0, label: 'Red', value: 1, color: '#C31F26', popupContent: <WordsPopUp words={['Is there another configuration needed?']} /> },
                        { id: 1, label: 'Orange', value: 1, color: '#FFAE42', popupContent: <WordsPopUp words={['Is there another configuration needed?']} /> },
                        { id: 2, label: 'Yellow', value: 1, color: '#FFE044', popupContent: <WordsPopUp words={['Is there another configuration needed?']} /> },
                        { id: 6, label: 'Green', value: 1, color: '#007B3B', popupContent: <WordsPopUp words={['Is there another configuration needed?']} /> },
                        { id: 9, label: 'Blue', value: 1, color: '#0B349B', popupContent: <WordsPopUp words={['Is there another configuration needed?']} /> },
                        { id: 12, label: 'Purple', value: 1, color: '#5C00A3', popupContent: <WordsPopUp words={['Is there another configuration needed?']} /> },
                        { id: 16, label: 'White', value: 1, color: '#f0f0f0', popupContent: <WordsPopUp words={['Is there another configuration needed?']} /> },
                        { id: 17, label: 'Black', value: 1, color: '#000000', popupContent: <WordsPopUp words={['Is there another configuration needed?']} /> },
                        { id: 18, label: 'Indigo', value: 1, color: '#4B0082', popupContent: <WordsPopUp words={['Is there another configuration needed?']} /> },
                        { id: 19, label: 'Turquoise', value: 1, color: '#40E0D0', popupContent: <WordsPopUp words={['Is there another configuration needed?']} /> },
                        { id: 22, label: 'Rainbow', value: 1, color: 'url(#rainbowGradient)', popupContent: <WordsPopUp words={['Is there another configuration needed?']} /> },
                        { id: 21, label: 'Bronze', value: 1, color: '#CD7F32', popupContent: <WordsPopUp words={['Is there another configuration needed?']} /> },
                        { id: 20, label: 'Gold', value: 1, color: '#FFD700', popupContent: <WordsPopUp words={['Is there another configuration needed?']} /> }
                    ],
                    level: 1,
                    parent: 'Protocol',
                    disableClick: false,
                    subPages: {}, 
                    textChartInstructions : <PointsOfLightText />
                },
                "Planetary": {
                    chart: [
                        { id: 0, label: 'Venus', value: 1, color: '#C31F26', popupContent: <WordsPopUp words={['Sense of balance (vesticular balance) homeostasis, sex hormones/feedback mechanisms']} /> },
                        { id: 1, label: 'Mars', value: 1, color: '#E97A23', popupContent: <WordsPopUp words={['Muscle systems and movement, coordination through space (proprioceptive sense), impulse control, immune system, inflammatory response, adrenal function, arousal']} /> },
                        { id: 2, label: 'Jupiter', value: 1, color: '#F0B731', popupContent: <WordsPopUp words={['Cerebrum (left hemisphere), integrative brain function, abstract and motor reasoning (hence cognitive and motor disorders), belief/ideological system, arterial system, liver, hypothalamus']} /> },
                        { id: 3, label: 'Saturn', value: 1, color: '#94C739', popupContent: <WordsPopUp words={['Skin (tactile sense, with mercury), skeletal system, teeth, auditory sense (bony aspect and regulation), vestibular sense (gravity/regulation), developmental timelines']} /> },
                        { id: 4, label: 'Uranus', value: 1, color: '#4FAD45', popupContent: <WordsPopUp words={['Rhythmic pulsation, bodily systems coordination, electrical system, corpus callosum/cross-hemispheric thinking']} /> },
                        { id: 5, label: 'Neptune', value: 1, color: '#209692', popupContent: <WordsPopUp words={['Intuition, mental visualization and illusion, right-brained processing, permeability, allergies/autoimmune and fungal diseases, addiction, spiritual/psychic insight']} /> },
                        { id: 6, label: 'Pluto', value: 1, color: '#2A468A', popupContent: <WordsPopUp words={['Waste/elimination system, toxicity, primitive "reptilian" brain/amygdala, obsessive-compulsive disorder, panic attacks, "flight or fight" response']} /> },
                        { id: 7, label: 'Ascendant', value: 1, color: '#282460', popupContent: <WordsPopUp words={['Interaction with environment, "electric" inflow of vital energy']} /> },
                        { id: 8, label: 'Mid Heaven', value: 1, color: '#642F90', popupContent: <WordsPopUp words={['Highest aspirations, motor nerve centers, brain cortex (our crown), consciousness of self']} /> },
                        { id: 9, label: 'Sun', value: 1, color: '#9F2062', popupContent: <WordsPopUp words={['Being (sense of self), consciousness, vitality (body heat), vision (integrative function), heart function, cell nuclei (DNA, hence genetic conditions and congenital disorders), right eye of man/left eye of woman']} /> },
                        { id: 10, label: 'Moon', value: 1, color: '#8D6E63', popupContent: <WordsPopUp words={['Bodily sensations and cycles, emotional arousal, fluids and hormones, limbic system (including hypothalamus and hippocampus), fertility/reproduction, circadian rhythms, instinctive reflexes, stomach/small intestine, right eye of woman/left eye of man']} /> }
                    ],

                    level: 1,
                    parent: 'Protocol',
                    disableClick: false,
                    subPages: {}
                },
            },
        },
        'Energetic Seed': {
            chart: [
                { id: 0, label: 'Heart Opening', value: 1, color: '#C31F26' },
                { id: 2, label: 'Brain Receptor', value: 1, color: '#F0B731' },
                { id: 3, label: 'Life Balance', value: 1, color: '#94C739' }
            ],
            level: 0,
            disableClick: false,
            subPages: {}
        },


        "Trauma": {
            chart: [
                { label: 'Stress Chart', value: 1, id: 0, color: '#D52329' },
                { label: 'Clean-Up Sessions', value: 1, id: 4, color: '#FF7B27' },
                { label: 'Land Rejuvenations', value: 1, id: 5, color: '#FFAE2D' },
                { label: 'Brain Balancing', value: 1, id: 1, color: '#4BAD43' },
                { label: 'Nature Balancing', value: 1, id: 2, color: '#008E9C' },
                { label: 'Life Statements', value: 1, id: 3, color: '#135CAB' },
                { label: 'Connections', value: 1, id: 6, color: '#000080' },
                { label: 'Addiction', value: 1, id: 7, color: '#502795' }
            ],
            level: 0,
            disableClick: false,
            subPages: {

                "Addiction": {
                    chart: [
                        { id: 0, label: "Head Heart", labelWithFormatting: 'Head Heart\nHow we think about life', value: 1, color: '#C31F26', popupContent: <AddictionPopUp /> },
                        { id: 1, label: "Heart Heart", labelWithFormatting: 'Heart Heart\nHow we feel about life', value: 1, color: '#E97A23', popupContent: <AddictionPopUp /> },
                        { id: 2, label: "Gut Heart", labelWithFormatting: 'Gut Heart\nHow we process life', value: 1, color: '#F0B731', popupContent: <AddictionPopUp /> },
                        { id: 3, label: "Foot Heart", labelWithFormatting: 'Foot Heart\nHow connected \nwe feel in life', value: 1, color: '#94C739', popupContent: <AddictionPopUp /> },
                        { id: 4, label: "Balance all hearts", labelWithFormatting: 'Balance All Hearts', value: 1, color: '#4FAD45', popupContent: <AddictionPopUp /> }
                    ],
                    parent: "Trauma",
                    level: 2,
                    subPages: {},
                    disableClick: false,
                },

                "Connections": {
                    chart: [
                        { id: 0, label: 'Ancestors Who Are Deceased', labelWithFormatting: 'Ancestors Who \n Are Deceased', value: 1, color: '#C31F26', popupContent: <WordsPopUp words={["Do I need to know who this is?", "Mental energy pattern, Mental Pattern, Unresolved life issues, Unresolved relationship issues"]} /> },
                        { id: 1, label: 'Past Animal Companions Who Are Deceased', labelWithFormatting: "Past Animal Companions \n Who Are Deceased", value: 1, color: '#E97A23', popupContent: <WordsPopUp words={["Do I need to know who this is?", "Mental energy pattern, Mental Pattern, Unresolved life issues, Unresolved relationship issues"]} /> },
                        { id: 2, label: 'Unresolved Issues with Deceased', labelWithFormatting: "Unresolved Issues \n with Deceased", value: 1, color: '#F0B731', popupContent: <WordsPopUp words={["Who is this person?", "Is there a forgiveness issue?", "Need to forgive or be forgiven?"]} /> },
                        { id: 3, label: 'Relationship with Dark vs Light', labelWithFormatting: "Relationship with \n Dark vs Light", value: 1, color: '#94C739', popupContent: <WordsPopUp words={["Light", "Dark", "Both"]} /> },
                    ],
                    parent: "Trauma",
                    level: 2,
                    subPages: {},
                    disableClick: false,
                },


                "Life Statements": {
                    chart: [
                        { id: 0, label: 'Not Being Seen', value: 1, color: '#C31F26', popupContent: <NavToPeopleOrAnimal /> },
                        { id: 1, label: 'Not Being Heard', value: 1, color: '#E97A23', popupContent: <NavToPeopleOrAnimal /> },
                        { id: 2, label: 'Not Being Understood', value: 1, color: '#F0B731', popupContent: <NavToPeopleOrAnimal /> },
                        { id: 3, label: 'Not Being Loved', value: 1, color: '#94C739', popupContent: <NavToPeopleOrAnimal /> },
                        { id: 4, label: 'Not Being Respected/Honored', value: 1, color: '#4FAD45', popupContent: <NavToPeopleOrAnimal />, labelWithFormatting: 'Not Being  \nRespected/Honored' },
                        { id: 5, label: 'Not Being Worthy', value: 1, color: '#209692', popupContent: <NavToPeopleOrAnimal /> },
                        { id: 6, label: 'Not Being Believed', value: 1, color: '#2A468A', popupContent: <NavToPeopleOrAnimal /> },
                        { id: 7, label: 'Not Feeling Safe', value: 1, color: '#263A8E', popupContent: <NavToPeopleOrAnimal /> },
                        { id: 8, label: 'Not Feeling Valued', value: 1, color: '#5E409A', popupContent: <NavToPeopleOrAnimal /> },
                        { id: 9, label: 'Not Feeling Witnessed', value: 1, color: '#774192', popupContent: <NavToPeopleOrAnimal /> }
                    ],
                    parent: "Trauma",
                    level: 2,
                    subPages: {},
                    disableClick: false,
                },



                "Stress Chart": {
                    chart: [
                        { label: 'Environmental', value: 1, id: 0, color: '#D52329', popupContent: <NoInfoPopUp /> },
                        { label: 'Chemical', value: 1, id: 4, color: '#F4C8C9', popupContent: <NoInfoPopUp /> },
                        { label: 'Emotional', value: 1, id: 5, color: '#D52329', popupContent: <NoInfoPopUp /> },
                        { label: 'Spiritual', value: 1, id: 1, color: '#F4C8C9', popupContent: <NoInfoPopUp /> },
                        { label: 'Maternal Age', value: 1, id: 2, color: '#D52329', popupContent: <NoInfoPopUp /> },
                        { label: 'Hereditary', value: 1, id: 3, color: '#F4C8C9', popupContent: <WordsPopUp words={["Mother", "Father", "Both"]} /> },
                        { label: 'De Novo', value: 1, id: 6, color: '#D52329', popupContent: <NoInfoPopUp /> },
                    ],
                    parent: "Trauma",
                    level: 2,
                    subPages: {}
                },
                "Clean-Up Sessions": {
                    chart: [
                        { label: 'Part 1', value: 1, id: 0, color: '#FF7B27' },
                        { label: 'Part 2', value: 1, id: 4, color: '#fcceb1' },
                    ],
                    parent: "Trauma",
                    level: 2,
                    textChartInstructions: <CleanUpSessionsText />,
                    subPages: {}
                },
                "Land Rejuvenations": {
                    chart: [
                        { label: 'Land Rejuvenation', value: 1, id: 0, color: '#FFAE2D' },
                        { label: 'Body Rejuvenation', value: 1, id: 1, color: '#FCDFB1' },
                        { label: 'Lay of the land', value: 1, id: 2, color: '#FFAE2D' },
                        { label: 'Partial Land Clearing', value: 1, id: 3, color: '#FCDFB1' },
                    ],
                    parent: "Trauma",
                    level: 2,
                    subPages: {
                        "Land Rejuvenation": {
                            chart: [
                            ],
                            parent: "Land Rejuvenations",
                            level: 3,
                            subPages: {},
                            customInstructionsPage: <LandRejuvenationText />
                        },
                        "Body Rejuvenation": {
                            chart: [
                            ],
                            parent: "Land Rejuvenations",
                            level: 3,
                            subPages: {},
                            customInstructionsPage: <BodyRejuvenationText />
                        },
                        "Lay of the land": {
                            chart: [
                            ],
                            parent: "Land Rejuvenations",
                            level: 3,
                            subPages: {},
                            customInstructionsPage: <LayOfTheLandText />
                        }

                    }

                }


            }
        },


        "Autoimmune": {
            chart: [
                { label: '12th Strand DNA', value: 1, id: 0, color: '#D52329' },
                { label: 'Hormonal', value: 1, id: 4, color: '#FF7B27' },
                { label: 'SRS', value: 1, id: 5, color: '#FFAE2D', popupContent: <WordsPopUp words={['Stress Response System']} /> },
                { label: 'ECS', value: 1, id: 1, color: '#4BAD43' },
                { label: 'Nanobots', value: 1, id: 2, color: '#008E9C' },
                { label: 'Detox', value: 1, id: 3, color: '#135CAB' },
                { label: 'Acupuncture', value: 1, id: 6, color: '#502795' }
            ],
            level: 0,
            disableClick: false,
            subPages: {
                "SRS": {
                    chart: [

                        { id: 0, label: '1', value: 1, color: '#EFB211', popupContent: <NoInfoPopUp /> },
                        { id: 1, label: '2', value: 1, color: '#FAEABF', popupContent: <NoInfoPopUp /> },
                        { id: 2, label: '3', value: 1, color: '#EFB211', popupContent: <NoInfoPopUp /> },
                        { id: 3, label: '4', value: 1, color: '#FAEABF', popupContent: <NoInfoPopUp /> },
                        { id: 4, label: '5', value: 1, color: '#EFB211', popupContent: <NoInfoPopUp /> },
                        { id: 5, label: '6', value: 1, color: '#FAEABF', popupContent: <NoInfoPopUp /> },
                        { id: 6, label: '7', value: 1, color: '#EFB211', popupContent: <NoInfoPopUp /> },
                        { id: 7, label: '8', value: 1, color: '#FAEABF', popupContent: <NoInfoPopUp /> },
                        { id: 8, label: '9', value: 1, color: '#EFB211', popupContent: <NoInfoPopUp /> },
                        { id: 9, label: 'More Than 9', value: 1, color: '#FAEABF', popupContent: <NoInfoPopUp /> },
                    ],
                    parent: "Spirit",
                    level: 2,
                    subPages: {},
                    textChartInstructions: <SRSChartText />
                },

                "Nanobots": {
                    chart: [
                        { id: 0, label: 'Control Bots', value: 1, color: '#008E9C', popupContent: <NanobotsClearingPopUp botCategory='ControlBots' /> },
                        { id: 1, label: 'Infusion Bots', value: 1, color: '#8ad6de', popupContent: <NanobotsClearingPopUp botCategory='Infusion Bots' /> },
                        { id: 2, label: 'Dispensary Bots', value: 1, color: '#008E9C', popupContent: <NanobotsClearingPopUp botCategory='Dispensary Bots' /> },
                        { id: 3, label: 'Disruption Bots', value: 1, color: '#8ad6de', popupContent: <NanobotsClearingPopUp botCategory='Disruption Bots' /> },
                        { id: 4, label: 'Responding Bots', value: 1, color: '#008E9C', popupContent: <NanobotsClearingPopUp botCategory='Responding Bots' /> },
                        { id: 5, label: 'Specific Task Bots', value: 1, color: '#8ad6de', popupContent: <NanobotsClearingPopUp botCategory='Specific Task Bots' /> },
                        { id: 6, label: 'Transmission Bots', value: 1, color: '#008E9C', popupContent: <NanobotsClearingPopUp botCategory='Transmission Bots' /> },
                        { id: 7, label: 'Communication Bots', value: 1, color: '#8ad6de', popupContent: <NanobotsClearingPopUp botCategory='Communication Bots' /> },
                        { id: 8, label: 'Exacerbate Weakness Bots', value: 1, color: '#008E9C', popupContent: <NanobotsClearingPopUp botCategory='Exacerbate Weakness Bots' /> },
                        { id: 9, label: 'Tracking Bots', value: 1, color: '#8ad6de', popupContent: <NanobotsClearingPopUp botCategory='Tracking Bots' /> },
                        { id: 10, label: 'Energetic Bots', value: 1, color: '#008E9C', popupContent: <NanobotsClearingPopUp botCategory='Energetic Bots' /> },
                    ],
                    parent: "Autoimmune",
                    level: 2,
                    subPages: {}
                },

                "Detox": {
                    chart: [
                        { id: 0, label: 'Colon', value: 1, color: '#135CAB', popupContent: NutritionPopUps.Nutrition.Detoxification.Colon },
                        { id: 1, label: 'Liver', value: 1, color: '#C0D3E8', popupContent: NutritionPopUps.Nutrition.Detoxification.Liver },
                        { id: 2, label: 'Gallbladder', value: 1, color: '#135CAB', popupContent: NutritionPopUps.Nutrition.Detoxification.Gallbladder },
                        { id: 3, label: 'Kidney', value: 1, color: '#C0D3E8', popupContent: NutritionPopUps.Nutrition.Detoxification.Kidney },
                        { id: 4, label: 'Lung', value: 1, color: '#135CAB', popupContent: NutritionPopUps.Nutrition.Detoxification.Lung },
                        { id: 5, label: 'Skin', value: 1, color: '#C0D3E8', popupContent: NutritionPopUps.Nutrition.Detoxification.Skin },
                        { id: 6, label: 'Lymphatic', value: 1, color: '#135CAB', popupContent: NutritionPopUps.Nutrition.Detoxification.Lymphatic },
                        { id: 7, label: 'Parasite', value: 1, color: '#C0D3E8', popupContent: NutritionPopUps.Nutrition.Detoxification.Parasite },
                        { id: 8, label: 'Candida', value: 1, color: '#135CAB', popupContent: NutritionPopUps.Nutrition.Detoxification.Candida },
                        { id: 9, label: 'Metal', value: 1, color: '#C0D3E8', popupContent: NutritionPopUps.Nutrition.Detoxification.Metal },
                        { id: 10, label: 'Master', value: 1, color: '#135CAB', popupContent: NutritionPopUps.Nutrition.Detoxification.Master },
                        { id: 11, label: 'Juicing', value: 1, color: '#C0D3E8', popupContent: NutritionPopUps.Nutrition.Detoxification.Juicing },
                        { id: 12, label: 'Raw/Alkaline', value: 1, color: '#135CAB', popupContent: NutritionPopUps.Nutrition.Detoxification.Raw },
                        { id: 13, label: 'Emotional/Spiritual', value: 1, color: '#C0D3E8', popupContent: NutritionPopUps.Nutrition.Detoxification.Emotional },
                        { id: 14, label: 'Homeopathic', value: 1, color: '#135CAB', popupContent: NutritionPopUps.Nutrition.Detoxification.Homeopathic },
                        { id: 15, label: 'Intermittent', value: 1, color: '#C0D3E8', popupContent: NutritionPopUps.Nutrition.Detoxification.Intermittent },
                        { id: 16, label: 'Sugar', value: 1, color: '#135CAB', popupContent: NutritionPopUps.Nutrition.Detoxification.Sugar },
                        { id: 17, label: 'Bentonite Clay', value: 1, color: '#C0D3E8', popupContent: NutritionPopUps.Nutrition.Detoxification.Bentonite },
                        { id: 18, label: 'Detox Gold', value: 1, color: '#135CAB', popupContent: NutritionPopUps.Nutrition.Detoxification.Gold },
                        { id: 19, label: 'Other', value: 1, color: '#C0D3E8', popupContent: NutritionPopUps.Nutrition.Detoxification.Other },

                    ],
                    parent: "Autoimmune",
                    level: 2,
                    subPages: {}
                },

                "Acupuncture": {
                    chart: [
                        { id: 0, label: 'People', value: 1, color: '#502795' },
                        { id: 1, label: 'Animal', value: 1, color: '#d3bbfc' },

                    ],
                    parent: "Autoimmune",
                    level: 2,
                    subPages: {
                        "People": {
                            chart: [
                                { id: 0, label: 'Spleen (Pancreas)', value: 1, color: '#502795' },
                                { id: 1, label: 'Heart Governor', value: 1, color: '#d3bbfc', popupContent: TraumaPeoplePopUps.TraumaPeople.Meridians.HeartGovernor },
                                { id: 2, label: 'Triple Heater', value: 1, color: '#502795' },
                                { id: 3, label: 'Stomach', value: 1, color: '#d3bbfc' },
                                { id: 4, label: 'Bladder', value: 1, color: '#502795' },
                                { id: 5, label: 'Liver', value: 1, color: '#d3bbfc' },
                                { id: 6, label: 'Lung', value: 1, color: '#502795' },
                                { id: 7, label: 'Heart', value: 1, color: '#d3bbfc' },
                                { id: 8, label: 'Kidney', value: 1, color: '#502795' },
                                { id: 9, label: 'Gallbladder', value: 1, color: '#d3bbfc' },
                                { id: 10, label: 'Pericardium', value: 1, color: '#502795' },
                                { id: 11, label: 'Large Intestine', value: 1, color: '#d3bbfc' },
                                { id: 12, label: 'Small Intestine', value: 1, color: '#502795' },
                                { id: 13, label: 'Multiple Meridians', value: 1, color: '#d3bbfc', popupContent: TraumaPeoplePopUps.TraumaPeople.Meridians.Multiple },
                            ],
                            parent: "Acupuncture",
                            level: 2,
                            subPages: {}
                        },
                        "Animal": {
                            chart: [
                                { id: 0, label: 'Spleen (Pancreas)', value: 1, color: '#502795' },
                                { id: 1, label: 'Large Intestine', value: 1, color: '#d3bbfc' },
                                { id: 2, label: 'Stomach', value: 1, color: '#502795' },
                                { id: 3, label: 'Lung', value: 1, color: '#d3bbfc' },
                                { id: 4, label: 'Heart', value: 1, color: '#502795' },
                                { id: 5, label: 'Sm. Intestine', value: 1, color: '#d3bbfc', popupContent: TraumaAnimalPopUps.TraumaAnimal.Meridians.SmIntestine },
                                { id: 6, label: 'Bladder', value: 1, color: '#502795' },
                                { id: 7, label: 'Kidney', value: 1, color: '#d3bbfc' },
                                { id: 8, label: 'Heart Governor', value: 1, color: '#502795', popupContent: TraumaAnimalPopUps.TraumaAnimal.Meridians.HeartGovernor },
                                { id: 9, label: 'Triple Heater', value: 1, color: '#d3bbfc' },
                                { id: 10, label: 'Gall Bladder', value: 1, color: '#502795' },
                                { id: 11, label: 'Liver', value: 1, color: '#d3bbfc' },
                                { id: 12, label: 'Pericardium', value: 1, color: '#502795', popupContent: TraumaAnimalPopUps.TraumaAnimal.Meridians.Paricardium },
                                { id: 13, label: 'Governing Vessel', value: 1, color: '#d3bbfc' },
                                { id: 14, label: 'Multiple Meridians', value: 1, color: '#502795', popupContent: TraumaAnimalPopUps.TraumaAnimal.Meridians.Multiple },
                            ],
                            parent: "Acupuncture",
                            level: 2,
                            subPages: {}
                        },
                    },
                },

            },
        }
    },
    disableClick: false,
};



export const doesProtocolChartExist = (module: string | null, item: string | null, subItem: string | null = null, detail: string | null = null): boolean => {
    if (module) {
        if (item) {
            if (subItem) {
                if (detail) {
                    return detail in ProtocolMapping.subPages[module].subPages[item].subPages[subItem].subPages;
                }
                return subItem in ProtocolMapping.subPages[module].subPages[item].subPages;
            }
            if (item === 'ECS' || item === '12th Strand DNA') {
                return true;
            }
            return item in ProtocolMapping.subPages[module].subPages;
        }
        return module in ProtocolMapping.subPages;
    }
    return true;
};


export const GetProtocolChart = (module: string | null, item: string | null, subitem: string | null, detail: string | null) => {
    if (module) {
        if (item) {
            if (subitem && subitem in ProtocolMapping.subPages[module].subPages[item].subPages) {
                if ((detail) && detail in ProtocolMapping.subPages[module].subPages[item].subPages[subitem].subPages) {
                    return ProtocolMapping.subPages[module].subPages[item].subPages[subitem].subPages[detail] || null;
                }
                return ProtocolMapping.subPages[module].subPages[item].subPages[subitem] || null;
            }
            if (item in ProtocolMapping.subPages[module].subPages) {
                return ProtocolMapping.subPages[module].subPages[item] || null;
            }
        }
        return ProtocolMapping.subPages[module] || null;
    }
    return ProtocolMapping;
};


