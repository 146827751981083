import React from 'react';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import ModuleChart from '../ModuleChart/ModuleChart';
import { ChartPageType } from '../../data/types';
import { SUB_TEXT_TYPOGRAPHY_PROPS } from '../../utils';
import { NoInfoPopUp, WordsPopUp } from '../../data/popup';
import { useLocation } from 'react-router-dom';

interface ECSProps {
    height: number;
    width: number;
}

const MODULE_COLORS = {
    'Trauma Animal': {
      primary: '#46AA42',
      secondary: '#CDE8CC'
    },
    'Trauma People': {
      primary: '#00653A',
      secondary: '#BBD5CA'
    },
};

const getModuleColors = (moduleName: string | null) => {
    const defaultColors = MODULE_COLORS['Trauma Animal']; // fallback
    return MODULE_COLORS[moduleName as keyof typeof MODULE_COLORS] || defaultColors;
  };




const ECSPage: React.FC<ECSProps> = ({ height, width }) => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const module = queryParams.get('module');
    const colors = getModuleColors(module);

    const ComponentsData: ChartPageType = {
        chart: [
            { label: 'EC', value: 2, id: 1, color: colors.primary, popupContent: <WordsPopUp words={["Endocannabinoids"]} /> },
            { label: 'C.R.', value: 2, id: 2, color: colors.secondary,popupContent: <WordsPopUp words={["Cannabinoid Receptors"]} /> },
            { label: 'Enzymes', value: 1, id: 3, color: colors.primary,popupContent: <NoInfoPopUp/>},
    
        ],
        innerChart: [
            { label: 'AEA', value: 1, id: 1, color: colors.primary, popupContent: <WordsPopUp words={["Anandamide"]} /> },
            { label: '2-AG', value: 1, id: 2, color: colors.secondary, popupContent: <WordsPopUp words={["2-Arachidonoylglycerol"]} />  },
            { label: 'CB1 Central', labelWithFormatting:'CB1 \n Central', value: 1, id: 3, color: colors.primary, popupContent:<NoInfoPopUp/>},
            { label: 'CB2 Periph', labelWithFormatting:'CB2 \n Periph',value: 1, id: 4, color: colors.secondary, popupContent: <WordsPopUp words={["CB2 Peripheral"]} /> },
            { label: 'TRP Channels', labelWithFormatting: 'TRP \n Chnls', value: 1, id: 5, color: colors.primary, popupContent: <WordsPopUp words={["Transient receptor potential (TRP) channels"]} />},
        ],
        level: 0,
        subPages: {},
        disableClick: false,
        textSizeOverride: 13,
        textChartInstructions: <Typography {...SUB_TEXT_TYPOGRAPHY_PROPS} >Components</Typography>
    }
    
    const InteractionsData: ChartPageType = {
        chart: [
            { label: 'Neurotransmitters', value: 1, id: 1, color: colors.primary, popupContent: <NoInfoPopUp/> },
            { label: 'Serotonin System', value: 1, id: 2, color: colors.secondary, popupContent: <NoInfoPopUp/> },
            { label: 'Dopamine System', value: 1, id: 3, color: colors.primary, popupContent: <NoInfoPopUp/> },
        ],
        level: 0,
        subPages: {},
        disableClick: false,
        textSizeOverride: 13,
        textChartInstructions: <Typography {...SUB_TEXT_TYPOGRAPHY_PROPS} >Interactions</Typography>
    } 


    const top_chart_heights = height / 2;
    const bottom_chart_heights = top_chart_heights;
    const chart_widths = width;
    return (
        <Grid container direction="column" alignItems="center">
            <Grid size={{xs:6}} >
                    <ModuleChart
                        height={bottom_chart_heights}
                        width={chart_widths}
                        innerChartRadius={"35%"}
                        chartData={ComponentsData}
                        chartModule={module as string}
                        item={"Systems"}
                        subitem="ECS" />
            </Grid>
            <Grid size={{xs:6}}>
                    <ModuleChart
                        height={bottom_chart_heights}
                        width={chart_widths}
                        chartData={InteractionsData}
                        chartRadius={"50%"}
                        chartModule={module as string}
                        item={"Systems"}
                        subitem="ECS" />
            </Grid>
        </Grid>
    );
}
export default ECSPage;
