import { PieValueType } from '@mui/x-charts';

export type ExtendedPieValueType = PieValueType & {
  popupContent?: React.ReactElement
  onClickNav?: string
  labelWithFormatting?: string,
  textColor?: string,
  disableTextRotation?: boolean
};

export type ChartPageType = {
  chart: ExtendedPieValueType[];
  innerChart?: ExtendedPieValueType[];
  innerInnerChart?: ExtendedPieValueType[];
  parent?: string | null;
  level: number;
  subPages: ChartPagesType;
  textChartInstructions?: React.ReactElement;
  rightOrLeftImage?: string;
  disableClick?: boolean;
  textSizeOverride?: number;
  fullOneEightyChart?: boolean;
  customInstructionsPage ?: React.ReactElement;
  innerChartRadius?: number | string;
};

export type ChartPagesType = {
  [key: string]: ChartPageType;
};

export type popupIdMappingType = {
  [module: string]: {
    [item: string]: {
      [subitem: string]: React.ReactElement
    };
  };
};
export type WordListWithTitleType = { title: string, words: string[], links?: string[] };
export type WordListType = string | WordListWithTitleType;

export type WordListMappingType = {
  [module: string]: {
    [item: string]: WordListType[] |
    {
      [subitem: string]: WordListType[] | {
        [detail: string]: WordListType[]
      }
    };
  };
};

export type WordListPageTitle = {
  [module: string]: {
    [item: string]: React.ReactElement | { [subitem: string]: React.ReactElement };
  };
};

export type TextPageMapType = {
  [module: string]: {
    [item: string]: React.ComponentType<any> |
    {
      [subitem: string]: React.ComponentType<any> | {
        [detail: string]: React.ComponentType<any>
      }
    }
  } | React.ComponentType<any>;
};


export type User = {
  username: string,
  email: string,
  name: string,
  full_name: string,
  first_name: string,
  last_name: string
} 

// Add enum for nav items
export enum NavItem {
    PRE_PROTOCOL = 'pre_protocol',
    PROTOCOL = 'protocol',
    ANCESTRY = 'ancestry'
}

// Add type for the mapping
export type NavItemTextMapping = {
    [key in NavItem]: string;
};

// Update the object with type
export const navItemToText: NavItemTextMapping = {
    [NavItem.ANCESTRY]: "Ancestry",
    [NavItem.PRE_PROTOCOL]: "Pre-Protocol",
    [NavItem.PROTOCOL]: "Protocol"
};
