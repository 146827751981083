import { WordListMappingType, WordListType, WordListWithTitleType } from "../../types";


export const ProtocolWordListMap: WordListMappingType = {
    "Autoimmune": {
        "Hormonal": [
            "Corticotrophin",
            "Dopamine",
            "Gonadotropin",
            "Ghrelin",
            "Growth Hormone",
            "Oxytocin",
            "Somatostatin",
            "IGF-1",
            "Angiotensinogen",
            "Thyrotropin",
            "Adrenocorticotropic Hormone (ACTH)",
            "FSH (Follicle-Stimulating Hormone)",
            "LH (Luteinizing Hormone)",
            "Prolactin",
            "TSH (Thyroid-Stimulating Hormone)",
            "ADH (Antidiuretic Hormone)",
            "T4 (Thyroxine)",
            "T3 (Triiodothyronine)",
            "RT3 (Reverse Triiodothyronine)",
            "Calcitonin",
            "PTH (Parathyroid Hormone)",
            "Cortisol",
            "Aldosterone",
            "DHEA (Dehydroepiandrosterone)",
            "Adrenaline (Epinephrine)",
            "Noradrenaline (Norepinephrine)",
            "Insulin",
            "Glucagon",
            "Estrogen",
            "Progesterone",
            "Testosterone",
            "Leptin",
            "Adiponectin",
            "Plasminogen",
            "Angiotensin",
            "Erythropoietin",
            "Renin",
            "D"
        ],
        "Acupuncture": {
            "Animal": {
                "Spleen (Pancreas)": [{
                    title: "Horse", words: [
                        "K10",
                        "K7",
                        "K3"
                    ]
                },
                {
                    title: "Cat", words: [
                        "S17", "S19", "SI11"
                    ]
                },
                {
                    title: "Dog", words: [
                        "SP18", "SP15", "SP9", "SP4"
                    ]
                },
                ],
                "Large Intestine": [{
                    title: "Dog", words: ["LI20", "LI15", "LI14", "LI12", "LI11", "LI10", "LI1"]
                }],
                "Stomach": [{
                    title: "Horse", words: [
                        "S7",
                        "S4",
                        "S13",
                        "S14",
                        "S19",
                        "S25",
                        "S26",
                        "S41",
                        "S36",
                        "S34"
                    ]
                },
                {
                    title: "Dog", words: [
                        "S7", "S4", "S6", "S19", "S25", "S26S41", "S44", "S36", "S19", "S14", "S13", "S34",
                        "LI20", "LI15", "LI14", "LI12", "LI11", "LI10", "LI1",
                        "L15", "L14", "L7", "L5",
                        "L15", "L14", "L7", "L5"
                    ]
                },
                {
                    title: "Cat", words: [
                        "ST4", "ST10", "ST34", "ST36"
                    ]
                }


                ],
                "Lung": [{
                    title: "Cat", words: [
                        "LU6", "LU9",
                        "TW4", "TW10",
                        "P6",
                        "LV3", "SP6", "SP10", "K13",
                        "Erchien", "Zhijian", "Weidaan"]

                }],
                "Heart": [{
                    title: "Dog", words: [
                        "H7",
                        "H3",
                        "P6",
                        "Yang tang",
                        "Umbilicus"
                    ]
                }],
                // "Small Intestine": [{
                //   title: "Horse (Misc.)", words: [

                //   ]
                // }],
                "Bladder": [{
                    title: "Horse", words: [
                        "B10",
                        "B17",
                        "B13",
                        "B11",
                        "B60",
                        "B59",
                        "B40",
                        "B32",
                        "B31",
                        "B25",
                        "B23",
                        "B21",
                        "B20"
                    ]
                },
                {
                    title: "Dog", words: [
                        "B25", "B23", "B21", "B20", "B17", "B13", "B11", "B67", "B60", "B59", "B40", "B32", "B31",
                        "CV24", "CV22", "CV17", "CV12", "CV9", "CV6", "CV7", "CV3", "CV4",
                        "SP18", "SP15", "SP9", "SP10", "SP8", "SP6", "SP3", "SP4",
                        "SI12", "SI9",
                        "Liv8", "Liv3",
                    ]
                },
                {
                    title: "Cat", words: [
                        "BL1", "BL15", "BL18", "BL21", "BL25", "BL32", "BL60"
                    ]
                }


                ],

                "Kidney": [{
                    title: "Dog", words: [
                        "K10", "K7", "K3"
                    ]
                },
                { title: "Cat", words: ["K13"] },
                { title: "Horse", words: ["K10", "K7", "K3"] }

                ],
                // "Heart Governor"
                "Triple Heater": [{
                    title: "Horse", words: [
                        "TW17",
                        "TW21",
                        "TW14",
                        "TW5"
                    ]
                },
                {
                    title: "Dog", words: [
                        "TW21", "TW17", "TW14", "TW5"
                    ]
                }

                ],
                "Gall Bladder": [{
                    title: "Horse", words: [
                        "GB20",
                        "GB14",
                        "GB21",
                        "GB40",
                        "GB34",
                        "GB33",
                        "GB29",
                        "GB30"
                    ]
                },
                {
                    title: "Dog", words: [
                        "GB20", "GB14", "GB40", "GB34", "GB33", "GB29", "GB30"
                    ]
                },
                {
                    title: "Cat", words: [
                        "GB1", "GB21", "GB30", "GB31", "GB34"]
                }
                ],
                "Liver": [{
                    title: "Horse", words: [
                        "LI20",
                        "LI15",
                        "LI14",
                        "LI12",
                        "LI5",
                        "LI4",
                        "LI10",
                        "LI11",
                        "SI12",
                        "H3",
                        "L5",
                        "L7",
                        "Chintietou",
                        "P6",
                        "Yintang",
                        "Haotietou",
                        "K10",
                        "K7",
                        "K3",
                        "LIV8",
                        "LIV3",
                        "SP10",
                        "SP8",
                        "SP6",
                        "SP4SP9",
                        "SP3",
                        "SP15",
                        "CV17",
                        "CV12",
                        "CV9",
                        "CV8",
                        "CV7",
                        "CV6",
                        "CV3",
                        "CV4",
                        "CV22"

                    ]
                },
                {
                    title: "Cat", words: [
                        "LI11", "LI18"]
                },
                {
                    title: "Dog", words: [
                        "L15", "L14", "L7", "L5"]
                }
                ],
                // "Pericardium": [{
                //   title: "Horse", words: [

                //   ]
                // }],


                // "Multiple Meridians": [{
                //   title: "Horse ", words: [

                //   ]
                // }],
                "Governing Vessel": [
                    {
                        title: "Horse", words: [
                            "GV20",
                            "GV23",
                            "GV27",
                            "GV26",
                            "GV24",
                            "GV14",
                            "GV4",
                            "GV8"
                        ]
                    },
                    {
                        title: "Dog", words: [
                            "GV20", "GV23", "GV26", "GV27", "GV4", "GV5 & 6", "GV8", "GV14", "GV1", "GV2"
                        ]
                    },
                    {
                        title: "Cat", words: [
                            "GV1", "GV2", "GV4", "GV8", "GV12", "GV14", "GV25", "GV26",
                            "CV6", "CV12", "CV22"

                        ]
                    }

                ],
            },
            "People": {

                "Spleen (Pancreas)": [

                    "BL20",
                    "LV13",
                    "SP3-source",
                    "SP4",
                    "SP8",
                    "SP1",
                    "SP21",
                    "SP3-Horay",
                    "SP9",
                    "SP8",
                    "SP5",
                    "SP2"
                ],
                // "Heart Governor"
                "Triple Heater": [
                    "BL22",
                    "CV5",
                    "TW4",
                    "TW5",
                    "TW7",
                    "TW1",
                    "TW22",
                    "TW16",
                    "TW3",
                    "TW2",
                    "TW1",
                    "TW6",
                    "TW10"
                ],
                "Stomach": [
                    "BL21",
                    "CV12",
                    "ST42",
                    "ST40",
                    "ST34",
                    "ST1",
                    "ST42",
                    "ST36",
                    "ST41",
                    "ST43",
                    "ST44",
                    "ST45",
                    "ST36"
                ],
                "Bladder": [
                    "BL28",
                    "CV3",
                    "BL64",
                    "BL58",
                    "BL63",
                    "BL1",
                    "BL67",
                    "BL66",
                    "BL65",
                    "BL60",
                    "BL58",
                    "BL40"
                ],
                "Liver": [
                    "BL18",
                    "LV14-front",
                    "LV3",
                    "LV5",
                    "LV6",
                    "LV1-entry",
                    "LV14-exit",
                    "LV1-horary",
                    "LV2",
                    "LV8",
                    "LV4"],
                "Lung": [
                    "BL13",
                    "LU1-front",
                    "LU9",
                    "LU7-luo",
                    "LU6",
                    "LU1-entry",
                    "LU7-exit",
                    "LU8",
                    "LU11",
                    "LU5",
                    "LU10"
                ],
                "Heart": [
                    "BL15",
                    "CV14",
                    "HT7",
                    "HT5",
                    "HT6",
                    "HT1",
                    "HT9",
                    "HT8",
                    "HT4",
                    "HT3"
                ],
                "Kidney": [
                    "BL23",
                    "GB25",
                    "KI3",
                    "KI4",
                    "KI5",
                    "KI1",
                    "KI22",
                    "KI10",
                    "KI2",
                    "KI7",
                    "KI"
                ],
                "Gallbladder": [
                    "BL19",
                    "GB24",
                    "GB40",
                    "GB37",
                    "GB36",
                    "GB1",
                    "GB38",
                    "GB41-exit",
                    "GB41Horary",
                    "GB44",
                    "GB43",
                    "GB34"
                ],
                "Pericardium": [
                    "BL14",
                    "CV17",
                    "P7",
                    "P6",
                    "P4",
                    "P1",
                    "P6",
                    "P8",
                    "P9",
                    "P5",
                    "P3"
                ],
                "Large Intestine": [
                    "BL25",
                    "ST25",
                    "LI4-source",
                    "LI6",
                    "LI7",
                    "LI4-entry",
                    "LI20",
                    "LI1",
                    "LI5",
                    "LI2",
                    "LI3",
                    "LI11"
                ],
                "Small Intestine": [
                    "BL27",
                    "CV4",
                    "SI4",
                    "SI7",
                    "SI6",
                    "SI1",
                    "SI19",
                    "SI5",
                    "SI8",
                    "SI3",
                    "SI2"
                ],
                // "Multiple Meridians"
            },
        }
    },

    'Origin': {
        'Chromosome': {
            'Structural': [
                "Rings",
                "Deletion",
                "Inversion",
                "Duplication",
                "mutation",
                "Translocation",
                "Mutagenesis",
                "Transposition",
                "Reversion",
                "Saltation",
                "Robertsonian translocation",
                "Replication",
                "Transcription",
                "Post translation modification"
            ],
            'DNA' : [
                "Blood DNA",
                "Tissue DNA",
                "Nucleotides",
                "Phosphate group",
                "Pentose sugar",
                {title : "Nitrogenous base", words : [
                "1. Adenine",
                "2. Thymine",
                "3. Cytosine",
                "4. Guanine",
                "5. Uracil"]},
            ]
        },

        'Exotic Implants': [
            "SX mind control",
            "Holographic inserts",
            "Miasmic overlay",
            "Nanites",
            "Artificial machinery",
            "Archondtic deception",
            "NRG grid implants",
            "DL matrixes",
            "Metatronic reversals",
            "Tree of life implants",
            "Black tree of life implants",
            "TOB implants",
            "Negative form implants",
            "Solar to earth implants",
            "Negative ego implants",
            "REP tail implants",
            "Scalar tagging implants",
            "Zeta seal implants",
            "Aging disease implants",
            "Templar seal implants",
            "Metatronic implants",
            "Crucifixion implants",
            "Crown of thorns implants",
            "Glandular system implants",
            "Splitter Tech implants",
            "Needle implants",
            "Matrix/octopus implants",
            "Scalar Archon implants",
            "Dot implants",
            "Bar implants",
            "Spinal cap",
            "Umbrella implants",
            "Head crane",
            "Walnut",
            "Skull jellyfish",
            "Crown chakra implants",
            "Mechanical scorpion",
            "Pineal implants",
            "Amygdala implants",
            "Face cage",
            "Other head implants",
            "Eye implants",
            "Thymus implants",
            "Bra implant",
            "Pancreas rope",
            "Abdominal clamp",
            "Vaginal plug",
            "Prostrate 'G' spot implant",
            "Circle implant",
            "Plate implant",
            "Black dragon",
            "Spiders",
            "Other dark exotic implants"
        ],
        "Vaccines": {
            "Origination": [
                "Chemtrails",
                "Maternal Immune Activation — MIA",
                "Brain Inflammation",
                "Pre-natal Vaccines",
                "Post-natal Vaccines",
                "Low Kidney Chi",
                "Nutrition Imbalance",
                "Poor Emotional State",
                "Disrupted Learning",
                "Brain Area Damage",
                "Medications",
                "Chemicals — Environmental",
                "Metal Exposure",
                "Parasitic Implants",
                "Syncing With the Land",
                "Virus or Bacteria Exposure",
                "Cytokine Interruption & Exposure",
                "Karma",
                "Deep-seated Unworthiness"],

            "Vaccines": [
                "HPV (4,9,16,18 other)",
                "Hep B",
                "Varivax",
                "Cervavix",
                "MMR",
                "DPT",
                "DPTap (Daptacel, Infannix, Kinrix)",
                "DT",
                "HIB",
                "Rubella",
                "Mumps",
                "Measles",
                "Polio",
                "Diphtheria",
                "Tuberculosis",
                "Adnenovirus 4 & 7",
                "Meningococcal",
                "Yellow fever",
                "HEP A",
                "Japanese B Encephalitis",
                "Cholera",
                "Plague",
                "Rabies",
                "Distemper",
                "Bordatella",
                "Lymes",
                "Crotaid",
                "FCV",
                "FHV",
                "FPV",
                "Pertussis",
                "Gardasil",
                "Pneumoccoal",
                "Tetanus",
                "Anthrax",
                "Typhoid",
                "PB",
                "Mefloquine",
                "Smallpox",
                "Influenza",
                "Mefloquine",
                "Asbestos",
                "Opium",
                "Amanity Mushrooms",
                "Leptospirosis",
                "Polyomavirus",
                "Rhinopneumonitis",
                "West Nile Encephalitis",
                "Western Encephalitis",
                "Eastern Encephalitis",
                "Sedative",
                "Agent orange",
                "Cocaine",
                "Methamphetamine",
                "Other"
            ]
        },
    },
};

export const doesWordListExist = (module: string | null, item: string | null, subItem: string | null = null, detail: string | null = null): boolean => {
    if (module && module in ProtocolWordListMap) {
        if (item && item in ProtocolWordListMap[module]) {
            if (subItem) {
                if (detail) {
                    const val = ProtocolWordListMap[module][item];
                    if (val && typeof val === 'object') {
                        const subwords = (val as { [key: string]: WordListType[] | { [key: string]: WordListType[] } })[subItem];
                        if (subwords && typeof subwords === 'object') {
                            const detailwords = (subwords as { [key: string]: WordListType[] | { [key: string]: WordListType[] } });
                            return detail in detailwords
                        }
                    }
                    return false;
                }
                return subItem in ProtocolWordListMap[module][item]
            }
            return item in ProtocolWordListMap[module]
        }
        return module in ProtocolWordListMap;
    }
    return false;
};

const IsWordListType = (obj: any): obj is WordListType => {
    if (Array.isArray(obj)) {
        return obj.every(item => typeof item === 'string' || (item as WordListWithTitleType).title !== undefined);
    }
    return false;
};


export const GetProtocolWordList = (module: string | null, item: string | null, subItem: string | null = null, detail: string | null = null): WordListType[] | null => {
    if (module && module in ProtocolWordListMap) {
        if (item) {
            const val = ProtocolWordListMap[module][item];

            if (IsWordListType(val)) {
                return val as WordListType[];
            }


            if (subItem && val) {
                const subwords = (val as { [key: string]: WordListType[] | { [key: string]: WordListType[] } })[subItem];
                if (IsWordListType(subwords)) {
                    return subwords as WordListType[]
                }


                if (detail && subwords) {
                    const detailwords = (subwords as { [key: string]: WordListType[] | { [key: string]: WordListType[] } });
                    return detailwords[detail] as WordListType[]
                }
                return null;
            }
            return null;

        }
        return null;
    }
    return null;
};



