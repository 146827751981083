import React, { useRef,useState,useEffect,useMemo } from 'react';
import Header from '../components/Header/Header';
import BottomNav from '../components/BottomNav/BottomNav'
import { Box } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useWindowSize } from '@uidotdev/usehooks';
import PreProtocolContent from '../components/PreProtocolContent/PreProtocolContent';
import { NavItem } from '../data/types';
import ProtocolContent from '../components/ProtocolContent/ProtocolContent';
import PrePreProtocolContent from '../components/PrePreProtocolContent/PrePreProtocolContent';


const Protocol: React.FC = () => {
    const [chartSize, setChartSize] = useState<{ height: number, width: number }>({ height: 0, width: 0 })
    const location = useLocation();
    const headerRef = useRef<HTMLDivElement>(null);
    const tagRef = useRef<HTMLDivElement>(null);
    const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
    const currentNavItem = queryParams.get('bottom_nav') || 'protocol';

     const size = useWindowSize();

    useEffect(() => {
        const chartHeight = (size?.height ?? 0) - (headerRef.current?.offsetHeight ?? 0) - (tagRef.current?.offsetHeight ?? 0);
        const chartWidth = size?.width ?? 0;
        setChartSize({ height: chartHeight, width: chartWidth });
    }, [size,queryParams]);


    const GetContent = (navItem : string) => {
        if (navItem === NavItem.PRE_PROTOCOL){
            return (<PreProtocolContent width={chartSize.width} height={chartSize.height}/>);
        }
        if (navItem === NavItem.PROTOCOL)
        {
            return (<ProtocolContent width={chartSize.width} height={chartSize.height} />);
        }
        if (navItem === NavItem.ANCESTRY){
            return (<PrePreProtocolContent width={chartSize.width} height={chartSize.height} />);
        }
        return (<></>);
    }

    return (
        <Box style={{ display: 'flex', flexDirection: 'column', minHeight: '100svh' }}>
                <Box ref={headerRef}>
                    <Header />
                </Box>              
                <Box style={{ position: 'relative', flexGrow: 1 }}>
                    {GetContent(currentNavItem)}
                </Box>
                <Box style={{ position: 'sticky', bottom: 0 }} ref={tagRef}>
                    <BottomNav />
                </Box>
        </Box>
    );
}

export default Protocol;
