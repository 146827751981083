import { Typography } from "@mui/material";
import { Link } from 'react-router-dom';
import { NoInfoPopUp, WordsPopUp } from "../popup"
import { popupIdMappingType } from "../types"
import { AngelsPopups } from "../AngelsPopups"


export const CallAngel = () => {
    return (
        <div>
            <Typography>You do this just with your intention and thoughts. Make sure that you ask specifically for what you need and then start your pendulum swinging in a clockwise manner and let it swing on it's own until it is done. You will then know that the angels/guides/helpers have completed your request. Make sure to thank them for the help. Gratitude goes a long way!</Typography>
            <Typography>Consult &nbsp;
                <Link to="?module=Trauma+Animal&item=Angels">Angels</Link>
            </Typography>
        </div>
    );

}
export const SpiritPopUp = () => {
    return (
        <div>
            <Typography>Someone in spirit has something that they need you to know. If you are not a medium, you can refer to the Trailblazing Communications People Style Module to find out what the message is.</Typography>
            <Typography>Consult &nbsp;
                <Link to="?module=People">People Style</Link>
            </Typography>
        </div>
    );

}
export const AnimalPopUp = () => {
    return (
        <div>
            <Typography>This means that whatever animal comes up with this has something that they need you to know beyond the explanations for the unwanted visitor explanations. If you are not a non-verbal communicator you can refer to Trailblazing communications Animal Style Module to find out what this message may be.</Typography>
            <Typography>Consult &nbsp;
                <Link to="?module=Animal">Animal Style</Link>
            </Typography>
        </div>
    );

}



export const TraumaAnimalPopUps: popupIdMappingType = {
    "TraumaAnimal": {
        "Angels": {
            ...AngelsPopups,
        },
        "Trauma": {
            "ChromosomeAbnormality": <WordsPopUp words={["Chromosome Abnormality"]} />,
            "ElectromagneticSmog": <WordsPopUp words={["Electromagnetic Smog-Stress"]} />,
            "DietDeficiencies": <NoInfoPopUp />,
            "GroupConciousness": <WordsPopUp words={["Group Consciousness"]} />,
            "Emotions": <NoInfoPopUp />,
            "PTSD": <NoInfoPopUp />,
            "PastLive": <NoInfoPopUp />,
            "Genetics": <NoInfoPopUp />,
            "Syncing": <NoInfoPopUp />,
            "Injury": <NoInfoPopUp />,
            "Chemicals": <NoInfoPopUp />,
            "Experiences": <NoInfoPopUp />,
            "Relationships": <NoInfoPopUp />,
            "Surgery": <NoInfoPopUp />,
            "Vaccinations": <NoInfoPopUp />,
            "Cording": <NoInfoPopUp />,
            "GMOFoods": <NoInfoPopUp />,
            //"Programming": <WordsPopUp words={["Bots: See Protocol"]} />,
            "Programming": <NoInfoPopUp />,
            "EnvironmentalToxins": <NoInfoPopUp />,
            "EnvironmentalConditions": <NoInfoPopUp />,
            "SpeciesConsciousness": <WordsPopUp words={["Same species consciousness", "Other species consciousness"]} />,
        },

        "Issues": {
            "RelationalEnvironment": <NoInfoPopUp />,
            "SpiritualEnvironment": <NoInfoPopUp />,
            "RNA": <NoInfoPopUp />,
            "Genetics": <NoInfoPopUp />,
            "CopingSKills": <NoInfoPopUp />,
            "Dietary": <NoInfoPopUp />,
            "SoulUnrest": <NoInfoPopUp />,
            "DNA": <NoInfoPopUp />,
            "Chromosomal": <NoInfoPopUp />,
            "Situational": <NoInfoPopUp />,
            "Chemical": <NoInfoPopUp />,
            "BodyImbalance": <NoInfoPopUp />,
            "PhysicalEnvironment": <NoInfoPopUp />,

        },
        "Syncing": {
            "Deceased": <NoInfoPopUp />,
            "LikeSpeciesGroup": <NoInfoPopUp />,
            "HumansRoomate": <NoInfoPopUp />,
            "HumansFriend": <NoInfoPopUp />,
            "AnimalCompanion": <NoInfoPopUp />,
            "Caretaker": <NoInfoPopUp />,
            "GroupMentality": <NoInfoPopUp />,
            "ChildHuman": <NoInfoPopUp />,
            "Environment": <NoInfoPopUp />,
            "FosterHome": <NoInfoPopUp />,
            "FosterPerson": <NoInfoPopUp />,
            "ShelterRescue": <NoInfoPopUp />,
            "AdultHuman": <NoInfoPopUp />,
            "HumansPartner": <NoInfoPopUp />,
            "HumansHealth": <NoInfoPopUp />,
            "HumansMentalPattern": <WordsPopUp words={["Humans", "Animals"]} />,
            "HomeEnergy": <NoInfoPopUp />,
            "SomethingElse": <NoInfoPopUp />,
        },

        "BodyImbalance": {
            //No Popus in this section
        },
        "EnergyFields": {
            "EF1": <NoInfoPopUp />,
            "EF2": <NoInfoPopUp />,
            "Emotional": <NoInfoPopUp />,
            "M1": <NoInfoPopUp />,
            "M2": <NoInfoPopUp />,
            "S1": <NoInfoPopUp />,
            "S2": <NoInfoPopUp />,
            "S3": <NoInfoPopUp />,
            "Transpersonal": <NoInfoPopUp />,
        },
        "Chakras": {
            "Root": <WordsPopUp words={["Adrenals"]} />,
            "Sacral": <WordsPopUp words={["Ovaries", "Testes"]} />,
            "Solar": <WordsPopUp words={["Pancreas"]} />,
            "Heart": <WordsPopUp words={["Thymus"]} />,
            "Throat": <WordsPopUp words={["Thyroid"]} />,
            "ThirdEye": <WordsPopUp words={["Pituitary"]} />,
            "Brachial": <NoInfoPopUp />,
            "Minor": <NoInfoPopUp />,
            "Bud": <NoInfoPopUp />,
            "MinorSensory": <NoInfoPopUp />,
            "NoInfo": <NoInfoPopUp />,
        },
        "Blood": {
            "WhiteCellAntibodies": <NoInfoPopUp />,
            "CarbonDioxide": <NoInfoPopUp />,
            "RedCells": <NoInfoPopUp />,
            "Nitrogen": <NoInfoPopUp />,
            "Hormones": <NoInfoPopUp />,
            "Proteins": <NoInfoPopUp />,
            "Plasma": <NoInfoPopUp />,
            "Oxygen": <NoInfoPopUp />,
            "StemCells": <NoInfoPopUp />,
            "Platelets": <NoInfoPopUp />,
            "Hemoglobin": <NoInfoPopUp />,
            "Fat": <NoInfoPopUp />,
            "Carbs": <NoInfoPopUp />,
            "Erthrocytes": <NoInfoPopUp />,
            "Hemocytes": <NoInfoPopUp />,
            "Hemolymph": <NoInfoPopUp />,
            "Hemocyanin": <NoInfoPopUp />,
        },
        "Meridians": {
            "Spleen": <WordsPopUp words={["recycle", "fairness", "worry", "feeling dirty", "openness", "depression", "subconscious"]} />,
            "Stomach": <WordsPopUp words={["intellect", " digesting life", "ruminating", "intolerance", "all emotions", "conscious thinking",]} />,
            "Lung": <WordsPopUp words={["letting go", "taking life in fully", "skin Courage", "grief", "assertiveness", "unworthiness",]} />,
            "Heart": <WordsPopUp words={["trust", "disappointment", "insomnia", "joy", "being in touch with self", "depression", "shock", "serenity"]} />,
            "SmIntestine": <WordsPopUp words={["intellect", "discernment", "confusion", "overwhelmed", "clarity",]} />,
            "Bladder": <WordsPopUp words={["annoyed", "peeved", "gentleness", "resentment", "support", "control", "callousness"]} />,
            "Kidney": <WordsPopUp words={["bone marrow", "stability", "restriction", "fear", "knees", "ancestors", "hopelessness", "coping", "wisdom"]} />,
            "HeartGovernor": <WordsPopUp words={["protects the heart from outside disease factors"]} />,
            "TripleHeater": <WordsPopUp words={["impotence", "frigidity", "fascia", "cellular memory", "transition"]} />,
            "GallBladder": <WordsPopUp words={["wi qi", "discernment", "filters", "synthesizing", "decision-making", "motivation"]} />,
            "Liver": <WordsPopUp words={["movement", "female cycle", "anger", "eyes", "muscle spasms", "benevolence"]} />,
            "Paricardium": <WordsPopUp words={["spirituality", "protection", "deep clarity", "eyes", "psychic interaction", "shutting down", "honor"]} />,
            "Multiple": <NoInfoPopUp />,
        },
        "BodyArea": {
            "SkinFurFeatures": <NoInfoPopUp />,
            "Neurological": <NoInfoPopUp />,
            "Eyes": <NoInfoPopUp />,
            "Ears": <NoInfoPopUp />,
            "Lymph": <NoInfoPopUp />,
            "Brain": <NoInfoPopUp />,
            "Colon": <NoInfoPopUp />,
            "Muscles": <NoInfoPopUp />,
            "Fascia": <NoInfoPopUp />,
            "Tendons": <NoInfoPopUp />,
            "Bones": <NoInfoPopUp />,
            "BodyArea": <NoInfoPopUp />,
            "CirculatorySystem": <NoInfoPopUp />,
        },
        "Systems": {
            "Integumentary": <NoInfoPopUp />,
            "Urinary": <NoInfoPopUp />,
            "Reproductive": <NoInfoPopUp />,
            "Endocrine": <NoInfoPopUp />,
            "Digestive": <NoInfoPopUp />,
            "Lymphatic": <NoInfoPopUp />,
            "Nervous": <NoInfoPopUp />,
            "Cardiovascular": <NoInfoPopUp />,
            "Immune": <NoInfoPopUp />,
            "Respiratory": <NoInfoPopUp />,
            "Skeletal": <NoInfoPopUp />,
            "Muscular": <NoInfoPopUp />,
            //"SRS":<WordsPopUp words={["Stress Response System: See Protocol"]} />,
            "SRS":<WordsPopUp words={["Stress Response System"]} />,
        },
        "Emotional": {
            "Bored": <WordsPopUp words={["Need Activity", "Nothing Going On"]} />,
            "AttnIssue": <WordsPopUp words={["Attention Issue"]} />,
            "NeedSpace": <WordsPopUp words={["Emotional", "Physical"]} />,
            "LSE": <WordsPopUp words={["Low Self Esteem"]} />,
            "Grieving": <NoInfoPopUp />,
            "Worried": <NoInfoPopUp />,
            "Jealous": <NoInfoPopUp />,
            "Preoccupied": <NoInfoPopUp />,
            "Shame": <NoInfoPopUp />,
            "WithoutDirection": <WordsPopUp words={["Without Direction"]} />,
            "NeedToBelong": <WordsPopUp words={["Need to Belong"]} />,
            "TakenForGranted": <NoInfoPopUp />,
            "Overwhelmed": <WordsPopUp words={["Overwhelmed", "Stressed"]} />,
            "EmotionalSponging": <NoInfoPopUp />
        },
        "BodyQuadrants": {
            "Head": <NoInfoPopUp />,
            "Chest": <NoInfoPopUp />,
            "Torso": <NoInfoPopUp />,
            "HindEnd": <NoInfoPopUp />,
        },
        "Connectors": {
            "Fascia": <NoInfoPopUp />,
            "Muscles": <NoInfoPopUp />,
            "Tendons": <NoInfoPopUp />,
            "Nerves": <NoInfoPopUp />,
            "Ligaments": <NoInfoPopUp />,
            "Bones": <NoInfoPopUp />,
            "Joints": <NoInfoPopUp />,
            "Skin": <NoInfoPopUp />,
            "Cartilage": <NoInfoPopUp />,
        },
        "Bones": {
            //no popups in this section
        },


        "Cells": {
            "NuclearEnvelope": <WordsPopUp words={["Protects the nucleus", "Lets things in and out"]} />,
            "CellMembrane": <WordsPopUp words={["The outer most boundary of the cell and separates the cell from other cells."]} />,
            "EndoplasmicReticulum": <WordsPopUp words={["Passageway for material moving through the cell"]} />,
            "Mitochondria": <WordsPopUp words={["The main energy source of the cell"]} />,
            "Cytoskeleton": <WordsPopUp words={["Protein filaments that give the shape, support and movement"]} />,
            "Eukaryote": <WordsPopUp words={["Contain membrane-bound organelles such as the nucleus"]} />,
            "FetalCell": <WordsPopUp words={["Left over cells from pregnancy and birth that can free float within mother’s body long after birth"]} />,
            "Lysosomes": <WordsPopUp words={["The digestive system of the cell"]} />,
            "Cell": <WordsPopUp words={["The smallest structural and functional unit of an organism"]} />,
            "Cytoplasm": <WordsPopUp words={["Clear thick fluid "]} />,
            "Nucleolus": <WordsPopUp words={["Makes ribosomes"]} />,
            "Organelle": <WordsPopUp words={["Any of a number of organized or specialized structures within a living cell"]} />,
            "Prokaryote": <WordsPopUp words={["Usually unicellular (bacteria)"]} />,
            "Ribosome": <WordsPopUp words={["This is where the RNA is translated into protein"]} />,
            "Vacuole": <WordsPopUp words={["Single membrane organelles that are part of the outside that is located within the cell."]} />,
            "Vesicle": <WordsPopUp words={["They function in transport within and to the outside of the cell"]} />,
            "Endoderm": <WordsPopUp words={["One of the three primary germ layers in the early human embryo and is the proximal layer", "Exocrine secretory epithelial cells: They cover outer body surfaces and excrete", "Hormone secreting cells: Cells that secrete horomes"]} />,
            "Ectoderm": <WordsPopUp words={["The outer layer of cells in an embryo", "Integumentary system cells: Cells within the skin", "Nervous system cells: Neurons that carry messages through an electrochemical process"]} />,
            "GolgiApparatus": <WordsPopUp words={["Changes molecules and divides them into small membrane contained sacs called vesicles"]} />,

        },

        "Reproductive": {
            //no modals here
        },

        "Colors": {
            "Red": <WordsPopUp words={["Brings balance to the area"]} />,
            "Orange": <WordsPopUp words={["Brings the energy of celebration to the situation"]} />,
            "Yellow": <WordsPopUp words={["Brings wisdom to the situation"]} />,
            "Green": <WordsPopUp words={["Brings healing and abundance to the situation"]} />,
            "Blue": <WordsPopUp words={["Brings power and protection to the area and situation"]} />,
            "Violet": <WordsPopUp words={["Brings forgiveness to the situation"]} />,
            "Indigo": <WordsPopUp words={["Brings empowerment to the situation"]} />,
            "White": <WordsPopUp words={["Brings purity to the situation"]} />,
            "Silver": <WordsPopUp words={["Brings magnificence to the situation"]} />,
            "Gold": <WordsPopUp words={["Brings manifestation and perfection to the situation"]} />,
            "Black": <WordsPopUp words={["Density and stuck energy"]} />,
            "Rainbow": <WordsPopUp words={["Brings the full spectrum of color and healing"]} />,

        },


        "Clearing": {
            "Frequency": <WordsPopUp words={["Raise", "Lower"]} />,
            "CallAngel": <CallAngel />,
            "WriteIntention": <WordsPopUp words={["Write down your intention: This is asking you to write down what you are intending. Writing it down is a powerful manifestation practice."]} />,
            "Animal": <AnimalPopUp />,
            "Spirit": <SpiritPopUp />,
            "TakeInspriedAction": <NoInfoPopUp />,
            "ClearEnergyField": <WordsPopUp words={["With a left spin of your pendulum and an intention to clear any unwanted energies that are not in the highest good of the animal that you are clearing ask for it to be cleared and taken to a dimension where it cannot come back again to bother the animal that it was attached to. When the pendulum stops spinning on its own, ask if the energies were cleared. If you get a yes, then do a right spin and bring in the protective and healing white energies of the Divine and if you get a no, repeat the left spin process intending to clear any unwanted energies until you get a yes. Always end this process with filling the cleared space with the protective and healing white energies of the Divine."]} />,
        },

        "Frequency": {
            //all of them have the same popup
            "Frequency": <NoInfoPopUp />,
        },
        "Origination": {
            //all of them have the same popup
            "Origination": <NoInfoPopUp />,
        }
    }
}