import { ChartPageType } from '../../types';
import React from 'react';
import { WordsPopUp, NoInfoPopUp } from '../../popup';
import { Card, CardContent, Typography, Box, Link } from '@mui/material';
import { SUB_TEXT_TYPOGRAPHY_PROPS } from '../../../utils';
import { useLocation } from 'react-router-dom';
import ModuleChart from '../../../components/ModuleChart/ModuleChart';


export interface CustomChartProps {
    width: number;
    height: number;
}

export type StepperContentItem = {
    label: string;
    chart?: ChartPageType;
    customText?: React.ReactElement;
    customChart?: React.ComponentType<CustomChartProps>;
    finalStepLink?: string;
    finalStepLabel?: string;
    backLink?: string;
    backLinkLabel?: string;
};

export type StepperContentMapType = {
    [step: string]: StepperContentItem;
};

export type StepperMapType = {
    [module: string]: {
        [item: string]: StepperContentMapType | {
            [subitem: string]: StepperContentMapType;
        }
    }
};




export const NavProtocolPopUp = ({ program, link }: { program: string, link: string }) => {
    return (
        <Box>
            <Typography>
                Proceed to the <Link href={link}>{program} STYLE PROGRAM</Link> and begin a regular session. Go until session is complete.
            </Typography>
            <Typography>
                After the session is complete, go back to the first chart in this protocol to see if another protocol is needed for this session. Follow the instructions that you receive.
            </Typography>
        </Box>

    );
}

export const RedPopUp = () => {
    return (
        <div>
            <Typography><strong>Balanced:</strong> Courage, action, grounding, stability, survival</Typography>
            <Typography><strong>Unbalanced:</strong> Violence, greed, self-centeredness</Typography>
            <Typography>It activates, vitalizes, intensifies, releases blocked energy, produces collagen</Typography>
        </div>
    );
}

export const OrangePopUp = () => {
    return (
        <div>
            <Typography><strong>Balanced:</strong> Passion, trust, emotions, health, pleasure, let go</Typography>
            <Typography><strong>Unbalanced:</strong> Jealousy, envy, aimlessness, obsessiveness</Typography>
            <Typography>It restores, animates, warms, stimulates, building up energy step by step, unlocks deadlocked processes, cheers up</Typography>
        </div>
    );
}

export const YellowPopUp = () => {
    return (
        <div>
            <Typography><strong>Balanced:</strong> Personal power, self-esteem</Typography>
            <Typography><strong>Unbalanced:</strong> Fear, anger, hate, power</Typography>
            <Typography>Fortifies, tones, opens, brightness, stimulates without exciting, extends energy, reinforces weak processes, loosens rigidity, strengthens nerves</Typography>
        </div>
    );
}

export const GreenPopUp = () => {
    return (
        <div>
            <Typography><strong>Balanced:</strong> Acceptance, forgiveness, love, radiance</Typography>
            <Typography><strong>Unbalanced:</strong> Instability, repressed love</Typography>
            <Typography>Equalizes, relaxes, calms down, keeps physical and mental energy dynamically balanced, releases tensed-up painful processes, brings profound serenity</Typography>
        </div>
    );
}

export const DarkBluePopUp = () => {
    return (
        <div>
            <Typography><strong>Balanced:</strong> Communication, truth, creative arts</Typography>
            <Typography><strong>Unbalanced:</strong> Depression, ignorance</Typography>
            <Typography>Reduces inflammation, restful sleep, insomnia, sunburn, toothache, soothes, focuses, cools down, collects and structures energy, regulates hyperactive, inflammatory and depleting processes, brings serenity and clarity</Typography>
        </div>
    );
}

export const IndigoPopUp = () => {
    return (
        <div>
            <Typography><strong>Balanced:</strong> Perception, realization, intuition, clairvoyance</Typography>
            <Typography><strong>Unbalanced:</strong> Fear, tension, headaches</Typography>
            <Typography>Raises concentration, detoxing and cooling it, strengthens lymphatics, muscle toning, reduces bleeding, astringent, high-level vibration, known for effect on ears, eyes, nose, throat, sinuses, migraines</Typography>
        </div>
    );
}

export const VioletPopUp = () => {
    return (
        <div>
            <Typography><strong>Balanced:</strong> Fulfillment, inspiration</Typography>
            <Typography><strong>Unbalanced:</strong> Confusion</Typography>
            <Typography>Inspires, attenuates, transforms energy on a higher level, supports mental processes, relaxes the nerves, soothes nervous irritations, and relieves pain. It aids HBP, acne, lymph drainage, neuralgia, muscle relaxer, rheumatism</Typography>
        </div>
    );
}

export const ClearingStatement = ({ isPartOne }: { isPartOne: boolean }) => {
    const statementTwo = `
    Clear anything that stands in the way of person's name feeling, 
    knowing and believing deep in his/her heart that he/she is pure spirit 
    and that he/she is truly seen and loved by god.
    `;

    const statementOne = `
    Clear anything that stands in the way of persons' name releasing any patterns, signatures, 
    habits, reproductive programming, repetitive artificial binary programming from relationships 
    and any residual energies leftover from any other existence that is no longer serving persons' 
    name and his/her highest good.`

    const statement = isPartOne ? statementOne : statementTwo;


    return (<Card sx={{ maxWidth: 600, margin: 'auto', mt: 4, padding: 2, boxShadow: 3 }}>
        <CardContent>
            <Typography variant="h6" sx={{ mb: 1 }} >
                <strong>Clearing Statement</strong>
            </Typography>
            <Typography variant="body1" component={'p'} >
                {statement}
            </Typography>
        </CardContent>
    </Card>);
}


const LifeBalanceP2Instructions = () => {
    return (<Card sx={{ maxWidth: 600, margin: 'auto', mt: 4, padding: 2, boxShadow: 3 }}>
        <CardContent>
            <Typography variant="h6" sx={{ mb: 1 }} >
                <strong>Instructions</strong>
            </Typography>
            <Typography variant="body1" sx={{ mb: 0, ml: 1 }} >
                Ask to clear any addiction programming in:
            </Typography>

            <Typography variant="body1" sx={{ ml: 3 }} >
                Head Brain
            </Typography>
            <Typography variant="body1" sx={{ ml: 3 }} >
                Heart Brain
            </Typography>
            <Typography variant="body1" sx={{ ml: 3 }} >
                Gut Brain
            </Typography>
            <Typography variant="body1" component={'p'} sx={{ mt: 2, ml: 1 }} >
                Clear anything that is standing in the way of my head, heart and gut brains releasing any and all
                programming that is affecting and holding back this soul's evolution in any way. Then balance
                each of these brains to each other to restore perfect Divine functioning of them individually
                and together as a complete, whole and healthy system.
            </Typography>
        </CardContent>
    </Card>);
}

const LifeBalanceClearing = () => {
    return (
        <Card sx={{ maxWidth: 600, margin: 'auto', mt: 2, p: 0, boxShadow: 3 }}>
            <CardContent>
                <Typography variant="h6" sx={{ mb: 1 }}>
                    <strong>Clearing Instructions</strong>
                </Typography>

                {/* Instruction #1 */}
                <Typography variant="body1" sx={{ mb: 2, ml: 1 }}>
                    1. Ask to denounce and clear the underlying programming and addiction effects that
                    have been imprinted on this soul from any and all institutions that have left
                    programming not in alignment with the true Divine nature of this soul you are working on
                    and replace these energies with the frequencies of peace, love, joy, and compassion.
                </Typography>

                {/* Instruction #2 */}
                <Typography variant="body1" sx={{ ml: 1 }}>
                    2. Intend to clear and restore the soul at its most natural Divine state with regards to:
                </Typography>
                <Typography variant="body1" sx={{ ml: 3 }}>
                    - soul formation
                </Typography>
                <Typography variant="body1" sx={{ ml: 3 }}>
                    - soul presentation
                </Typography>
                <Typography variant="body1" sx={{ ml: 3 }}>
                    - soul transformation
                </Typography>
                <Typography variant="body1" sx={{ ml: 3 }}>
                    - soul evolution
                </Typography>
                <Typography variant="body1" sx={{ mt: 1, ml: 1 }}>
                    In any and all manifestations in this lifetime and from any other lifetimes, restoring
                    this soul back to its perfect Divine state across any and all dimensions and galaxies.
                </Typography>

                {/* Final instructions */}
                <Typography variant="body1" sx={{ mt: 1, ml: 1 }}>
                    Encapsulate with intention and clear!!!!
                </Typography>
                <Typography variant="body1" sx={{ mt: 1, ml: 1 }}>
                    Ask if another session on this is needed for a full recovery. If yes, then find out
                    when the next session is needed.
                </Typography>
            </CardContent>
        </Card>
    );
};

export const LifeBalanceFemalePopUp = () => {
    return (
        <div>
            <Typography>
                You will be working on the seeds of addiction planted from the
                mis-programming of your soul from the eggs and con-ception energy from
                the females in your ancestral genetic line. You will also be doing a DNA
                cleanse of the effects of these seeds for all generations forward and back.
                With that intention, go through each chart and find all the answers that apply here.
                You will then intend to encapsulate all those findings and those findings
                will become the item that you will be working on.
            </Typography>
        </div>
    );
}

export const LifeBalanceMalePopUp = () => {
    return (
        <div>
            <Typography>
                You will then be doing the exact same session as above on the seeds of addiction
                planted from the mis-programming of your soul from the sperm and conception
                energy from the males in your ancestral genetic line. You will also be doing a
                DNA cleanse of the effects of these seeds for all generations forward and back.
                With that intention, go through each chart and find all the answers that apply here.
                You will then intend to encapsulate all those findings and those findings will
                become the item that you will be working on.
            </Typography>
        </div>
    );
}


export const LifeBlockersCharts: ChartPageType = {
    chart: [
        { id: 0, label: 'People', value: 1, color: '#D32F2F', onClickNav: '/protocol/?bottom_nav=protocol&module=Origin&item=Soul+Work&step=life-blockers-subchart&chart_num=People' },
        { id: 1, label: 'Env Factors', value: 1, color: '#E97A23', onClickNav: '/protocol/?bottom_nav=protocol&module=Origin&item=Soul+Work&step=life-blockers-subchart&chart_num=Environmental+Factors' },
        { id: 2, label: 'Actions', value: 1, color: '#F0B731', onClickNav: '/protocol/?bottom_nav=protocol&module=Origin&item=Soul+Work&step=life-blockers-subchart&chart_num=Actions' },
        { id: 3, label: 'Exposures', value: 1, color: '#8BC34A', onClickNav: '/protocol/?bottom_nav=protocol&module=Origin&item=Soul+Work&step=life-blockers-subchart&chart_num=Exposures' },
    ],
    level: 0,
    subPages: {
        "People": {
            chart: [
                { id: 0, label: 'Family', value: 1, color: '#D32F2F', popupContent: <NoInfoPopUp /> },
                { id: 1, label: 'Friends', value: 1, color: '#E97A23', popupContent: <NoInfoPopUp /> },
                { id: 2, label: 'Acquaintances/Social Media', value: 1, color: '#F0B731', popupContent: <NoInfoPopUp /> },
                { id: 3, label: 'Co-Workers/Teams', value: 1, color: '#8BC34A', popupContent: <NoInfoPopUp /> },
                { id: 4, label: 'Other', value: 1, color: '#4CAF50', popupContent: <NoInfoPopUp /> }
            ],
            level: 0,
            subPages: {}
        },
        'Environmental Factors': {
            chart: [
                { id: 0, label: 'Job', value: 1, color: '#D32F2F', popupContent: <NoInfoPopUp /> },
                { id: 1, label: 'Home', value: 1, color: '#E97A23', popupContent: <NoInfoPopUp /> },
                { id: 2, labelWithFormatting: 'Spiritual\nEnvironment', label: 'Spiritual Environment', value: 1, color: '#F0B731', popupContent: <NoInfoPopUp /> },
                { id: 3, labelWithFormatting: 'Emotional\nEnvironment', label: 'Emotional Environment', value: 1, color: '#8BC34A', popupContent: <NoInfoPopUp /> },
                { id: 4, labelWithFormatting: 'Situational\nEnvironment', label: 'Situational Environment', value: 1, color: '#4CAF50', popupContent: <NoInfoPopUp /> },
                { id: 5, labelWithFormatting: 'Activities/Hobbies\nand Teams', label: 'Activities/Hobbies and Teams', value: 1, color: '#0097A7', popupContent: <NoInfoPopUp /> },
                { id: 6, label: 'Nature', value: 1, color: '#2A468A', popupContent: <NoInfoPopUp /> }
            ],
            level: 0,
            subPages: {}
        },
        'Actions': {
            chart: [
                { id: 1, labelWithFormatting: 'Discover Underlying\nCause and Then \nDo a Session', label: 'Discover Underlying Cause and Then Do a Session', value: 1, color: '#E97A23', popupContent: <NoInfoPopUp /> },
                { id: 2, labelWithFormatting: 'Clear Your\nRelationship With', label: 'Clear Your Relationship With', value: 1, color: '#F0B731', popupContent: <NoInfoPopUp /> },
                { id: 3, labelWithFormatting: 'Find How This\n is Serving You and \nThen Do a Session', label: 'Find How This is Serving You and Then Do a Session', value: 1, color: '#8BC34A', popupContent: <NoInfoPopUp /> }
            ],
            level: 0,
            subPages: {}
        },
        'Exposures': {
            chart: [
                { id: 0, label: 'Foods/Products I Use', value: 1, color: '#D32F2F', popupContent: <NoInfoPopUp /> },
                { id: 1, labelWithFormatting: 'Toxic Chemicals\nExposed To', label: 'Toxic Chemicals Exposed To', value: 1, color: '#E97A23', popupContent: <NoInfoPopUp /> },
                { id: 2, label: 'Toxic People', value: 1, color: '#F0B731', popupContent: <NoInfoPopUp /> },
                { id: 3, label: 'Other', value: 1, color: '#8BC34A', popupContent: <NoInfoPopUp /> }
            ],
            level: 0,
            subPages: {}
        },


    }
};


// Custom chart component defined as a functional component.
const LifeBlockersChart: React.FC<CustomChartProps> = ({ width, height }) => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const step = queryParams.get('step') || 'life-blockers';
    let chartNum = queryParams.get('chart_num') || 'life-blockers-top';
    if (step === 'life-blockers') {
        chartNum = 'life-blockers-top';
    }


    let chartData = LifeBlockersCharts;
    if (chartNum !== 'life-blockers-top') {
        chartData = LifeBlockersCharts.subPages[chartNum];
    }

    return (
        <ModuleChart chartData={chartData} height={height} width={width} chartModule='Protocol' item={null} subitem={null} />
    );
};

export const CyclesCharts: ChartPageType = {
    chart: [
        { id: 0, label: 'Disruption', value: 1, color: '#D32F2F', onClickNav: '/protocol/?bottom_nav=protocol&module=Origin&item=Circadian+Cycle&step=blockers&chart_num=Disruption' },
        { id: 1, label: 'Regulation', value: 1, color: '#E97A23', onClickNav: '/protocol/?bottom_nav=protocol&module=Origin&item=Circadian+Cycle&step=blockers&chart_num=Regulation' },
    ],
    level: 0,
    subPages: {
        'Disruption': {
                chart: [
                    { id: 0, label: 'Suprachiasmic nucleus (SCN)', labelWithFormatting: 'Suprachiasmic \n nucleus (SCN)', value: 1, color: '#D32F2F', popupContent: <NoInfoPopUp /> },
                    { id: 1, label: 'Sleep disorders - intrinsic or extrinsic', labelWithFormatting: 'Sleep disorders \n intrinsic or extrinsic', value: 1, color: '#E97A23', popupContent: <NoInfoPopUp /> },
                    { id: 2, label: 'Delayed sleep phase syndrome (DSPS)', labelWithFormatting: 'Delayed sleep \n phase syndrome (DSPS)', value: 1, color: '#F0B731', popupContent: <NoInfoPopUp /> },
                    { id: 3, label: 'Advanced sleep phase syndrome (ASPS)', labelWithFormatting: 'Advanced sleep \n phase syndrome (ASPS)', value: 1, color: '#8BC34A', popupContent: <NoInfoPopUp /> },
                    { id: 4, label: 'Non-24 hour sleep-wake syndrome', labelWithFormatting: 'Non-24 hour \n sleep-wake syndrome', value: 1, color: '#4CAF50', popupContent: <NoInfoPopUp /> },
                    { id: 5, label: 'Irregular sleep-wake rhythm', labelWithFormatting: 'Irregular \n sleep-wake rhythm', value: 1, color: '#009688', popupContent: <NoInfoPopUp /> },
                    { id: 6, label: 'Shift-work sleep disorder', labelWithFormatting: 'Shift-work \nsleep disorder', value: 1, color: '#2A468A', popupContent: <NoInfoPopUp /> },
                    { id: 7, label: 'Jet lag', labelWithFormatting: 'Jet lag', value: 1, color: '#512DA8', popupContent: <NoInfoPopUp /> }
                ],
                level: 0,
                subPages: {},
        },
        'Regulation': {
                chart: [
                    { id: 0, label: 'Light therapy', value: 1, color: '#D32F2F', popupContent: <NoInfoPopUp /> },
                    { id: 1, label: 'Master circadian clock', value: 1, color: '#E97A23', popupContent: <NoInfoPopUp /> },
                    { id: 2, label: 'Medications', value: 1, color: '#F0B731', popupContent: <NoInfoPopUp /> },
                    { id: 3, label: 'Circadian genes', value: 1, color: '#8BC34A', popupContent: <NoInfoPopUp /> },
                    { id: 4, label: 'Environment', value: 1, color: '#4CAF50', popupContent: <NoInfoPopUp /> },
                    { id: 5, label: 'NPAS2', value: 1, color: '#009688', popupContent: <NoInfoPopUp /> },
                    { id: 6, label: 'Emotions', value: 1, color: '#2A468A', popupContent: <NoInfoPopUp /> },
                    { id: 7, label: 'PER3', value: 1, color: '#512DA8', popupContent: <NoInfoPopUp /> },
                    { id: 8, label: 'Hormones', value: 1, color: '#673AB7', popupContent: <NoInfoPopUp /> },
                    { id: 9, label: 'BMAL1', value: 1, color: '#795548', popupContent: <NoInfoPopUp /> },
                    { id: 10, label: 'Neurons', value: 1, color: '#8D6E63', popupContent: <NoInfoPopUp /> },
                    { id: 11, label: 'Timeless', value: 1, color: '#FF5252', popupContent: <NoInfoPopUp /> },
                    { id: 12, label: 'Electromagnetics', value: 1, color: '#FFA000', popupContent: <NoInfoPopUp /> },
                    { id: 13, label: 'Clock', value: 1, color: '#FFEB3B', popupContent: <NoInfoPopUp /> },
                    { id: 14, label: 'Programming', labelWithFormatting: "Programming \n nanotechnology", value: 1, color: '#C0CA33', popupContent: <NoInfoPopUp /> },
                    { id: 15, label: 'Lithium target GSK3', value: 1, color: '#7CB342', popupContent: <NoInfoPopUp /> }
                ],
                level: 0,
                subPages: {},
        },
    }
};


// Custom chart component defined as a functional component.
const CyclesChart: React.FC<CustomChartProps> = ({ width, height }) => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const step = queryParams.get('step') || 'Circadian Cycle';
    let chartNum = queryParams.get('chart_num') || 'cycles-chart-top';
    if (step === 'Circadian Cycle') {
        chartNum = 'cycles-chart-top';
    }


    let chartData = CyclesCharts;
    if (chartNum !== 'cycles-chart-top') {
        chartData = CyclesCharts.subPages[chartNum];
    }

    return (
        <ModuleChart chartData={chartData} height={height} width={width} chartModule='Protocol' item={null} subitem={null} />
    );
};



export const StepPageMap: StepperMapType = {
    'Energetic Seed': {
        'Life Balance': {
            'male-or-female': {
                label: "Male/Female",
                chart: {
                    chart: [
                        { label: 'Male', value: 1, id: 0, color: '#88181B', popupContent: <LifeBalanceMalePopUp /> },
                        { label: 'Female', value: 1, id: 1, color: '#EE1D23', popupContent: <LifeBalanceFemalePopUp /> },
                    ],
                    level: 0,
                    subPages: {},
                    disableClick: false,
                }
            },
            'walks': {
                label: "Walks",
                chart: {
                    chart: [
                        { id: 0, label: '0', value: 1, color: '#D32F2F', popupContent: <NoInfoPopUp /> },
                        { id: 1, label: '1', value: 1, color: '#E97A23', popupContent: <NoInfoPopUp /> },
                        { id: 2, label: '2', value: 1, color: '#F0B731', popupContent: <NoInfoPopUp /> },
                        { id: 3, label: '3', value: 1, color: '#8BC34A', popupContent: <NoInfoPopUp /> },
                        { id: 4, label: '4', value: 1, color: '#4CAF50', popupContent: <NoInfoPopUp /> },
                        { id: 5, label: '5', value: 1, color: '#0097A7', popupContent: <NoInfoPopUp /> },
                        { id: 6, label: '6', value: 1, color: '#2A468A', popupContent: <NoInfoPopUp /> },
                        { id: 7, label: '7', value: 1, color: '#512DA8', popupContent: <NoInfoPopUp /> },
                        { id: 8, label: '8', value: 1, color: '#673AB7', popupContent: <NoInfoPopUp /> },
                        { id: 9, label: '9', value: 1, color: '#8D6E63', popupContent: <NoInfoPopUp /> },
                        { id: 10, label: '10', value: 1, color: '#C62828', popupContent: <NoInfoPopUp /> },
                        { id: 11, label: '11', value: 1, color: '#E64A19', popupContent: <NoInfoPopUp /> },
                        { id: 12, label: '12', value: 1, color: '#F57C00', popupContent: <NoInfoPopUp /> },
                        { id: 13, label: '13', value: 1, color: '#FFEB3B', popupContent: <NoInfoPopUp /> },
                        { id: 14, label: '14', value: 1, color: '#4CAF50', popupContent: <NoInfoPopUp /> },
                        { id: 15, label: '15', value: 1, color: '#1E88E5', popupContent: <NoInfoPopUp /> },
                        { id: 16, label: '16', value: 1, color: '#5E35B1', popupContent: <NoInfoPopUp /> },
                        { id: 17, label: '>16', value: 1, color: '#8D6E63', popupContent: <NoInfoPopUp /> }
                    ],
                    level: 0,
                    subPages: {},
                    disableClick: false,
                    textChartInstructions: <Typography {...SUB_TEXT_TYPOGRAPHY_PROPS} >
                        Number of Concurrent life walks
                    </Typography>

                }

            },
            'stage': {
                label: "Stage",
                chart: {
                    chart: [
                        { id: 0, label: 'Unborn Soul', value: 1, color: '#D32F2F', popupContent: <NoInfoPopUp /> },
                        { id: 1, label: 'In Utero', value: 1, color: '#E97A23', popupContent: <NoInfoPopUp /> },
                        { id: 2, label: 'Infant', value: 1, color: '#F0B731', popupContent: <NoInfoPopUp /> },
                        { id: 3, label: 'Toddler', value: 1, color: '#8BC34A', popupContent: <NoInfoPopUp /> },
                        { id: 4, label: 'Child', value: 1, color: '#4CAF50', popupContent: <NoInfoPopUp /> },
                        { id: 5, label: 'Pre-Adolescent', value: 1, color: '#0097A7', popupContent: <NoInfoPopUp /> },
                        { id: 6, label: 'Adolescent', value: 1, color: '#2A468A', popupContent: <NoInfoPopUp /> },
                        { id: 7, label: 'Young Adult', value: 1, color: '#512DA8', popupContent: <NoInfoPopUp /> },
                        { id: 8, label: 'Adult', value: 1, color: '#673AB7', popupContent: <NoInfoPopUp /> },
                        { id: 9, label: 'Geriatric', value: 1, color: '#8D6E63', popupContent: <NoInfoPopUp /> },
                        { id: 10, label: 'End of Life', value: 1, color: '#795548', popupContent: <NoInfoPopUp /> },
                        { id: 11, label: 'Reflecting Soul', value: 1, color: '#D32F2F', popupContent: <NoInfoPopUp /> }
                    ],
                    level: 0,
                    subPages: {},
                    disableClick: false,
                    textChartInstructions: <Typography {...SUB_TEXT_TYPOGRAPHY_PROPS} >
                        Stage of life where energy fragmentation began?
                    </Typography>

                }

            },
            'lessons': {
                label: "Lessons",
                chart: {
                    chart: [
                        { id: 0, label: 'Loss', value: 1, color: '#D32F2F', popupContent: <NoInfoPopUp /> },
                        { id: 1, label: 'Attachment', value: 1, color: '#E97A23', popupContent: <NoInfoPopUp /> },
                        { id: 2, label: 'Appearance', value: 1, color: '#F0B731', popupContent: <NoInfoPopUp /> },
                        { id: 3, label: 'Fulfillment', value: 1, color: '#8BC34A', popupContent: <NoInfoPopUp /> },
                        { id: 4, label: 'Duality', value: 1, color: '#4CAF50', popupContent: <NoInfoPopUp /> },
                        { id: 5, label: 'Egoism', value: 1, color: '#0097A7', popupContent: <NoInfoPopUp /> },
                        { id: 6, label: 'Fame', value: 1, color: '#2A468A', popupContent: <NoInfoPopUp /> },
                        { id: 7, label: 'Sexuality', value: 1, color: '#512DA8', popupContent: <NoInfoPopUp /> },
                        { id: 8, label: 'Selfishness', value: 1, color: '#673AB7', popupContent: <NoInfoPopUp /> },
                        { id: 9, label: 'Fear', value: 1, color: '#8D6E63', popupContent: <NoInfoPopUp /> },
                        { id: 10, label: 'Anger', value: 1, color: '#795548', popupContent: <NoInfoPopUp /> },
                        { id: 11, label: 'Greed', value: 1, color: '#D32F2F', popupContent: <NoInfoPopUp /> },
                        { id: 12, label: 'Jealousy', value: 1, color: '#E97A23', popupContent: <NoInfoPopUp /> },
                        { id: 13, label: 'Power', value: 1, color: '#F0B731', popupContent: <NoInfoPopUp /> },
                        { id: 14, label: 'Pride', value: 1, color: '#4CAF50', popupContent: <NoInfoPopUp /> },
                        { id: 15, label: 'Wealth', value: 1, color: '#1E88E5', popupContent: <NoInfoPopUp /> },
                        { id: 16, label: 'Complacency', value: 1, color: '#5E35B1', popupContent: <NoInfoPopUp /> }
                    ],
                    level: 0,
                    subPages: {},
                    disableClick: false,
                    textChartInstructions: <Typography {...SUB_TEXT_TYPOGRAPHY_PROPS} >
                        What is the associated life lesson(s)?
                    </Typography>

                }
            },
            'shame': {
                label: "Shame",
                chart: {
                    chart: [
                        { id: 0, label: "I'm not good enough", value: 1, color: '#D32F2F', popupContent: <NoInfoPopUp /> },
                        { id: 1, label: "I'm bad", value: 1, color: '#E97A23', popupContent: <NoInfoPopUp /> },
                        { id: 2, label: "I don't belong", value: 1, color: '#F0B731', popupContent: <NoInfoPopUp /> },
                        { id: 3, label: "I'm unlovable", value: 1, color: '#8BC34A', popupContent: <NoInfoPopUp /> },
                        { id: 4, label: "I should not exist", value: 1, color: '#0097A7', popupContent: <NoInfoPopUp /> },
                        { id: 5, label: "I'm broken", value: 1, color: '#2A468A', popupContent: <NoInfoPopUp /> }
                    ],
                    level: 0,
                    subPages: {},
                    disableClick: false,

                }
            },
            'brain': {
                label: "Brain",
                chart: {
                    chart: [
                        { id: 0, label: "Frontal Lobe", value: 1, color: '#D32F2F', popupContent: <WordsPopUp words={['Left Hemisphere or Right Hemisphere']} /> },
                        { id: 1, label: "Parietal Lobe", value: 1, color: '#E97A23', popupContent: <WordsPopUp words={['Left Hemisphere or Right Hemisphere']} /> },
                        { id: 2, label: "Limbic Lobe", value: 1, color: '#F0B731', popupContent: <WordsPopUp words={['Left Hemisphere or Right Hemisphere']} /> },
                        { id: 3, label: "Occipital Lobe", value: 1, color: '#8BC34A', popupContent: <WordsPopUp words={['Left Hemisphere or Right Hemisphere']} /> },
                        { id: 4, label: "Temporal Lobe", value: 1, color: '#4CAF50', popupContent: <WordsPopUp words={['Left Hemisphere or Right Hemisphere']} /> }
                    ],
                    level: 0,
                    subPages: {},
                    disableClick: false,

                },
                finalStepLabel: "Continue to Part 2",
                finalStepLink: '/protocol/?bottom_nav=protocol&module=Energetic+Seed&item=Life+Balance+Part+2&step=shifting'
            },
        },
        'Life Balance Part 2': {
            'receptor': {
                label: "Receptor",
                chart: {
                    chart: [
                        { label: 'Alpha 1 Receptor', value: 1, id: 0, color: '#D32F2F', popupContent: <NoInfoPopUp /> },
                        { label: 'Alpha 2 Receptor', value: 1, id: 1, color: '#E97A23', popupContent: <NoInfoPopUp /> },
                        { label: 'Beta 1 Receptor', value: 1, id: 2, color: '#F0B731', popupContent: <NoInfoPopUp /> },
                        { label: 'Beta 2 Receptor', value: 1, id: 3, color: '#8BC34A', popupContent: <NoInfoPopUp /> },
                    ],
                    level: 0,
                    subPages: {},
                    disableClick: false,
                },
                backLink: '/protocol/?bottom_nav=protocol&module=Energetic+Seed&item=Life+Balance&step=brain',
                backLinkLabel: 'Back to Part 1'
            },
            'issue': {
                label: "Issue Category",
                chart: {
                    chart: [
                        { id: 0, label: 'Abundance', value: 1, color: '#D32F2F', popupContent: <NoInfoPopUp /> },
                        { id: 1, label: 'Appearance', value: 1, color: '#E97A23', popupContent: <NoInfoPopUp /> },
                        { id: 2, label: 'Career', value: 1, color: '#F0B731', popupContent: <NoInfoPopUp /> },
                        { id: 3, label: 'Comparison', value: 1, color: '#8BC34A', popupContent: <NoInfoPopUp /> },
                        { id: 4, label: 'Faith', value: 1, color: '#4CAF50', popupContent: <NoInfoPopUp /> },
                        { id: 5, label: 'Family', value: 1, color: '#0097A7', popupContent: <NoInfoPopUp /> },
                        { id: 6, label: 'Friendship', value: 1, color: '#2A468A', popupContent: <NoInfoPopUp /> },
                        { id: 7, label: 'Goals', value: 1, color: '#512DA8', popupContent: <NoInfoPopUp /> },
                        { id: 8, label: 'Health-emotional', value: 1, color: '#673AB7', popupContent: <NoInfoPopUp /> },
                        { id: 9, label: 'Health-physical', value: 1, color: '#8D6E63', popupContent: <NoInfoPopUp /> },
                        { id: 10, label: 'Leadership', value: 1, color: '#795548', popupContent: <NoInfoPopUp /> },
                        { id: 11, label: 'Legacy', value: 1, color: '#000000', popupContent: <NoInfoPopUp /> },
                        { id: 12, label: 'Relationships', value: 1, color: '#3F51B5', popupContent: <NoInfoPopUp /> }
                    ],
                    level: 0,
                    subPages: {},
                    disableClick: false,
                },
            },
            'shame': {
                label: "Shame Statement",
                chart: {
                    chart: [
                        { id: 0, label: "I'm not good enough", value: 1, color: '#D32F2F', popupContent: <NoInfoPopUp /> },
                        { id: 1, label: "I'm bad", value: 1, color: '#E97A23', popupContent: <NoInfoPopUp /> },
                        { id: 2, label: "I don't belong", value: 1, color: '#F0B731', popupContent: <NoInfoPopUp /> },
                        { id: 3, label: "I'm unlovable", value: 1, color: '#8BC34A', popupContent: <NoInfoPopUp /> },
                        { id: 4, label: "I should not exist", value: 1, color: '#0097A7', popupContent: <NoInfoPopUp /> },
                        { id: 5, label: "I'm broken", value: 1, color: '#2A468A', popupContent: <NoInfoPopUp /> }
                    ],
                    level: 0,
                    subPages: {},
                    disableClick: false,
                },
            },
            'problem': {
                label: "Problem Category",
                chart: {
                    chart: [
                        { id: 0, label: 'Past Life', value: 1, color: '#D32F2F', popupContent: <WordsPopUp words={['Clear energy', 'Integrate lesson']} /> },
                        { id: 1, label: 'Karmic', value: 1, color: '#E97A23', popupContent: <WordsPopUp words={['Clear energy', 'Integrate lesson']} /> },
                        { id: 2, label: 'Current Life', value: 1, color: '#F0B731', popupContent: <NoInfoPopUp /> },
                        { id: 3, label: 'Outside Source', value: 1, color: '#8BC34A', popupContent: <NoInfoPopUp /> },
                        { id: 4, label: 'Inside Source', value: 1, color: '#4CAF50', popupContent: <NoInfoPopUp /> },
                        { id: 5, label: 'Energetic Rider', value: 1, color: '#0097A7', popupContent: <WordsPopUp words={['Neutral', 'Malicious']} /> },
                        { id: 6, label: 'False Intrinsic Worth', value: 1, color: '#2A468A', popupContent: <NoInfoPopUp /> },
                        { id: 7, label: 'Western Practices', value: 1, color: '#512DA8', popupContent: <NoInfoPopUp /> },
                        { id: 8, label: 'Water', value: 1, color: '#673AB7', popupContent: <NoInfoPopUp /> },
                        { id: 9, label: 'Land', value: 1, color: '#9C27B0', popupContent: <WordsPopUp words={['See land protocol']} /> }
                    ],

                    level: 0,
                    subPages: {},
                    disableClick: false,
                },
            },
            'instructions': {
                label: "Instructions",
                customText: <LifeBalanceP2Instructions />,
                finalStepLabel: "Continue to Part 3",
                finalStepLink: '/protocol/?bottom_nav=protocol&module=Energetic+Seed&item=Life+Balance+Part+3&step=brain'
            },
        },
        'Life Balance Part 3': {
            'brain': {
                label: "Brain",
                chart: {
                    chart: [
                        { id: 1, label: 'Quadrant 1', value: 1, color: '#E97A23', popupContent: <NoInfoPopUp /> },
                        { id: 2, label: 'Quadrant 2', value: 1, color: '#F0B731', popupContent: <NoInfoPopUp /> },
                        { id: 3, label: 'Quadrant 3', value: 1, color: '#8BC34A', popupContent: <NoInfoPopUp /> },
                        { id: 4, label: 'Quadrant 4', value: 1, color: '#4CAF50', popupContent: <NoInfoPopUp /> },
                        { id: 5, label: 'Quadrant 1', value: 1, color: '#0097A7', popupContent: <NoInfoPopUp /> },
                        { id: 6, label: 'Quadrant 2', value: 1, color: '#2A468A', popupContent: <NoInfoPopUp /> },
                        { id: 7, label: 'Quadrant 3', value: 1, color: '#512DA8', popupContent: <NoInfoPopUp /> },
                        { id: 8, label: 'Quadrant 4', value: 1, color: '#673AB7', popupContent: <NoInfoPopUp /> },
                    ],
                    innerChart: [
                        { id: 0, labelWithFormatting: 'Left\nHemisphere', label: 'Left Hemisphere', value: 1, color: '#7eab3f', popupContent: <NoInfoPopUp /> },
                        { id: 9, labelWithFormatting: 'Right\nHemisphere', label: 'Right Hemisphere', value: 1, color: '#3749b0', popupContent: <NoInfoPopUp /> }
                    ],
                    innerChartRadius: '25%',
                    level: 0,
                    subPages: {},
                    disableClick: false,
                    textChartInstructions: (<Box sx={{ display: 'flex', flexDirection: 'column' }}><Typography {...SUB_TEXT_TYPOGRAPHY_PROPS} >
                        What hemisphere(s) is/are involved?
                    </Typography>
                        <Typography {...SUB_TEXT_TYPOGRAPHY_PROPS} >
                            What quadrant(s) is/are involved?
                        </Typography>
                    </Box>)
                },
                backLink: '/protocol/?bottom_nav=protocol&module=Energetic+Seed&item=Life+Balance+Part+2&step=instructions',
                backLinkLabel: 'Back to Part 2'
            },
            'memory': {
                label: "Memory",
                chart: {
                    chart: [
                        { id: 0, label: 'Working Memory', value: 1, color: '#D32F2F', popupContent: <WordsPopUp words={['Short-term Memory or Long-term Memory']} /> },
                        { id: 1, label: 'Chunking', value: 1, color: '#E97A23', popupContent: <WordsPopUp words={['Short-term Memory or Long-term Memory']} /> },
                        { id: 2, label: 'Rehearsal', value: 1, color: '#F0B731', popupContent: <WordsPopUp words={['Short-term Memory or Long-term Memory']} /> },
                        { id: 3, label: 'Vector', value: 1, color: '#8BC34A', popupContent: <WordsPopUp words={['Short-term Memory or Long-term Memory']} /> },
                        { id: 4, label: 'Short-term Memory', value: 1, color: '#4CAF50', popupContent: <WordsPopUp words={['Short-term Memory or Long-term Memory']} /> },
                        { id: 5, label: 'Encoding', value: 1, color: '#0097A7', popupContent: <WordsPopUp words={['Short-term Memory or Long-term Memory']} /> },
                        { id: 6, label: 'Matrix', value: 1, color: '#2A468A', popupContent: <WordsPopUp words={['Short-term Memory or Long-term Memory']} /> },
                        { id: 7, label: 'Retrieval', value: 1, color: '#512DA8', popupContent: <WordsPopUp words={['Short-term Memory or Long-term Memory']} /> },
                        { id: 8, label: 'Procedural', value: 1, color: '#673AB7', popupContent: <WordsPopUp words={['Short-term Memory or Long-term Memory']} /> },
                        { id: 9, label: 'Semantic', value: 1, color: '#9C27B0', popupContent: <WordsPopUp words={['Short-term Memory or Long-term Memory']} /> },
                        { id: 10, label: 'Episodic', value: 1, color: '#795548', popupContent: <WordsPopUp words={['Short-term Memory or Long-term Memory']} /> },
                        { id: 11, label: 'Vector', value: 1, color: '#5D4037', popupContent: <WordsPopUp words={['Short-term Memory or Long-term Memory']} /> },
                        { id: 12, label: 'Encoding', value: 1, color: '#FF5252', popupContent: <WordsPopUp words={['Short-term Memory or Long-term Memory']} /> },
                        { id: 13, label: 'Matrix', value: 1, color: '#FF9800', popupContent: <WordsPopUp words={['Short-term Memory or Long-term Memory']} /> },
                        { id: 14, label: 'Long-term Memory', value: 1, color: '#000000', popupContent: <WordsPopUp words={['Short-term Memory or Long-term Memory']} /> }
                    ],
                    level: 0,
                    subPages: {},
                    disableClick: false,
                    textChartInstructions: (<Typography {...SUB_TEXT_TYPOGRAPHY_PROPS} >
                        What part(s) of memory is/are involved?
                    </Typography>
                    )
                }
            },
            'vertebrae-section': {
                label: "Vertebrae Section",
                chart: {
                    chart: [
                        { id: 0, label: 'Sacral 1-5', value: 1, color: '#D32F2F', popupContent: <NoInfoPopUp /> },
                        { id: 1, label: 'Lumbar 1-5', value: 1, color: '#E97A23', popupContent: <NoInfoPopUp /> },
                        { id: 2, label: 'Thoracic 1-12', value: 1, color: '#F0B731', popupContent: <NoInfoPopUp /> },
                        { id: 3, label: 'Cervical 1-9', value: 1, color: '#8BC34A', popupContent: <NoInfoPopUp /> }
                    ],
                    level: 0,
                    subPages: {},
                    disableClick: false,
                    textChartInstructions: (<Typography {...SUB_TEXT_TYPOGRAPHY_PROPS} >
                        Which vertebrae are involved?
                    </Typography>)
                },
            },
            'vertebrae-number': {
                label: "Vertebrae Number",
                chart: {
                    chart: [
                        { id: 0, label: '1', value: 1, color: '#D32F2F', popupContent: <NoInfoPopUp /> },
                        { id: 1, label: '2', value: 1, color: '#E97A23', popupContent: <NoInfoPopUp /> },
                        { id: 2, label: '3', value: 1, color: '#F0B731', popupContent: <NoInfoPopUp /> },
                        { id: 3, label: '4', value: 1, color: '#8BC34A', popupContent: <NoInfoPopUp /> },
                        { id: 4, label: '5', value: 1, color: '#4CAF50', popupContent: <NoInfoPopUp /> },
                        { id: 5, label: '6', value: 1, color: '#0097A7', popupContent: <NoInfoPopUp /> },
                        { id: 6, label: '7', value: 1, color: '#2A468A', popupContent: <NoInfoPopUp /> },
                        { id: 7, label: '8', value: 1, color: '#512DA8', popupContent: <NoInfoPopUp /> },
                        { id: 8, label: '9', value: 1, color: '#673AB7', popupContent: <NoInfoPopUp /> },
                        { id: 9, label: '10', value: 1, color: '#795548', popupContent: <NoInfoPopUp /> },
                        { id: 10, label: '11', value: 1, color: '#8D6E63', popupContent: <NoInfoPopUp /> },
                        { id: 11, label: '12', value: 1, color: '#C31F26', popupContent: <NoInfoPopUp /> }
                    ],

                    level: 0,
                    subPages: {},
                    disableClick: false,
                    textChartInstructions: (<Typography {...SUB_TEXT_TYPOGRAPHY_PROPS} >
                        Which vertebrae are involved?
                    </Typography>)
                },
            },
            'clearing': {
                label: "Clearing",
                customText: (<LifeBalanceClearing />)

            }

        },
        'Brain Receptor': {
            'instructions': {
                label: 'Instructions',
                customText: (
                    <Card sx={{ maxWidth: 600, margin: 'auto', mt: 4, padding: 2, boxShadow: 3 }}>
                        <CardContent>
                            <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>
                                Brain Receptor Protocol
                            </Typography>
                            <Typography variant="body1" component={'p'}>
                                Check to see what receptors need to be shifted and balanced. Do an individual session on each one that comes up for shifting.
                            </Typography>
                            <Typography variant="body1" component={'p'} ml={1}>
                                1. Alpha 1 receptor
                            </Typography>
                            <Typography variant="body1" component={'p'} ml={1}>
                                2. Alpha 2 receptor
                            </Typography>
                            <Typography variant="body1" component={'p'} ml={1}>
                                3. Beta 1 receptor
                            </Typography>
                            <Typography variant="body1" component={'p'} ml={1}>
                                4. Beta 2 receptor
                            </Typography>
                        </CardContent>
                    </Card>
                )
            },
            'issue': {
                label: "Issue",
                chart: {
                    chart: [
                        { label: 'Abundance', value: 1, id: 0, color: '#88181B', popupContent: <NoInfoPopUp /> },
                        { label: 'Appearance', value: 1, id: 1, color: '#EE1D23', popupContent: <NoInfoPopUp /> },
                        { label: 'Career', value: 1, id: 2, color: '#F58229', popupContent: <NoInfoPopUp /> },
                        { label: 'Comparison', value: 1, id: 3, color: '#F0AF13', popupContent: <NoInfoPopUp /> },
                        { label: 'Faith', value: 1, id: 4, color: '#FFDD17', popupContent: <NoInfoPopUp />, textColor: 'white' },
                        { label: 'Family', value: 1, id: 5, color: '#8BC63E', popupContent: <NoInfoPopUp /> },
                        { label: 'Friendship', value: 1, id: 6, color: '#3AB54A', popupContent: <NoInfoPopUp /> },
                        { label: 'Goals', value: 1, id: 7, color: '#0C6638', popupContent: <NoInfoPopUp /> },
                        { label: 'Health - Emotional', value: 1, id: 8, color: '#037370', popupContent: <NoInfoPopUp /> },
                        { label: 'Health - Physical', value: 1, id: 9, color: '#1074BC', popupContent: <NoInfoPopUp /> },
                        { label: 'Leadership', value: 1, id: 10, color: '#283890', popupContent: <NoInfoPopUp /> },
                        { label: 'Legacy', value: 1, id: 11, color: '#652D91', popupContent: <NoInfoPopUp /> },
                        { label: 'Relationships', value: 1, id: 12, color: '#9E1F62', popupContent: <NoInfoPopUp /> },
                    ],
                    level: 0,
                    subPages: {},
                    disableClick: false,
                }
            },
            'shame': {
                label: "Shame",
                chart: {
                    chart: [
                        { label: "I'm not good enough", value: 1, id: 0, color: '#FF0000', popupContent: <NoInfoPopUp /> },
                        { label: "I'm bad", value: 1, id: 1, color: '#FF7F00', popupContent: <NoInfoPopUp /> },
                        { label: "I don't belong", value: 1, id: 2, color: '#FFD700', popupContent: <NoInfoPopUp /> },
                        { label: "I'm unlovable", value: 1, id: 3, color: '#8BC640', popupContent: <NoInfoPopUp /> },
                        { label: "I should not exist", value: 1, id: 4, color: '#3CB54B', popupContent: <NoInfoPopUp /> },
                        { label: "I'm broken", value: 1, id: 5, color: '#138D93', popupContent: <NoInfoPopUp /> },
                    ],
                    level: 0,
                    subPages: {},
                    disableClick: false,
                }
            },
            "life-area": {
                label: "Life Area",
                chart: {
                    chart: [
                        { label: "Past life", value: 1, id: 0, color: '#88181B', popupContent: <WordsPopUp words={["Clear energy", "Integrate lesson"]} /> },
                        { label: "Karmic", value: 1, id: 1, color: '#EE1D23', popupContent: <WordsPopUp words={["Clear energy", "Integrate lesson"]} /> },
                        { label: "Current life", value: 1, id: 2, color: '#F58229', popupContent: <NoInfoPopUp /> },
                        { label: "Outside source", value: 1, id: 3, color: '#F0AF13', popupContent: <NoInfoPopUp /> },
                        { label: "Inside source", value: 1, id: 4, color: '#FFDD17', popupContent: <NoInfoPopUp /> },
                        { label: "Energetic rider", value: 1, id: 5, color: '#8BC63E', popupContent: <WordsPopUp words={["Neutral", "Malicious"]} /> },
                        { label: "False intrinsic worth", value: 1, id: 6, color: '#3AB54A', popupContent: <NoInfoPopUp /> },
                        { label: "Western practices", value: 1, id: 7, color: '#0C6638', popupContent: <NoInfoPopUp /> },
                        { label: "Water", value: 1, id: 8, color: '#037370', popupContent: <NoInfoPopUp /> },
                        { label: "Land", value: 1, id: 9, color: '#1074BC', popupContent: <NoInfoPopUp /> }, //  - See land protocol
                    ],
                    level: 0,
                    subPages: {},
                    disableClick: false,
                }
            },
            "module": {
                label: "Module",
                chart: {
                    chart: [
                        { label: "Trauma People", value: 1, id: 0, color: "#DB2F2F", popupContent: <NavProtocolPopUp program='TRAUMA PEOPLE' link={'/?module=Trauma+People'} /> },
                        { label: "Trauma Animal", value: 1, id: 1, color: "#F4802A", popupContent: <NavProtocolPopUp program='TRAUMA ANIMAL' link={'/?module=Trauma+Animal'} /> },
                    ],
                    level: 0,
                    subPages: {},
                }
            }
        },
        'Heart Opening': {
            'instructions': {
                label: 'Instructions',
                customText: (
                    <Card sx={{ maxWidth: 600, margin: 'auto', mt: 4, padding: 2, boxShadow: 3 }}>
                        <CardContent>
                            <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>
                                Heart Opening Protocol
                            </Typography>
                            <Typography variant="body1" component={'p'} >
                                Using the statement, check in with each chart and find all answers that apply from the 3 charts and document on a sheet of paper for focusing purposes.
                            </Typography>
                            <Typography variant="body1" component={'p'} >
                                <strong>Statement:</strong> What do I need to clear and shift for <em>PERSONS' NAME</em> to open their heart and to activate and balance his/her body's harmonic system?
                            </Typography>
                        </CardContent>
                    </Card>
                )
            },
            'issue': {
                label: "Issue",
                chart: {
                    chart: [
                        { label: 'Abundance', value: 1, id: 0, color: '#88181B', popupContent: <NoInfoPopUp /> },
                        { label: 'Appearance', value: 1, id: 1, color: '#EE1D23', popupContent: <NoInfoPopUp /> },
                        { label: 'Career', value: 1, id: 2, color: '#F58229', popupContent: <NoInfoPopUp /> },
                        { label: 'Comparison', value: 1, id: 3, color: '#F0AF13', popupContent: <NoInfoPopUp /> },
                        { label: 'Faith', value: 1, id: 4, color: '#FFDD17', popupContent: <NoInfoPopUp />, textColor: 'white' },
                        { label: 'Family', value: 1, id: 5, color: '#8BC63E', popupContent: <NoInfoPopUp /> },
                        { label: 'Friendship', value: 1, id: 6, color: '#3AB54A', popupContent: <NoInfoPopUp /> },
                        { label: 'Goals', value: 1, id: 7, color: '#0C6638', popupContent: <NoInfoPopUp /> },
                        { label: 'Health - Emotional', value: 1, id: 8, color: '#037370', popupContent: <NoInfoPopUp /> },
                        { label: 'Health - Physical', value: 1, id: 9, color: '#1074BC', popupContent: <NoInfoPopUp /> },
                        { label: 'Leadership', value: 1, id: 10, color: '#283890', popupContent: <NoInfoPopUp /> },
                        { label: 'Legacy', value: 1, id: 11, color: '#652D91', popupContent: <NoInfoPopUp /> },
                        { label: 'Relationships', value: 1, id: 12, color: '#9E1F62', popupContent: <NoInfoPopUp /> },
                    ],
                    level: 0,
                    subPages: {},
                    disableClick: false,
                }
            },
            'shame': {
                label: "Shame",
                chart: {
                    chart: [
                        { label: "I'm not good enough", value: 1, id: 0, color: '#FF0000', popupContent: <NoInfoPopUp /> },
                        { label: "I'm bad", value: 1, id: 1, color: '#FF7F00', popupContent: <NoInfoPopUp /> },
                        { label: "I don't belong", value: 1, id: 2, color: '#FFD700', popupContent: <NoInfoPopUp /> },
                        { label: "I'm unlovable", value: 1, id: 3, color: '#8BC640', popupContent: <NoInfoPopUp /> },
                        { label: "I should not exist", value: 1, id: 4, color: '#3CB54B', popupContent: <NoInfoPopUp /> },
                        { label: "I'm broken", value: 1, id: 5, color: '#138D93', popupContent: <NoInfoPopUp /> },
                    ],
                    level: 0,
                    subPages: {},
                    disableClick: false,
                }
            },
            "life-area": {
                label: "Life Area",
                chart: {
                    chart: [
                        { label: "Past life", value: 1, id: 0, color: '#88181B', popupContent: <WordsPopUp words={["Clear energy", "Integrate lesson"]} /> },
                        { label: "Karmic", value: 1, id: 1, color: '#EE1D23', popupContent: <WordsPopUp words={["Clear energy", "Integrate lesson"]} /> },
                        { label: "Current life", value: 1, id: 2, color: '#F58229', popupContent: <NoInfoPopUp /> },
                        { label: "Outside source", value: 1, id: 3, color: '#F0AF13', popupContent: <NoInfoPopUp /> },
                        { label: "Inside source", value: 1, id: 4, color: '#FFDD17', popupContent: <NoInfoPopUp /> },
                        { label: "Energetic rider", value: 1, id: 5, color: '#8BC63E', popupContent: <WordsPopUp words={["Neutral", "Malicious"]} /> },
                        { label: "False intrinsic worth", value: 1, id: 6, color: '#3AB54A', popupContent: <NoInfoPopUp /> },
                        { label: "Western practices", value: 1, id: 7, color: '#0C6638', popupContent: <NoInfoPopUp /> },
                        { label: "Water", value: 1, id: 8, color: '#037370', popupContent: <NoInfoPopUp /> },
                        { label: "Land", value: 1, id: 9, color: '#1074BC', popupContent: <NoInfoPopUp /> }, //  - See land protocol
                    ],
                    level: 0,
                    subPages: {},
                    disableClick: false,
                }
            },
            "module": {
                label: "Module",
                chart: {
                    chart: [
                        { label: "Trauma People", value: 1, id: 0, color: "#DB2F2F", popupContent: <NavProtocolPopUp program='TRAUMA PEOPLE' link={'/?module=Trauma+People'} /> },
                        { label: "Trauma Animal", value: 1, id: 1, color: "#F4802A", popupContent: <NavProtocolPopUp program='TRAUMA ANIMAL' link={'/?module=Trauma+Animal'} /> },
                    ],
                    level: 0,
                    subPages: {},
                }
            }
        }
    },
    'Trauma': {
        "Nature Balancing": {
            "instructions": {
                label: "Instructions",
                customText: (
                    <Card sx={{ maxWidth: 600, margin: 'auto', mt: 4, padding: 2, boxShadow: 3 }}>
                        <CardContent>
                            <Typography variant="h6" sx={{ mb: 1 }} >
                                <strong>Nature Balancing</strong>
                            </Typography>
                            <Typography variant="body1" component={'p'} mt={1}>
                                1. Ask Mother Nature if she has a message for you regarding this issue.
                            </Typography>
                            <Typography variant="body1" component={'p'} mt={0} ml={1}>
                                If yes, go to <Link href="/?module=People">People Style</Link> and take that message.
                            </Typography>
                            <Typography variant="body1" component={'p'} mt={1}>
                                2. Set your intention for this work and ask if you have permission from Mother Nature to do it?
                            </Typography>
                            <Typography variant="body1" component={'p'} mt={1}>
                                3. Ask if you need to start the session in the protocol?
                            </Typography>
                            <Typography variant="body1" component={'p'} mt={0} ml={1}>
                                If yes, proceed to protocol and follow what you get.
                            </Typography>
                            <Typography variant="body1" component={'p'} mt={0} ml={1}>
                                If no, go to <Link href="/?module=Trauma+People">Trauma People</Link> and start the session.
                            </Typography>
                        </CardContent>
                    </Card>)

            },
            "area": {
                label: "Area",
                chart: {
                    chart: [
                        { id: 0, label: 'Soil', value: 1, color: '#C0202E', popupContent: <NoInfoPopUp /> },
                        { id: 1, label: 'Water', value: 1, color: '#F4802A', popupContent: <NoInfoPopUp /> },
                        { id: 2, label: 'Air', value: 1, color: '#F3B01A', popupContent: <NoInfoPopUp /> },
                        { id: 3, label: 'Plants', value: 1, color: '#8BC640', popupContent: <NoInfoPopUp /> },
                        { id: 4, label: 'Minerals', value: 1, color: '#3FB04B', popupContent: <NoInfoPopUp /> },
                        { id: 5, label: 'Animals', value: 1, color: '#138D93', popupContent: <NoInfoPopUp /> },
                        { id: 6, label: 'Other/Chemtrails', value: 1, color: '#263A8E', popupContent: <NoInfoPopUp /> }
                    ],
                    level: 0,
                    subPages: {},
                    disableClick: false,
                }
            },
            "disruptor": {
                label: "Disruptors",
                chart: {
                    chart: [
                        { id: 0, label: 'Chemicals', value: 1, color: '#C31F26', popupContent: <NoInfoPopUp /> },
                        { id: 1, label: 'Metals', value: 1, color: '#E97A23', popupContent: <NoInfoPopUp /> },
                        { id: 2, label: 'Bacteria', value: 1, color: '#F0B731', popupContent: <NoInfoPopUp /> },
                        { id: 3, label: 'Virus', value: 1, color: '#94C739', popupContent: <NoInfoPopUp /> },
                        { id: 4, label: 'Gases', value: 1, color: '#4FAD45', popupContent: <NoInfoPopUp /> },
                        { id: 5, label: 'Toxins', value: 1, color: '#209692', popupContent: <NoInfoPopUp /> },
                        { id: 6, label: 'Medications', value: 1, color: '#2A468A', popupContent: <NoInfoPopUp /> },
                        { id: 7, label: 'Fungus', value: 1, color: '#5E409A', popupContent: <NoInfoPopUp /> },
                        { id: 8, label: 'Parasites', value: 1, color: '#774192', popupContent: <NoInfoPopUp /> },
                        { id: 9, label: 'E621', value: 1, color: '#954670', popupContent: <NoInfoPopUp /> },
                        { id: 10, label: 'Other', value: 1, color: '#804B3F', popupContent: <NoInfoPopUp /> }
                    ],
                    level: 0,
                    subPages: {},
                    disableClick: false,
                    textChartInstructions: <Typography {...SUB_TEXT_TYPOGRAPHY_PROPS} >Find out what all the nature distruptors play
                        into the situation you are working on, then continue to the next chart.</Typography>
                }
            },
            "frequency": {
                label: "Frequency",
                chart: {
                    chart: [
                        { id: 0, label: '1', value: 1, color: '#C31F26', popupContent: <NoInfoPopUp /> },
                        { id: 1, label: '2', value: 1, color: '#E97A23', popupContent: <NoInfoPopUp /> },
                        { id: 2, label: '3', value: 1, color: '#F0B731', popupContent: <NoInfoPopUp /> },
                        { id: 3, label: '4', value: 1, color: '#94C739', popupContent: <NoInfoPopUp /> },
                        { id: 4, label: '5', value: 1, color: '#4FAD45', popupContent: <NoInfoPopUp /> },
                        { id: 5, label: '6', value: 1, color: '#209692', popupContent: <NoInfoPopUp /> },
                        { id: 6, label: '7', value: 1, color: '#2A468A', popupContent: <NoInfoPopUp /> },
                        { id: 7, label: '8', value: 1, color: '#5E409A', popupContent: <NoInfoPopUp /> },
                        { id: 8, label: '9', value: 1, color: '#774192', popupContent: <NoInfoPopUp /> },
                        { id: 9, label: '0', value: 1, color: '#954670', popupContent: <NoInfoPopUp /> }
                    ],
                    innerChart: [
                        { id: 0, label: 'MHz', value: 1, color: '#C31F26', popupContent: <NoInfoPopUp /> },
                        { id: 1, label: 'Hz', value: 1, color: '#E97A23', popupContent: <NoInfoPopUp /> },
                        { id: 2, label: 'GHz', value: 1, color: '#F0B731', popupContent: <NoInfoPopUp /> }
                    ],
                    level: 0,
                    subPages: {},
                    disableClick: false,
                    innerChartRadius: '20%',
                    textChartInstructions: <Typography {...SUB_TEXT_TYPOGRAPHY_PROPS} align="center">What frequency is needed? Number of digits of this
                        frequency (1, 2, 3 or 4).  What are those digits (use the chart to find out the numbers one at a time).  Determine the
                        frequency unit (MHz, Hz, GHz).
                    </Typography>
                }
            },
            "action": {
                label: "Action",
                chart: {
                    chart: [
                        { id: 0, label: 'Neutralize', value: 1, color: '#C31F26', popupContent: <NoInfoPopUp /> },
                        { id: 1, label: 'Balance', value: 1, color: '#E97A23', popupContent: <NoInfoPopUp /> },
                        { id: 2, label: 'Cleanse', value: 1, color: '#F0B731', popupContent: <NoInfoPopUp /> },
                        { id: 3, label: 'Clear', value: 1, color: '#94C739', popupContent: <NoInfoPopUp /> },
                        { id: 4, label: 'Restore', value: 1, color: '#4FAD45', popupContent: <NoInfoPopUp /> },
                        { id: 5, label: 'Create', value: 1, color: '#209692', popupContent: <NoInfoPopUp /> },
                        { id: 6, label: 'Destroy', value: 1, color: '#2A468A', popupContent: <NoInfoPopUp /> },
                        { id: 7, label: 'Protect', value: 1, color: '#6a268e', popupContent: <NoInfoPopUp /> }
                    ],
                    level: 0,
                    subPages: {},
                    disableClick: false,
                    textChartInstructions: <Typography {...SUB_TEXT_TYPOGRAPHY_PROPS} >Choose one or more that apply.</Typography>
                }
            },
            "energy-type": {
                label: "Energy Type",
                chart: {
                    chart: [
                        { id: 0, label: 'X-Ray', value: 1, color: '#C31F26', popupContent: <NoInfoPopUp /> },
                        { id: 1, label: 'IR—Infrared', value: 1, color: '#E97A23', popupContent: <NoInfoPopUp /> },
                        { id: 2, label: 'MW—Microwave', value: 1, color: '#F0B731', popupContent: <NoInfoPopUp /> },
                        { id: 3, label: 'RF—Radio Frequency', value: 1, color: '#94C739', popupContent: <NoInfoPopUp /> },
                        { id: 4, label: 'Magnetic Fields—EM', value: 1, color: '#4FAD45', popupContent: <NoInfoPopUp /> },
                        { id: 5, label: 'AC', value: 1, color: '#209692', popupContent: <NoInfoPopUp /> },
                        { id: 6, label: 'DC', value: 1, color: '#2A468A', popupContent: <NoInfoPopUp /> },
                        { id: 7, label: 'Does this apply?', value: 1, color: '#6a268e', popupContent: <NoInfoPopUp /> },
                        { id: 8, label: 'None', value: 1, color: '#bd2b65', popupContent: <NoInfoPopUp /> }

                    ],
                    level: 0,
                    subPages: {},
                    disableClick: false,
                    textChartInstructions: <Typography {...SUB_TEXT_TYPOGRAPHY_PROPS} >Choose one.</Typography>
                }
            },
        },

        "Brain Balancing": {
            "alarm-switch": {
                label: "Alarm Switch",
                customText: (
                    <Card sx={{ maxWidth: 600, margin: 'auto', mt: 4, padding: 2, boxShadow: 3 }}>
                        <CardContent>
                            <Typography variant="h6" sx={{ mb: 1 }} >
                                <strong>Alarm Switch</strong>
                            </Typography>
                            <Typography variant="body1" component={'p'} mt={1}>
                                Ask if the alarm switch in each “brain” (head, heart, gut) is stuck on.
                            </Typography>
                            <Typography variant="body1" component={'p'} mt={1}>
                                You may receive up to three “yes” answers (one for each brain).
                            </Typography>
                            <Typography variant="body1" component={'p'} mt={1}>
                                Only address the brain(s) for which you receive a “yes.”
                            </Typography>

                            <Typography variant="h6" component={'p'} mt={2}>
                                <strong>Use the Clearing Statement:</strong>
                            </Typography>
                            <Typography variant="body1" component={'p'} mt={1}>
                                After identifying which brain’s alarm switch is stuck on, say:
                            </Typography>
                            <Typography variant="body1" component={'p'} mt={1} ml={1}>
                                “Clear anything that is standing in the way of my (or [client’s name]) alarm switch in my (head/heart/gut)
                                brain being turned off so that it is only triggered when an actual emergency is occurring.”
                            </Typography>

                        </CardContent>
                    </Card>)
            },
            "full-session": {
                label: "Full Session",
                customText: (
                    <Card sx={{ maxWidth: 600, margin: 'auto', mt: 4, padding: 2, boxShadow: 3 }}>
                        <CardContent>
                            <Typography variant="h6" sx={{ mb: 1 }} >
                                <strong>Full Session</strong>
                            </Typography>
                            <Typography variant="body1" component={'p'} mt={1}>
                                If you get a “yes” for a particular brain (head/heart/gut):
                            </Typography>
                            <Typography variant="body1" component={'p'} mt={1}>
                                Do a full session:
                            </Typography>
                            <Typography variant="body1" component={'p'} mt={0} ml={1}>
                                - <Link href="/?module=Trauma+People">Trauma People Style</Link> (for humans)
                            </Typography>
                            <Typography variant="body1" component={'p'} mt={0} ml={1}>
                                - <Link href="/?module=Trauma+Animal">Trauma Animal Style</Link> (for animals)
                            </Typography>
                        </CardContent>
                    </Card>)
            },
        },
        "Land Rejuvenations": {
            "Partial Land Clearing": {
                "directions": {
                    label: 'Directions',
                    customText: (
                        <Card sx={{ maxWidth: 600, margin: 'auto', mt: 4, padding: 2, boxShadow: 3 }}>
                            <CardContent>
                                <Typography variant="h6" sx={{ mb: 1 }} >
                                    <strong>Partial Land Clearing</strong>
                                </Typography>
                                <Typography variant="body1" component={'p'} >
                                    "Clear anything that stands in the way of the land being able to rejuvenate and be healthy."
                                </Typography>
                            </CardContent>
                        </Card>)
                },
                "color": {
                    label: 'Color',
                    chart: {
                        chart: [
                            { id: 0, label: 'Orange-Yellow', value: 1, color: '#FF581D', popupContent: <NoInfoPopUp /> },
                            { id: 1, label: 'Yellow-Orange', value: 1, color: '#FFAE42', popupContent: <NoInfoPopUp /> },
                            { id: 2, label: 'Yellow', value: 1, color: '#FFE044', popupContent: <NoInfoPopUp /> },
                            { id: 3, label: 'Yellow-Green', value: 1, color: '#C9D731', popupContent: <NoInfoPopUp /> },
                            { id: 4, label: 'Green-Yellow', value: 1, color: '#5DBB46', popupContent: <NoInfoPopUp /> },
                            { id: 5, label: 'Light-Green', value: 1, color: '#4DBC82', popupContent: <NoInfoPopUp /> },
                            { id: 6, label: 'Dark-Green', value: 1, color: '#007B3B', popupContent: <NoInfoPopUp /> },
                            { id: 7, label: 'Green-Blue', value: 1, color: '#008274', popupContent: <NoInfoPopUp /> },
                            { id: 8, label: 'Blue-Green', value: 1, color: '#055F88', popupContent: <NoInfoPopUp /> },
                            { id: 9, label: 'Blue', value: 1, color: '#0B349B', popupContent: <NoInfoPopUp /> },
                            { id: 10, label: 'Blue-Violet', value: 1, color: '#482E92', popupContent: <NoInfoPopUp /> },
                            { id: 11, label: 'Violet-Blue', value: 1, color: '#510AC9', popupContent: <NoInfoPopUp /> },
                            { id: 12, label: 'Violet', value: 1, color: '#5C00A3', popupContent: <NoInfoPopUp /> },
                            { id: 13, label: 'Ultra-Violet', value: 1, color: '#986BAE', popupContent: <NoInfoPopUp /> },
                            { id: 14, label: 'Invisible-Violet', value: 1, color: '#C2ABD1', popupContent: <NoInfoPopUp /> },
                            { id: 15, label: 'Invisible-Red', value: 1, color: '#F7BBD6', popupContent: <NoInfoPopUp /> },
                            { id: 16, label: 'Infra-Red', value: 1, color: '#F27985', popupContent: <NoInfoPopUp /> },
                            { id: 17, label: 'Red', value: 1, color: '#FB2B17', popupContent: <NoInfoPopUp /> },
                            { id: 18, label: 'Red-Orange', value: 1, color: '#EF4722', popupContent: <NoInfoPopUp /> },
                            { id: 19, label: 'Orange-Red', value: 1, color: '#F26021', popupContent: <NoInfoPopUp /> },
                            { id: 20, label: 'Orange', value: 1, color: '#FF7F27', popupContent: <NoInfoPopUp /> },
                            { id: 21, label: 'Platinum', value: 1, color: '#A7A2A0', popupContent: <NoInfoPopUp /> },
                            { id: 22, label: 'Orgone', value: 1, color: 'url(#rainbowGradient)', popupContent: <NoInfoPopUp /> },
                        ],
                        level: 0,
                        subPages: {},
                        disableClick: false,
                    }
                },
                "tone": {
                    label: 'Tone',
                    chart: {
                        chart: [
                            { id: 1, label: 'Red', value: 1, color: '#FB2B17', popupContent: <RedPopUp /> },
                            { id: 2, label: 'Orange', value: 1, color: '#FF7F27', popupContent: <OrangePopUp /> },
                            { id: 3, label: 'Yellow', value: 1, color: '#FFE044', popupContent: <YellowPopUp /> },
                            { id: 4, label: 'Green', value: 1, color: '#008000', popupContent: <GreenPopUp /> },
                            { id: 5, label: 'Dark Blue', value: 1, color: '#00008B', popupContent: <DarkBluePopUp /> },
                            { id: 6, label: 'Indigo', value: 1, color: '#4B0082', popupContent: <IndigoPopUp /> },
                            { id: 7, label: 'Violet', value: 1, color: '#5C00A3', popupContent: <VioletPopUp /> },
                        ],
                        level: 0,
                        subPages: {},
                        disableClick: false,
                    }
                },
                "frequency": {
                    label: 'Frequency',
                    chart: {
                        chart: [
                            { id: 0, label: 'A', value: 1, color: '#FF581D', popupContent: <NoInfoPopUp /> },
                            { id: 1, label: 'A#', value: 1, color: '#FFAE42', popupContent: <NoInfoPopUp /> },
                            { id: 2, label: 'B♭', value: 1, color: '#FFE044', popupContent: <NoInfoPopUp /> },
                            { id: 3, label: 'B', value: 1, color: '#C9D731', popupContent: <NoInfoPopUp /> },
                            { id: 4, label: 'C♭', value: 1, color: '#5DBB46', popupContent: <NoInfoPopUp /> },
                            { id: 5, label: 'B#', value: 1, color: '#4DBC82', popupContent: <NoInfoPopUp /> },
                            { id: 6, label: 'C', value: 1, color: '#007B3B', popupContent: <NoInfoPopUp /> },
                            { id: 7, label: 'C#', value: 1, color: '#008274', popupContent: <NoInfoPopUp /> },
                            { id: 8, label: 'D♭', value: 1, color: '#055F88', popupContent: <NoInfoPopUp /> },
                            { id: 9, label: 'D', value: 1, color: '#0B349B', popupContent: <NoInfoPopUp /> },
                            { id: 10, label: 'D#', value: 1, color: '#482E92', popupContent: <NoInfoPopUp /> },
                            { id: 11, label: 'E♭', value: 1, color: '#510AC9', popupContent: <NoInfoPopUp /> },
                            { id: 12, label: 'E', value: 1, color: '#5C00A3', popupContent: <NoInfoPopUp /> },
                            { id: 13, label: 'F♭', value: 1, color: '#986BAE', popupContent: <NoInfoPopUp /> },
                            { id: 14, label: 'E#', value: 1, color: '#C2ABD1', popupContent: <NoInfoPopUp /> },
                            { id: 15, label: 'F', value: 1, color: '#F7BBD6', popupContent: <NoInfoPopUp /> },
                            { id: 16, label: 'F#', value: 1, color: '#F27985', popupContent: <NoInfoPopUp /> },
                            { id: 17, label: 'G♭', value: 1, color: '#FB2B17', popupContent: <NoInfoPopUp /> },
                            { id: 18, label: 'G', value: 1, color: '#EF4722', popupContent: <NoInfoPopUp /> },
                            { id: 19, label: 'G#', value: 1, color: '#F26021', popupContent: <NoInfoPopUp /> },
                            { id: 20, label: 'A♭', value: 1, color: '#FF7F27', popupContent: <NoInfoPopUp /> },
                        ],
                        level: 0,
                        subPages: {},
                        disableClick: false,
                    }
                },
                "clearing-statement": {
                    label: 'Clearing Statement',
                    customText: <ClearingStatement isPartOne={true} />

                },
                'trauma-animal-or-trauma-people': {
                    label: "Module",
                    chart: {
                        chart: [
                            { label: "Trauma People", value: 1, id: 0, color: "#DB2F2F", popupContent: <NavProtocolPopUp program='TRAUMA PEOPLE' link={'/?module=Trauma+People'} /> },
                            { label: "Trauma Animal", value: 1, id: 1, color: "#F4802A", popupContent: <NavProtocolPopUp program='TRAUMA ANIMAL' link={'/?module=Trauma+Animal'} /> },
                        ],
                        level: 0,
                        subPages: {},
                    }
                }
            }
        },

        'Clean-Up Sessions': {
            "Part 1": {
                "problem-category": {
                    label: 'Problem Category',
                    chart: {
                        chart: [
                            { label: 'Past Life', value: 1, id: 0, color: '#88181B', popupContent: <WordsPopUp words={["Clear energy", "Integrate lesson"]} /> },
                            { label: 'Karmic', value: 1, id: 1, color: '#EE1D23', popupContent: <WordsPopUp words={["Clear energy", "Integrate lesson"]} /> },
                            { label: 'Current Life', value: 1, id: 2, color: '#F58229', popupContent: <NoInfoPopUp /> },
                            { label: 'Outside Source', value: 1, id: 3, color: '#F0AF13', popupContent: <NoInfoPopUp /> },
                            { label: 'Inside Source', value: 1, id: 5, color: '#8BC63E', popupContent: <NoInfoPopUp /> },
                            { label: 'Energetic Rider', value: 1, id: 6, color: '#3AB54A', popupContent: <WordsPopUp words={["Neutral", "Malicious"]} /> },
                            { label: 'False Intrinsic Worth', value: 1, id: 7, color: '#0C6638', popupContent: <NoInfoPopUp /> },
                            { label: 'Western Practices', value: 1, id: 8, color: '#037370', popupContent: <NoInfoPopUp /> },
                            { label: 'Water', value: 1, id: 9, color: '#1074BC', popupContent: <NoInfoPopUp /> },
                            { label: 'Land', value: 1, id: 10, color: '#283890', popupContent: <NoInfoPopUp /> },
                        ],
                        level: 0,
                        subPages: {},
                        disableClick: false,
                    }
                },
                "clearing-statement": {
                    label: 'Clearing Statement',
                    customText: <ClearingStatement isPartOne={true} />

                },
                'trauma-animal-or-trauma-people': {
                    label: "Module",
                    chart: {
                        chart: [
                            { label: "Trauma People", value: 1, id: 0, color: "#DB2F2F", popupContent: <NavProtocolPopUp program='TRAUMA PEOPLE' link={'/?module=Trauma+People'} /> },
                            { label: "Trauma Animal", value: 1, id: 1, color: "#F4802A", popupContent: <NavProtocolPopUp program='TRAUMA ANIMAL' link={'/?module=Trauma+Animal'} /> },
                        ],
                        level: 0,
                        subPages: {},
                    }
                }
            },
            "Part 2": {
                "issue-category": {
                    label: 'Issue Category',
                    chart: {
                        chart: [
                            { label: 'Abundance', value: 1, id: 0, color: '#88181B', popupContent: <NoInfoPopUp /> },
                            { label: 'Appearance', value: 1, id: 1, color: '#EE1D23', popupContent: <NoInfoPopUp /> },
                            { label: 'Career', value: 1, id: 2, color: '#F58229', popupContent: <NoInfoPopUp /> },
                            { label: 'Comparison', value: 1, id: 3, color: '#F0AF13', popupContent: <NoInfoPopUp /> },
                            { label: 'Faith', value: 1, id: 5, color: '#FFDD17', popupContent: <NoInfoPopUp /> },
                            { label: 'Family', value: 1, id: 6, color: '#8BC63E', popupContent: <NoInfoPopUp /> },
                            { label: 'Friendship', value: 1, id: 7, color: '#3AB54A', popupContent: <NoInfoPopUp /> },
                            { label: 'Goals', value: 1, id: 8, color: '#0C6638', popupContent: <NoInfoPopUp /> },
                            { label: 'Health-Emotional', value: 1, id: 9, color: '#037370', popupContent: <NoInfoPopUp /> },
                            { label: 'Health-Physical', value: 1, id: 10, color: '#1074BC', popupContent: <NoInfoPopUp /> },
                            { label: 'Leadership', value: 1, id: 11, color: '#283890', popupContent: <NoInfoPopUp /> },
                            { label: 'Legacy', value: 1, id: 12, color: '#652D91', popupContent: <NoInfoPopUp /> },
                            { label: 'Relationships', value: 1, id: 13, color: '#9E1F62', popupContent: <NoInfoPopUp /> },
                        ],
                        level: 0,
                        subPages: {},
                        disableClick: false,
                    }
                },
                "shame": {
                    label: 'Shame Statement',
                    chart: {
                        chart: [
                            { label: 'I\'m Not Good Enough', value: 1, id: 0, color: '#88181B', popupContent: <NoInfoPopUp /> },
                            { label: 'I\'m Bad', value: 1, id: 1, color: '#EE1D23', popupContent: <NoInfoPopUp /> },
                            { label: 'I Dont\'t Belong', value: 1, id: 2, color: '#F58229', popupContent: <NoInfoPopUp /> },
                            { label: 'I\'m unlovable', value: 1, id: 3, color: '#F0AF13', popupContent: <NoInfoPopUp /> },
                            { label: 'I Should Not Exist', value: 1, id: 5, color: '#8BC63E', popupContent: <NoInfoPopUp /> },
                            { label: 'I\'m Broken', value: 1, id: 6, color: '#3AB54A', popupContent: <NoInfoPopUp /> },
                        ],
                        level: 0,
                        subPages: {},
                        disableClick: false,
                    }
                },
                "clearing-statement": {
                    label: 'Clearing Statement',
                    customText: <ClearingStatement isPartOne={false} />

                },
                'trauma-animal-or-trauma-people': {
                    label: "Module",
                    chart: {
                        chart: [
                            { label: "Trauma People", value: 1, id: 0, color: "#DB2F2F", popupContent: <NavProtocolPopUp program='TRAUMA PEOPLE' link={'/?module=Trauma+People'} /> },
                            { label: "Trauma Animal", value: 1, id: 1, color: "#F4802A", popupContent: <NavProtocolPopUp program='TRAUMA ANIMAL' link={'/?module=Trauma+Animal'} /> },
                        ],
                        level: 0,
                        subPages: {},
                    }
                }
            }
        },
    },
    'Autoimmune': {
        '12th Strand DNA': {
            'instructions': {
                label: 'Instructions',
                customText: (
                    <Card sx={{ maxWidth: 600, margin: 'auto', mt: 4, padding: 2, boxShadow: 3 }}>
                        <CardContent>
                            <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>
                                12<sup>th</sup> Strand DNA Protocol
                            </Typography>
                            <Typography variant="body1" component={'p'} >
                                Using the statement, check in with each chart and find all answers that apply from the 3 charts and document on a sheet of paper for focusing purposes.
                            </Typography>
                            <Typography variant="body1" component={'p'} >
                                <strong>Statement:</strong> What do I need to clear and shift for <em>PERSONS' NAME</em> so that his/her 12<sup>th</sup> DNA and God DNA strand can connect and he/she can also connect and hear his/her soul song?
                            </Typography>
                        </CardContent>
                    </Card>
                )
            },
            'issue': {
                label: "Issue",
                chart: {
                    chart: [
                        { label: 'Abundance', value: 1, id: 0, color: '#88181B', popupContent: <NoInfoPopUp /> },
                        { label: 'Appearance', value: 1, id: 1, color: '#EE1D23', popupContent: <NoInfoPopUp /> },
                        { label: 'Career', value: 1, id: 2, color: '#F58229', popupContent: <NoInfoPopUp /> },
                        { label: 'Comparison', value: 1, id: 3, color: '#F0AF13', popupContent: <NoInfoPopUp /> },
                        { label: 'Faith', value: 1, id: 4, color: '#FFDD17', popupContent: <NoInfoPopUp />, textColor: 'white' },
                        { label: 'Family', value: 1, id: 5, color: '#8BC63E', popupContent: <NoInfoPopUp /> },
                        { label: 'Friendship', value: 1, id: 6, color: '#3AB54A', popupContent: <NoInfoPopUp /> },
                        { label: 'Goals', value: 1, id: 7, color: '#0C6638', popupContent: <NoInfoPopUp /> },
                        { label: 'Health - Emotional', value: 1, id: 8, color: '#037370', popupContent: <NoInfoPopUp /> },
                        { label: 'Health - Physical', value: 1, id: 9, color: '#1074BC', popupContent: <NoInfoPopUp /> },
                        { label: 'Leadership', value: 1, id: 10, color: '#283890', popupContent: <NoInfoPopUp /> },
                        { label: 'Legacy', value: 1, id: 11, color: '#652D91', popupContent: <NoInfoPopUp /> },
                        { label: 'Relationships', value: 1, id: 12, color: '#9E1F62', popupContent: <NoInfoPopUp /> },
                    ],
                    level: 0,
                    subPages: {},
                    disableClick: false,
                }
            },
            'shame': {
                label: "Shame",
                chart: {
                    chart: [
                        { label: "I'm not good enough", value: 1, id: 0, color: '#FF0000', popupContent: <NoInfoPopUp /> },
                        { label: "I'm bad", value: 1, id: 1, color: '#FF7F00', popupContent: <NoInfoPopUp /> },
                        { label: "I don't belong", value: 1, id: 2, color: '#FFD700', popupContent: <NoInfoPopUp /> },
                        { label: "I'm unlovable", value: 1, id: 3, color: '#8BC640', popupContent: <NoInfoPopUp /> },
                        { label: "I should not exist", value: 1, id: 4, color: '#3CB54B', popupContent: <NoInfoPopUp /> },
                        { label: "I'm broken", value: 1, id: 5, color: '#138D93', popupContent: <NoInfoPopUp /> },
                    ],
                    level: 0,
                    subPages: {},
                    disableClick: false,
                }
            },
            "problem-category": {
                label: "Problem Category",
                chart: {
                    chart: [
                        { label: "Past life", value: 1, id: 0, color: '#88181B', popupContent: <WordsPopUp words={["Clear energy", "Integrate lesson"]} /> },
                        { label: "Karmic", value: 1, id: 1, color: '#EE1D23', popupContent: <WordsPopUp words={["Clear energy", "Integrate lesson"]} /> },
                        { label: "Current life", value: 1, id: 2, color: '#F58229', popupContent: <NoInfoPopUp /> },
                        { label: "Outside source", value: 1, id: 3, color: '#F0AF13', popupContent: <NoInfoPopUp /> },
                        { label: "Inside source", value: 1, id: 4, color: '#FFDD17', popupContent: <NoInfoPopUp /> },
                        { label: "Energetic rider", value: 1, id: 5, color: '#8BC63E', popupContent: <WordsPopUp words={["Neutral", "Malicious"]} /> },
                        { label: "False intrinsic worth", value: 1, id: 6, color: '#3AB54A', popupContent: <NoInfoPopUp /> },
                        { label: "Western practices", value: 1, id: 7, color: '#0C6638', popupContent: <NoInfoPopUp /> },
                        { label: "Water", value: 1, id: 8, color: '#037370', popupContent: <NoInfoPopUp /> },
                        { label: "Land", value: 1, id: 9, color: '#1074BC', popupContent: <NoInfoPopUp /> }, //  - See land protocol
                    ],
                    level: 0,
                    subPages: {},
                    disableClick: false,
                }
            },
            "module": {
                label: "Module",
                chart: {
                    chart: [
                        { label: "Trauma People", value: 1, id: 0, color: "#DB2F2F", popupContent: <NavProtocolPopUp program='TRAUMA PEOPLE' link={'/?module=Trauma+People'} /> },
                        { label: "Trauma Animal", value: 1, id: 1, color: "#F4802A", popupContent: <NavProtocolPopUp program='TRAUMA ANIMAL' link={'/?module=Trauma+Animal'} /> },
                    ],
                    level: 0,
                    subPages: {},
                }
            }
        }
    },
    'Origin': {
        'Soul Work': {
            'life-lesson': {
                label: 'Life Lesson',
                chart: {
                    chart: [
                        { id: 0, label: 'Fulfillment', value: 1, color: '#D32F2F', popupContent: <NoInfoPopUp /> },
                        { id: 1, label: 'Appearance', value: 1, color: '#E97A23', popupContent: <NoInfoPopUp /> },
                        { id: 2, label: 'Attachment', value: 1, color: '#F0B731', popupContent: <NoInfoPopUp /> },
                        { id: 3, label: 'Duality', value: 1, color: '#8BC34A', popupContent: <NoInfoPopUp /> },
                        { id: 4, label: 'Egoism', value: 1, color: '#4CAF50', popupContent: <NoInfoPopUp /> },
                        { id: 5, label: 'Fame', value: 1, color: '#009688', popupContent: <NoInfoPopUp /> },
                        { id: 6, label: 'Fear', value: 1, color: '#2A468A', popupContent: <NoInfoPopUp /> },
                        { id: 7, label: 'Anger', value: 1, color: '#512DA8', popupContent: <NoInfoPopUp /> },
                        { id: 8, label: 'Greed', value: 1, color: '#673AB7', popupContent: <NoInfoPopUp /> },
                        { id: 9, label: 'Jealousy', value: 1, color: '#795548', popupContent: <NoInfoPopUp /> },
                        { id: 10, label: 'Power', value: 1, color: '#8D6E63', popupContent: <NoInfoPopUp /> },
                        { id: 11, label: 'Pride', value: 1, color: '#FF5252', popupContent: <NoInfoPopUp /> },
                        { id: 12, label: 'Wealth', value: 1, color: '#FFA000', popupContent: <NoInfoPopUp /> },
                        { id: 13, label: 'Sexuality', value: 1, color: '#FFEB3B', popupContent: <NoInfoPopUp /> },
                        { id: 14, label: 'Selfishness', value: 1, color: '#C0CA33', popupContent: <NoInfoPopUp /> },
                    ],
                    level: 0,
                    subPages: {},
                    disableClick: false,
                }
            },
            'life-blockers': {
                label: 'Life Blockers',
                customChart: LifeBlockersChart
            },
            'life-blockers-subchart': {
                label: 'Life Blockers Subchart',
                customChart: LifeBlockersChart
            }
        },

        'Circadian Cycle': {
            'Circadian Cycle': {
                label: 'Circadian Cycle',
                customChart: CyclesChart
            },
            'blockers':{
                label : 'Blockers',
                customChart: CyclesChart
            },

            'trauma-animal-or-trauma-people': {
                label: "Module",
                chart: {
                    chart: [
                        { label: "Trauma People", value: 1, id: 0, color: "#DB2F2F", popupContent: <NavProtocolPopUp program='TRAUMA PEOPLE' link={'/?module=Trauma+People'} /> },
                        { label: "Trauma Animal", value: 1, id: 1, color: "#F4802A", popupContent: <NavProtocolPopUp program='TRAUMA ANIMAL' link={'/?module=Trauma+Animal'} /> },
                    ],
                    level: 0,
                    subPages: {},
                }
            }
        },
        'Fetal Cells': {
            'Vow': {
                label: 'Vow',
                chart: {
                    chart: [
                        { id: 0, labelWithFormatting: 'Poverty\nLack of Abundance', label: 'Poverty—Lack of Abundance', value: 1, color: '#C31F26', popupContent: <NoInfoPopUp /> },
                        { id: 1, labelWithFormatting: 'Slavery\nGiving Power Away', label: 'Slavery—Giving Power Away', value: 1, color: '#E97A23', popupContent: <NoInfoPopUp /> },
                        { id: 2, labelWithFormatting: 'Silence/Complacency', label: 'Silence/Complacency', value: 1, color: '#F0B731', popupContent: <NoInfoPopUp /> },
                        { id: 3, labelWithFormatting: 'Sickness\nFeeling Self as Broken', label: 'Sickness—Feeling Self as Broken', value: 1, color: '#94C739', popupContent: <NoInfoPopUp /> },
                        { id: 4, labelWithFormatting: 'War\nInternal/External\nAddiction', label: 'War—Internal/External—Addiction', value: 1, color: '#4FAD45', popupContent: <NoInfoPopUp /> }
                    ],
                    level: 0,
                    subPages: {},
                    disableClick: false,
                }
            },
            'Individual': {
                label: 'Individual',
                chart: {
                    chart: [
                        { id: 0, label: 'Mother', value: 1, color: '#C31F26', popupContent: <NoInfoPopUp /> },
                        { id: 1, label: 'M+F Combo', value: 1, color: '#E97A23', popupContent: <NoInfoPopUp /> },
                        { id: 2, label: 'Father', value: 1, color: '#F0B731', popupContent: <NoInfoPopUp /> },
                        { id: 3, label: 'Self', value: 1, color: '#94C739', popupContent: <NoInfoPopUp /> }
                    ],
                    level: 0,
                    subPages: {},
                    disableClick: false,
                }
            },
            'Generation': {
                label: 'Generation',
                chart: {
                    chart: [
                        { id: 0, label: '1', value: 1, color: '#C31F26', popupContent: <NoInfoPopUp /> },
                        { id: 1, label: '2', value: 1, color: '#E97A23', popupContent: <NoInfoPopUp /> },
                        { id: 2, label: '3', value: 1, color: '#F0B731', popupContent: <NoInfoPopUp /> },
                        { id: 3, label: '4', value: 1, color: '#94C739', popupContent: <NoInfoPopUp /> },
                        { id: 4, label: '5', value: 1, color: '#4FAD45', popupContent: <NoInfoPopUp /> },
                        { id: 5, label: '6', value: 1, color: '#209692', popupContent: <NoInfoPopUp /> },
                        { id: 6, label: '7', value: 1, color: '#2A468A', popupContent: <NoInfoPopUp /> },
                        { id: 7, label: '8', value: 1, color: '#5E409A', popupContent: <NoInfoPopUp /> },
                        { id: 8, label: '9', value: 1, color: '#774192', popupContent: <NoInfoPopUp /> },
                        { id: 9, label: '10', value: 1, color: '#954670', popupContent: <NoInfoPopUp /> }
                    ],
                    level: 0,
                    subPages: {},
                    disableClick: false,
                }
            }
        }
    }
};

const IsStepperContentMapType = (obj: any): obj is StepperContentMapType => {
    return typeof obj === 'object' && obj !== null && Object.keys(obj).every(key => {
        const item = obj[key];
        return typeof item === 'object' && item !== null && 'label' in item;
    });
};


export const GetStepPageContent = (module: string, item: string, subitem: string): StepperContentMapType | null => {
    if (StepPageMap[module] && StepPageMap[module][item]) {
        if (subitem in StepPageMap[module][item] && IsStepperContentMapType(StepPageMap[module][item][subitem])) {
            return StepPageMap[module][item][subitem] as StepperContentMapType;
        }

        if (IsStepperContentMapType(StepPageMap[module][item])) {
            return StepPageMap[module][item] as StepperContentMapType;
        }
    }
    return null;
};

export const doesStepPageExist = (module: string, item: string, subitem: string): boolean => {
    return GetStepPageContent(module, item, subitem) !== null;
};