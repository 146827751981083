import React, { useRef, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import ModuleChart from '../ModuleChart/ModuleChart';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid2';
import { useWindowSize } from '@uidotdev/usehooks';
import { NavItem } from '../../data/types';
import { useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import { StepperContentMapType } from '../../data/Protocol/ProtocolData/StepPage';


interface StepPageContentProps {
  height: number;
  width: number;
  titleHeight: number;
  stepMap: StepperContentMapType;
  finalStepLink?: string;
  finalStepLabel?: string;
}

const isValidStep = (step: string, stepMap: StepperContentMapType) => {
  return Object.keys(stepMap).includes(step);
};


const StepPageContent: React.FC<StepPageContentProps> = ({ stepMap, width, height, titleHeight, finalStepLabel, finalStepLink }) => {
  const stepperRef = useRef<HTMLDivElement>(null);
  const size = useWindowSize();
  const navigate = useNavigate();
  const [chartWidth, setChartWidth] = useState<number>(width);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const paramsStep = queryParams.get('step') || Object.keys(stepMap)[0];
  const currentStep: string = isValidStep(paramsStep, stepMap) ? paramsStep : Object.keys(stepMap)[0];
  const currentStepIndex = Object.keys(stepMap).indexOf(currentStep);
  const [refreshKey, setRefreshKey] = useState<number>(0); // State to force refresh
  const isSmallBreakpoint = height <= 412;


  useEffect(() => {
    setChartWidth(width - (stepperRef.current?.offsetWidth ?? 0));
  }, [size, width]);



  const chart = stepMap[currentStep].chart;
  const textComponent = stepMap[currentStep].customText;
  const customChart = stepMap[currentStep].customChart;
  const finalStepLabelParsed = finalStepLabel ?? stepMap[currentStep].finalStepLabel;
  const finalStepLinkParsed = finalStepLink ?? stepMap[currentStep].finalStepLink;
  const backLink = stepMap[currentStep].backLink;
  const backLinkLabel = stepMap[currentStep].backLinkLabel;

  const handleStepClick = (index: number) => {
    const stepKey = Object.keys(stepMap)[index];
    // Preserve existing query parameters
    const queryParams = new URLSearchParams(location.search);
    queryParams.set('step', stepKey);
    const newUrl = `${location.pathname}?${queryParams.toString()}`;
    navigate(newUrl);
    setRefreshKey(prevKey => prevKey + 1); // Increment refresh key
  };

  const handleBack = () => {
    handleStepClick(currentStepIndex - 1);
  };

  const handleNext = () => {
    if (currentStepIndex === Object.keys(stepMap).length - 1) {
      // move to a new tab within the protocol
      const queryParams = new URLSearchParams();
      queryParams.set('bottom_nav', NavItem.PROTOCOL);
      const newUrl = `${location.pathname}?${queryParams.toString()}`;
      navigate(newUrl);
    }
    else {
      handleStepClick(currentStepIndex + 1);
    }
  }
  return (
    <Grid container spacing={0} direction="row" key={refreshKey} wrap="nowrap">
      <Grid size={{ xs: 1, md: 2 }} ref={stepperRef}>
        <Box sx={{ marginLeft: '20px', mt: `${titleHeight}px` }}>
          <Stepper
            activeStep={currentStepIndex}
            orientation="vertical"
            sx={{
              '& .MuiStep-root': { my: { xs: 0.0, '@media (max-height:600px)': 0.1 } },
              '& .MuiStepLabel-label': {
                fontSize: { xs: '0.65rem', sm: '0.75rem', md: '0.85rem' },
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                '@media (max-height:600px)': { fontSize: '0.6rem' },
              },
            }}
          >
            {Object.keys(stepMap).map((step) => {
              const { label } = stepMap[step];
              const isNotFinalStep =
                currentStep !== 'life-blockers' && 
                currentStep !== 'Circadian Cycle' &&
                currentStepIndex !== Object.keys(stepMap).length - 1;
              return (
                <Step
                  key={label}
                  sx={{
                    py: { xs: 0, '@media (max-height:600px)': 0 },
                    '& .MuiStepLabel-root.Mui-disabled': { cursor: 'pointer' },
                  }}
                >
                  <StepLabel sx={{ py: { xs: 0, '@media (max-height:600px)': 0 }, cursor: 'pointer' }}>
                    {label}
                  </StepLabel>
                  <StepContent sx={{ cursor: 'pointer', py: { xs: 0, '@media (max-height:600px)': 0 } }}>
                    <Box sx={{ mb: { xs: 0, '@media (max-height:600px)': 0 } }}>
                      <Button
                        size={isSmallBreakpoint ? "small" : "medium"}
                        onMouseDown={(e) => e.preventDefault()}
                        disabled={!backLink && currentStepIndex === 0}
                        onClick={() => (backLink ? navigate(backLink) : handleBack())}
                        style={{ fontSize: isSmallBreakpoint ? '10px' : '' }}
                        sx={{
                          mt: 0,
                          mr: 0,
                          p: { xs: 0.5, '@media (max-height:600px)': 0.3 },
                          WebkitTapHighlightColor: 'transparent',
                          '&:hover': { bgcolor: 'transparent' },
                        }}
                      >
                        {backLinkLabel ?? 'Back'}
                      </Button>
                      {isNotFinalStep ? (
                        <Button
                          size={isSmallBreakpoint ? "small" : "medium"}
                          onMouseDown={(e) => e.preventDefault()}
                          variant="contained"
                          disableElevation
                          disableRipple
                          onClick={handleNext}
                          style={{ fontSize: isSmallBreakpoint ? '10px' : '' }}
                          sx={{
                            mt: 0,
                            mr: 0,
                            p: { xs: 0.5, '@media (max-height:600px)': 0.3 },
                            WebkitTapHighlightColor: 'transparent',
                            '&:hover': { bgcolor: 'primary.main' },
                          }}
                        >
                          Continue
                        </Button>
                      ) : (
                        finalStepLinkParsed &&
                        finalStepLabelParsed && (
                          <Button
                            size={isSmallBreakpoint ? "small" : "medium"}
                            onMouseDown={(e) => e.preventDefault()}
                            variant="contained"
                            disableElevation
                            disableRipple
                            onClick={() => navigate(finalStepLinkParsed)}
                            style={{ fontSize: isSmallBreakpoint ? '10px' : '' }}
                            sx={{
                              mt: 0,
                              mr: 0,
                              mb: 0,
                              p: { xs: 0.5, '@media (max-height:600px)': 0.3 },
                              WebkitTapHighlightColor: 'transparent',
                              '&:hover': { bgcolor: 'primary.main' },
                            }}
                          >
                            {finalStepLabelParsed}
                          </Button>
                        )
                      )}
                    </Box>
                  </StepContent>
                </Step>
              );
            })}
          </Stepper>

        </Box>
      </Grid>
      <Grid container size={{ xs: 11, md: 10 }}
        sx={{
          justifyContent: "center",
          alignItems: chart || customChart ? "flex-end" : "center",
          height: '100%',
          width: '100%',
        }} direction="column">
        {chart && (
          <ModuleChart chartData={chart} height={height} width={chartWidth} chartModule='Pre-Protocol' item={null} subitem={null} innerChartRadius={chart.innerChartRadius} />
        )}
        {textComponent && (
          <Box sx={{ p: 2, height: Math.min(height, width) }}>
            {textComponent}
          </Box>
        )}

        {paramsStep === "Ancestor" ? (
          <Box sx={{ mt: `${titleHeight}px`, width: '100%', height: '100%', p: 0 }}>
            {customChart && stepperRef.current?.offsetWidth && (
              React.createElement(customChart, { height: height, width: chartWidth })
            )}
          </Box>
        ) : (
          customChart && stepperRef.current?.offsetWidth && (
            React.createElement(customChart, { height: height, width: chartWidth })
          )
        )}


      </Grid>
    </Grid>
  );
}

export default StepPageContent;